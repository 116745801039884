import { Formik } from "formik";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import { sendMessageFromContact } from "../../services/dataServices";
import language from "../../utils/language.json";

const Contact = (props) => {
  const { currentLanguage, setIsAuthenticated } = props;
  const [writeMessage, setWriteMessage] = useState("");

  return (
    <div className="mx-1">
      <h5 className="w-100 font-weight-bold">
        {language[currentLanguage].contactUs}
      </h5>
      <hr />
      <Formik
        initialValues={{ writeMessage }}
        validate={(values) => {
          const errors = {};
          if (!values.writeMessage) {
            errors.writeMessage = language[currentLanguage].writeMessageError;
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const {
              data: { message, message_de, success },
            } = await sendMessageFromContact({ message: values.writeMessage });

            if (success) {
              toast.success(currentLanguage === "en" ? message : message_de);
              resetForm({ writeMessage });
              props.history.push("/home/messages");
            }
          } catch (e) {
            if (e.response && e.response.status === 403) {
              setIsAuthenticated(false);
              localStorage.removeItem("authorization");
              props.history.push("/home");
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="contact-message-page w-100 mt-2">
              <textarea
                rows={4}
                type="text"
                name="writeMessage"
                className="form-control w-100 h-auto"
                id="messageInputField"
                onBlur={handleBlur}
                value={values.writeMessage}
                onChange={(e) => handleChange(e)}
                placeholder={language[currentLanguage].typeMessage}
              />
              <div className="w-100 mt-2 d-flex justify-content-end">
                {/* <small
                  id="messageInputField"
                  className="text-danger text-start"
                >
                  {errors.writeMessage}
                </small> */}
                <button
                  disabled={writeMessage || values.writeMessage ? false : true}
                  type="submit"
                  className="btn btn-primary"
                >
                  {language[currentLanguage].sendMessage}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(Contact);
