import firebase from "firebase/app";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyBpWT-RINRnkhegmbfBqUKiDVtmypqqWiI",
  authDomain: "juwelier-mercigold.firebaseapp.com",
  databaseURL: "https://juwelier-mercigold.firebaseio.com",
  projectId: "juwelier-mercigold",
  storageBucket: "juwelier-mercigold.appspot.com",
  messagingSenderId: "898302059175",
  appId: "1:898302059175:web:0ae0a2d6469d3c0d967182",
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app();
}

export default firebase;
