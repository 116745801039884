import { Formik } from "formik";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { EmailInputField, FloatingInputField } from "../../../components";
import { login, resendEmailConfirmation } from "../../../services/authService";
import language from "../../../utils/language.json";

const Login = (props) => {
  const [emailUi, setEmailUi] = useState(false);
  const [loading, setLoading] = useState(false);

  const { currentLanguage, setIsAuthenticated, verifyToken } = props;

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(language[currentLanguage].invalidEmail)
      .required(language[currentLanguage].emailRequired),
    password: Yup.string().required(language[currentLanguage].passwordRequired),
  });

  const handleSubmission = async (values, { setSubmitting }) => {
    // setIsAuthenticated(true);
    // localStorage.setItem("authorize", true);
    // props.history.push("/");
    setLoading(true);
    try {
      const {
        data: { status, message, message_de, ju_verified },
      } = await login(values);
      if (status === false) {
        toast.info(currentLanguage === "en" ? message : message_de);
        if (ju_verified === 0) setEmailUi(true);
      } else {
        verifyToken();
        // setIsAuthenticated(true);
        // // props.history.push("/");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response) {
        const { message, message_de } = e.response.data;
        toast.error(currentLanguage === "en" ? message : message_de);
      }
    }
  };
  const resendEmail = async (values, { setSubmitting }) => {
    const updatedObj = { ...values, language: currentLanguage };
    setLoading(true);
    try {
      const {
        data: { message, message_de },
      } = await resendEmailConfirmation(updatedObj);
      setEmailUi(false);
      setLoading(false);
      toast.info(currentLanguage === "en" ? message : message_de);
    } catch (ex) {
      setLoading(false);
      if (ex.response && ex.response.status === 400) {
        const { message, message_de } = ex.response.data;
        toast.error(currentLanguage === "en" ? message : message_de);
      }
    }
    setSubmitting(false);
  };
  const passwordReset = () => {
    setEmailUi(true);
  };
  return (
    <>
      {emailUi === false ? (
        <div className="app-width p-3 d-flex flex-column justify-content-center ">
          <Fragment>
            {" "}
            <h5>{language[currentLanguage].welcome}</h5>
            <br />
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={loginSchema}
              onSubmit={handleSubmission}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FloatingInputField
                    type="email"
                    name="email"
                    value={values.email}
                    placeholderValue={language[currentLanguage].email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email && errors.email}
                  />
                  <FloatingInputField
                    error={
                      errors.password && touched.password && errors.password
                    }
                    type="password"
                    name="password"
                    value={values.password}
                    placeholderValue={language[currentLanguage].password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    currentLanguage={currentLanguage}
                  />
                  <>
                    {loading && (
                      <div className="spinner-control">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary btn-block"
                    >
                      {!loading ? (
                        <strong>{language[currentLanguage].login}</strong>
                      ) : null}
                    </button>
                  </>
                </form>
              )}
            </Formik>
            <br />
            <div className="text-center pt-3 ">
              <div>
                <NavLink to="/password-reset" className="link-style cursor">
                  <strong>{language[currentLanguage].resetPassword}</strong>
                </NavLink>
              </div>
              <br />
              <div>
                <NavLink to="/signup" className="link-style">
                  <strong>
                    {language[currentLanguage].newAccount
                      .toLowerCase()
                      .replace(/\b\w/g, (l) => l.toUpperCase())}
                  </strong>
                </NavLink>
              </div>
            </div>
          </Fragment>
        </div>
      ) : (
        <Fragment>
          <EmailInputField
            resendEmail={resendEmail}
            loading={loading}
            currentLanguage={currentLanguage}
          />
        </Fragment>
      )}
    </>
  );
};

export default Login;
