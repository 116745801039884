import React from "react";
import { Modal } from "react-bootstrap";

const ServiceModal = (props) => {
  const { modal, closeModal, body } = props;

  return (
    <div>
      <Modal
        show={modal}
        onHide={closeModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {body}
      </Modal>
    </div>
  );
};
export default ServiceModal;
