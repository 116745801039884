import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { image } from "../../images";
import { getAllMessages } from "../../services/dataServices";
import language from "../../utils/language.json";

class MessagesGroup extends Component {
  state = {
    allMessages: [],
    loading: true,
  };

  fetchData = async () => {
    this.setState({ loading: true });

    try {
      const {
        data: { data },
      } = await getAllMessages();
      this.setState({ allMessages: data, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      if (e.response && e.response.status === 403) {
        this.props.setIsAuthenticated(false);
        localStorage.removeItem("authorization");
      }
    }
  };

  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate({ location: { key } }) {
    const {
      location: { key: oldKey },
    } = this.props;
    if (oldKey !== key) this.fetchData();
  }

  render() {
    const { allMessages, loading } = this.state;
    const { currentLanguage } = this.props;
    return (
      <>
        {loading ? (
          <div className="reset-page-height">
            <div className="spinner-control">
              <div className="spinner-border full-page-spinner" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <h5 className="font-weight-bold">
                {language[currentLanguage].messages}
              </h5>

              <hr className="mt-0" />
            </div>
            <div className="container p-0 message-group-feed">
              {isEmpty(allMessages) ? (
                <div className="text-center">
                  <h5>{language[currentLanguage].noMessages}</h5>
                </div>
              ) : (
                <div>
                  {allMessages.map((list, index) => (
                    <Link
                      key={index}
                      to={{
                        pathname: `/home/messages/${list.message_id}`,
                        state: {
                          subject: list.juId,
                          image: image,
                        },
                      }}
                      className="link-decoration"
                    >
                      <div
                        key={index}
                        className={
                          list.isJuwelierRead === 1 ? "bg-read" : "bg-unread"
                        }
                      >
                        <div className="row">
                          <img
                            src={image}
                            alt="rings"
                            className="message-img-size"
                          />
                          <div className="col">
                            <h6 className="font-weight-bold">
                              {language[currentLanguage].customerService}
                            </h6>
                            <h6>
                              {moment(list.last_updated).format(
                                "DD.MM.YYYY H:mm"
                              )}
                            </h6>
                          </div>
                          <div className="icon-positioning-letter">
                            {list.isJuwelierRead === 1 ? (
                              <i className="fa fa-envelope-open" />
                            ) : (
                              <i className="fa fa-envelope" />
                            )}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default withRouter(MessagesGroup);
