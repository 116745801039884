import { NavLink } from "react-router-dom";
import language from "../../utils/language.json";

const Welcome = ({ currentLanguage }) => {
  return (
    <div className="app-width m-auto p-3 d-flex flex-column justify-content-center">
      <h5 className="mb-3">{language[currentLanguage].hello}</h5>

      <h6 className="mb-3">{language[currentLanguage].welcomeBodyText}</h6>

      <div className="d-flex flex-column text-center">
        <NavLink to="/login" className="link-decoratin">
          <button type="button" className="btn btn-primary mb-4 w-100">
            {language[currentLanguage].login}
          </button>
        </NavLink>
        <NavLink to="/signup" className="link-decoratin">
          <button type="button" className="btn btn-outline-secondary w-100">
            {language[currentLanguage].newAccount}
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default Welcome;
