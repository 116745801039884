import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component, createRef } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { toast } from "react-toastify";
import SimpleReactLightbox from "simple-react-lightbox";
import { capitalize } from "lodash";
import {
  ActiveOrderButtons,
  FullScreenImages,
  MediaShare,
  RepairServices,
  ReturnServices,
  SingleDetailPageProperties,
} from "../components";
import {
  createPickupService,
  getPickupInfo,
  getProductDetails,
  getServicePrices,
} from "../services/dataServices";
import language from "../utils/language.json";

import { defaultImage } from "../images";
const baseURL = process.env.REACT_APP_LINK_SHARE_URL;

export default class ProductDetails extends Component {
  checkMount = false;

  constructor(props) {
    super(props);
    this.imagesRef = createRef();
  }

  state = {
    id: "",
    serviceModal: "",
    openService: false,
    rings: {},
    price: "",
    linkedTo: "",
    categoryName: "",
    currentImageIndex: 0,
    imageToDisplayIndex: 0,
    servicePrices: {},
    images: [],
    loading: false,
    bodyLoader: false,
    priceDisplay: false,
    showFullScreenImages: false,
    offersButtons: false,
    ordersButtons: false,
    displaySendNewOfferButton: false,
    messagesModal: false,
    shareModal: false,
    open: true,
    openShare: false,
    messageError: false,
    repeat: false,
    disableInputField: true,
    confirmProduceNow: false,
    copied: false,
    prices: {},
    current_time: new Date(),
    response: {},
  };
  componentDidMount = async () => {
    this.setState({ bodyLoader: true });
    const {
      currentLanguage,
      logout,
      location: { state },
    } = this.props;
    // await getServicePrices();
    await this.fetchServicePrices();
    if (!isEmpty(state)) {
      const { rings = {} } = state;
      if (moment(new Date()).diff(moment(rings.order_date), "month", true) > 12)
        toast.error(language[currentLanguage].expireWarranty);
      this.setState({
        rings,
        bodyLoader: false,
        images: !isEmpty(rings.product_image)
          ? [
              {
                original: rings.product_image,
                thumbnail: rings.product_image,
              },
            ]
          : [{ original: defaultImage, thumbnail: defaultImage }],
      });
    } else {
      const { id } = this.props.match.params;
      try {
        const {
          data: { data, success, current_time, message, message_de },
        } = await getProductDetails(id);
        if (success === true) {
          if (
            moment(current_time).diff(
              moment(data[0].order_date),
              "month",
              true
            ) > 12
          )
            toast.error(language[currentLanguage].expireWarranty);
          this.setState({
            current_time,
            rings: data[0],
            bodyLoader: false,
            images: !isEmpty(data[0].product_image)
              ? [
                  {
                    original: data[0].product_image,
                    thumbnail: data[0].product_image,
                  },
                ]
              : [{ original: defaultImage, thumbnail: defaultImage }],
          });
        } else {
          this.setState({
            bodyLoader: false,
            response: { message, message_de },
          });
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          logout();
        }
        if (e.response && e.response.status === 400) {
          const { message, message_de } = e.response.data;
          toast.error(currentLanguage === "en" ? message : message_de);
        }
      }
    }
  };

  fetchServicePrices = async () => {
    this.setState({ loading: true });
    try {
      const {
        data: { data: prices },
      } = await getServicePrices();
      this.setState({
        loading: false,
        prices: prices[0],
        servicePrices: prices[0],
      });
    } catch (e) {
      this.setState({ loading: false });
      if (e.response && e.response.status === 403) this.props.logout();
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        toast.error(this.state.currentLanguage === "en" ? message : message_de);
      }
    }
  };

  handleShareModal = (e) => {
    this.setState({ shareModal: true, openShare: true });
  };

  closeShareModal = () => {
    const { openShare } = this.state;
    this.setState({ openShare: !openShare, shareModal: false });
  };
  closeModal = () => {
    const { open } = this.state;
    this.setState({ open: !open, messagesModal: false });
  };

  handleFullImage = (e) => {
    const currentImageIndex = this.imagesRef.current.getCurrentIndex();
    this.setState({
      currentImageIndex,
      showFullScreenImages: true,
    });
  };
  handleHideFullScreen = (id) => {
    this.setState({
      imageToDisplayIndex: Number(id),
      showFullScreenImages: false,
    });
  };

  handleClick = async ({ name }) => {
    this.setState({ serviceModal: name, openService: true });
    const { pickup_price } = this.state.prices;
    if (name === "pickup") {
      this.setState({ loading: true });
      const { id } = this.props.match.params;
      const { logout, currentLanguage, history } = this.props;
      try {
        const {
          data: { data, allowed, message, message_de },
        } = await getPickupInfo(id);
        this.setState({ loading: false });
        if (allowed === true) {
          this.setState({ loading: true });
          try {
            const {
              data: { data },
            } = await createPickupService({ id, pickup_price });
            history.push({
              pathname: `/home/order-details/${data[0].ju_order_id}`,
            });
            this.setState({ loading: false });
          } catch (e) {
            this.setState({ loading: false });

            if (e.response && e.response.status === 403) {
              logout();
            }
            if (e.response && e.response.status === 400) {
              const { message, message_de } = e.response.data;
              toast.error(currentLanguage === "en" ? message : message_de);
            }
          }
        }
        if (!isEmpty(data[0]) && allowed === false) {
          history.push(`/home/order-details/${data[0].ju_order_id}`);
        }
        if (allowed === false) {
          toast.error(currentLanguage === "en" ? message : message_de);
        }
      } catch (e) {
        this.setState({ loading: false });
        if (e.response && e.response.status === 403) {
          logout();
        }
        if (e.response && e.response.status === 400) {
          const { message, message_de } = e.response.data;
          toast.error(currentLanguage === "en" ? message : message_de);
        }
      }
    }
  };
  closeShareModal = () => {
    this.setState({ serviceModal: "", openService: false });
  };

  render() {
    const {
      rings,
      showFullScreenImages,
      currentImageIndex,
      imageToDisplayIndex,
      images,
      loading,
      bodyLoader,
      shareModal,
      openShare,
      serviceModal,
      openService,
      servicePrices,
      current_time,
      prices,
      response,
    } = this.state;
    const { currentLanguage } = this.props;
    const {
      product_id,
      product_gender,
      material_color,
      ring_size,
      ring_surface,
      material_karat,
      main_stone,
      gravur,
      male_ring_surface,
      male_material_color,
      male_ring_size,
      male_material_karat,
      male_main_stone,
      male_gravur,
      product_name,
      order_date,
      user_bill_firstName,
      user_bill_lastName,
      ju_id,
      ju_order_id,
      product_desc,
      product_desc1,
      product_desc2,
      product_desc3,
    } = rings;

    return (
      <React.Fragment>
        {!isEmpty(serviceModal) && serviceModal === "return" && (
          <ReturnServices
            loading={loading}
            modal={openService}
            closeModal={this.closeShareModal}
            currentLanguage={currentLanguage}
          />
        )}
        {!isEmpty(serviceModal) && serviceModal === "repair" && (
          <RepairServices
            loading={loading}
            modal={openService}
            closeModal={this.closeShareModal}
            currentLanguage={currentLanguage}
            rings={rings}
            serviceCode={2}
            logout={this.props.logout}
            bigPrice={servicePrices.repair_price}
          />
        )}
        {!isEmpty(serviceModal) && serviceModal === "cleanup" && (
          <RepairServices
            loading={loading}
            modal={openService}
            closeModal={this.closeShareModal}
            currentLanguage={currentLanguage}
            rings={rings}
            serviceCode={3}
            logout={this.props.logout}
            bigPrice={servicePrices.cleanup_price}
          />
        )}
        {!isEmpty(serviceModal) && serviceModal === "adjustment" && (
          <RepairServices
            loading={loading}
            modal={openService}
            closeModal={this.closeShareModal}
            currentLanguage={currentLanguage}
            rings={rings}
            serviceCode={4}
            logout={this.props.logout}
            bigPrice={servicePrices.adjustment_big_price}
            smallPrice={servicePrices.adjustment_small_price}
          />
        )}
        {shareModal && (
          <MediaShare
            loading={loading}
            modal={openShare}
            closeModal={this.closeShareModal}
            currentLanguage={currentLanguage}
            urlShare={`${baseURL}product-details/${this.props.match.params.id}`}
          />
        )}
        <>
          <div className="mb-1">
            <div className="d-md-none">
              {bodyLoader ? (
                <div className="reset-page-height">
                  <div className="spinner-control">
                    <div className="spinner-border " role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {isEmpty(rings) ? (
                    <div>
                      {currentLanguage === "en"
                        ? response.message
                        : response.message_de}
                    </div>
                  ) : (
                    <div className="">
                      <div className="pb-4 mb-4 m-auto">
                        {showFullScreenImages === false ? (
                          <ImageGallery
                            ref={this.imagesRef}
                            showThumbnails={false}
                            onClick={this.handleFullImage}
                            className="bg-color"
                            showFullscreenButton={false}
                            showPlayButton={false}
                            items={images}
                            startIndex={imageToDisplayIndex}
                            showThumbnails={false}
                          />
                        ) : (
                          <SimpleReactLightbox>
                            <FullScreenImages
                              handleHideFullScreen={this.handleHideFullScreen}
                              images={images}
                              currentImageIndex={currentImageIndex}
                            />
                          </SimpleReactLightbox>
                        )}
                      </div>

                      <hr />
                      <span>
                        {language[currentLanguage].name}:{" "}
                        {capitalize(user_bill_firstName) +
                          " " +
                          capitalize(user_bill_lastName)}
                      </span>
                      <hr />

                      <div className="app-width m-auto px-0">
                        <div className="card-body d-flex flex-column mb-4">
                          <div className="row m-0">
                            <div className="col-6 p-0 pr-1">
                              <div className="card-body d-flex flex-column">
                                <SingleDetailPageProperties
                                  data={{
                                    language: "en",
                                    ju_id,
                                    ju_order_id,
                                    material_color,
                                    ring_surface,
                                    product_id,
                                    product_gender:
                                    product_gender &&
                                    product_gender.toLowerCase() ===
                                      "single"
                                      ? language[currentLanguage].single
                                      : language[currentLanguage].woman,
                                    ring_surface,
                                    material_color,
                                    ring_size,
                                    material_karat,
                                    main_stone,
                                    gravur,
                                    currentLanguage,
                                  }}
                                />
                              </div>
                            </div>

                            {product_gender === "couple" && (
                              <div className="col-6 p-0">
                                <div className="card-body d-flex flex-column">
                                  <SingleDetailPageProperties
                                    data={{
                                      ju_id,
                                      ju_order_id,
                                      material_color,
                                      ring_surface,
                                      language: "en",
                                      product_id,
                                      product_gender:
                                      product_gender &&
                                      product_gender.toLowerCase() ===
                                        "single"
                                        ? language[currentLanguage].single
                                        : language[currentLanguage].man,
                                      ring_surface: male_ring_surface,
                                      material_color: male_material_color,
                                      ring_size: male_ring_size,
                                      material_karat: male_material_karat,
                                      main_stone: male_main_stone,
                                      gravur: male_gravur,
                                      currentLanguage,
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="my-3 font-weight-bold more-info-size">
                          {language[currentLanguage].moreInfo}
                        </p>
                        <p className="m-0 p-0 font-weight-bold desc-size">
                          {language[currentLanguage].desc}
                        </p>
                      </div>
                      <hr />
                      <div className="container m-auto px-0">
                        {currentLanguage === "en" ? (
                          <p className="body-text-size">{product_desc2}</p>
                        ) : (
                          <p className="body-text-size">{product_desc}</p>
                        )}
                        {currentLanguage === "en" ? (
                          <p className="body-text-size">{product_desc3}</p>
                        ) : (
                          <p className="body-text-size">{product_desc1}</p>
                        )}
                      </div>
                      <hr />
                      {moment(current_time).diff(
                        moment(order_date),
                        "month",
                        true
                      ) < 13 ? (
                        <ActiveOrderButtons
                          prices={prices}
                          rings={this.state.rings}
                          handleClick={this.handleClick}
                          currentLanguage={currentLanguage}
                        />
                      ) : (
                        <div className="d-flex flex-column ">
                          <h6>{language[currentLanguage].expireWarranty}</h6>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="d-none d-md-block">
              {bodyLoader ? (
                <div className="reset-page-height">
                  <div className="spinner-control">
                    <div className="spinner-border " role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {isEmpty(rings) ? (
                    <h5 className="row d-flex justify-content-center">
                      {currentLanguage === "en"
                        ? response.message
                        : response.message_de}
                    </h5>
                  ) : (
                    <div className="container m-auto">
                      <div className="pt-4 pb-2 details-type-title px-0">
                        {product_name || "Rings"}
                      </div>
                      <div className="row px-0">
                        {showFullScreenImages === false ? (
                          <div className="col-lg-6 col-md-4 col-sm-12 px-0">
                            <ImageGallery
                              ref={this.imagesRef}
                              showNav={false}
                              onClick={this.handleFullImage}
                              className="bg-color"
                              showFullscreenButton={false}
                              showPlayButton={false}
                              items={images}
                              startIndex={imageToDisplayIndex}
                              showThumbnails={false}
                            />
                          </div>
                        ) : (
                          <div className="col-lg-6 col-md-4 col-sm-12 pb-4 ">
                            <SimpleReactLightbox>
                              <FullScreenImages
                                handleHideFullScreen={this.handleHideFullScreen}
                                images={images}
                                currentImageIndex={currentImageIndex}
                              />
                            </SimpleReactLightbox>
                          </div>
                        )}

                        <div className="col-lg-6 col-md-8 col-sm-12 mb-margin">
                          {moment(current_time).diff(
                            moment(order_date),
                            "month",
                            true
                          ) < 13 ? (
                            <div className="buttons-list row mb-4">
                              <ActiveOrderButtons
                                rings={this.state.rings}
                                handleClick={this.handleClick}
                                currentLanguage={currentLanguage}
                                prices={prices}
                              />
                            </div>
                          ) : (
                            <div className="d-flex flex-column ">
                              <h6>
                                {language[currentLanguage].expireWarranty}
                              </h6>
                            </div>
                          )}
                          <hr />
                          <span className="font-weight-bold">
                            {language[currentLanguage].name}:{" "}
                            {capitalize(user_bill_firstName) +
                              " " +
                              capitalize(user_bill_lastName)}
                          </span>

                          <hr />
                          <div className="row d-flex flex-column ">
                            <p className="mb-3 font-weight-bold more-info-size">
                              {language[currentLanguage].moreInfo}
                            </p>
                            <p className="m-0 p-0  font-weight-bold desc">
                              {language[currentLanguage].desc}
                            </p>
                            <hr className="w-100" />
                            {currentLanguage === "en" ? (
                              <p className="body-text-size">{product_desc2}</p>
                            ) : (
                              <p className="body-text-size">{product_desc}</p>
                            )}
                            {currentLanguage === "en" ? (
                              <p className="body-text-size">{product_desc3}</p>
                            ) : (
                              <p className="body-text-size">{product_desc1}</p>
                            )}

                            <hr className="w-100 mt-0" />
                            <div className="card-body d-flex flex-column properties-height">
                              <div className="row">
                                <div className="col-6 p-0 pr-1">
                                  <div className="card-body d-flex flex-column">
                                    <SingleDetailPageProperties
                                      data={{
                                        language,
                                        ju_id,
                                        ju_order_id,
                                        material_color,
                                        ring_surface,
                                        product_id,
                                        product_gender:
                                          product_gender &&
                                          product_gender.toLowerCase() ===
                                            "single"
                                            ? language[currentLanguage].single
                                            : language[currentLanguage].woman,
                                        ring_surface,
                                        material_color,
                                        ring_size,
                                        material_karat,
                                        main_stone,
                                        gravur,
                                        currentLanguage,
                                      }}
                                    />
                                  </div>
                                </div>

                                {product_gender === "couple" && (
                                  <div className="col-6 p-0">
                                    <div className="card-body d-flex flex-column">
                                      <SingleDetailPageProperties
                                        data={{
                                          language,
                                          ju_id,
                                          ju_order_id,
                                          material_color,
                                          ring_surface,
                                          product_id,
                                          product_gender:
                                            product_gender &&
                                            product_gender.toLowerCase() ===
                                              "single"
                                              ? language[currentLanguage].single
                                              : language[currentLanguage].man,
                                          ring_surface: male_ring_surface,
                                          material_color: male_material_color,
                                          ring_size: male_ring_size,
                                          material_karat: male_material_karat,
                                          main_stone: male_main_stone,
                                          gravur: male_gravur,
                                          currentLanguage,
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      </React.Fragment>
    );
  }
}
