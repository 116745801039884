import { ShadowIcon } from "../../images";
import language from "../../utils/language.json";

const ReturnPickupComplete = ({ currentLanguage, changeText }) => {
  return (
    <div>
      <div className="text-start">
        {language[currentLanguage].pickupCompleteTitle}
      </div>
      <hr />
      <div className="p-0 py-4">
        <div className="d-flex flex-column">
          <div className="pb-4">
            <div className="mb-3 h-100 w-100 d-flex justify-content-between">
              <p className="ul-text my-auto">
                {language[currentLanguage].custIsAllowed}
              </p>
              <span>
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
            </div>
            <div
              // onClick={() => handleButton()}
              className="mb-3 h-100 w-100 d-flex justify-content-between"
            >
              <div className="ul-text my-auto">
                <span className="mx-auto">
                  {language[currentLanguage].returnLabel}
                </span>
                <br />
                <span className="mx-auto">
                  {language[currentLanguage].custDontPickedUp}
                </span>
              </div>
              <span className="my-auto">
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
            </div>
            <div
              // onClick={() => handleButton()}
              className="mb-3 h-100 w-100 d-flex justify-content-between"
            >
              <p className="ul-text my-auto">
                {language[currentLanguage].dhlPickedSelect}
              </p>
              <span className="my-auto">
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div className="booked-body p-3 mb-3">
            <p className="card-mid-title">
              {language[currentLanguage].processComplete}
            </p>
            <p className="mb-6 card-mid-body">
              {language[currentLanguage].willfindUnder}
            </p>

            <img
              src={ShadowIcon}
              alt="approved"
              className="check-circle-style"
            />
            <span className="card-end-body">
              {language[currentLanguage].thankYou}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnPickupComplete;
