import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { ServiceModal } from "../../components";

const PickupServices = ({
  loading,
  modal,
  closeModal,
  currentLanguage,
  logout,
  id,
  history,
}) => {
  const [displayModal, setDisplayModal] = useState(1);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {};

  return (
    <ServiceModal
      loading={loading}
      modal={modal}
      closeModal={closeModal}
      body={cardBody({ displayModal, setDisplayModal, currentLanguage })}
      currentLanguage={currentLanguage}
    />
  );
};
const cardBody = ({ displayModal, setDisplayModal, currentLanguage }) => {
  switch (displayModal) {
    default:
      return (
        <div className="reset-page-height mt-5">
          <div className="spinner-control">
            <div className="spinner-border " role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
  }
};

export default withRouter(PickupServices);
