import language from "../../../utils/language.json";

const Imprint = ({ currentLanguage }) => {
  return (
    <div className="card">
      <div>
        <h5 className="font-weight-bold">
        {language[currentLanguage].imprint}
        </h5>
        <hr />
      </div>

      <div className="card-body">
        
        <h4 className="card-text">
        {language[currentLanguage].provider}
        </h4>

        <div>
        <p className="no-margin font-primary mb-1">Merci Gold GmbH</p>
        <p className="no-margin font-primary mb-1">Friedenstraße 24</p>
        <p className="no-margin font-primary mb-1">51373 Leverkusen</p>
        <p className="no-margin font-primary mb-1">{language[currentLanguage].deCountry}</p>
        </div>

        
        <div className="mt-5">
        <p className="no-margin font-primary mb-1">{language[currentLanguage].contact}:</p>
        <p className="no-margin font-primary mb-1">+49 (0) 221 25939483</p>
        <p className="no-margin font-primary mb-1">info@juwelier-mercigold.de</p>
        </div>



        <div className="mt-5">
        <p className="no-margin font-primary mb-1">{language[currentLanguage].managementboard}: Adem Demir</p>
        <p className="no-margin font-primary mb-1">{language[currentLanguage].courtofregistery}: Amtsgericht Köln, HRB 102551</p>
        <p className="no-margin font-primary mb-1">{language[currentLanguage].vatnr}.: DE 333555931</p>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
