import React, {useEffect}  from "react";
import language from "../../../utils/language.json";





const TermsData = [
  {
    heading: {
      de: "Geltungsbereich",
      en: "Scope",
    },
    content: {
      de:
        "Diese Allgemeinen Geschäftsbedingungen gelten ausschließlich gegenüber Unternehmern, die Aufträge über die Webseite www.juwelier-mercigold.de durchführen sowie Vertragspartner der Merci Gold GmbH sind. Entgegenstehende oder abweichende Bedingungen des Auftragnehmers erkennen wir nur an, wenn wir ausdrücklich schriftlich der Geltung zustimmen. ",
      en:
        "These general terms and conditions apply exclusively to entrepreneurs who perform orders via the website www.juwelier-mercigold.de and who are contractual partners of Merci Gold GmbH. We only recognize conflicting or deviating conditions of the contractor if we expressly agree to their validity in writing.",
    },
  },
  {
    heading: {
      de: "Angebot & Vertragsabschluss",
      en: "Offer & conclusion of contract",
    },
    content: {
      de:
        "Sofern ein Auftrag über unsere Plattform www.juwelier-mercigold.de gebucht wurde, gilt dies als Angebot und Vertragsschluss für den jeweiligen Auftrag. Durch anklicken der betreffenden Schaltflächen geben Sie ein verbindliches Angebot ab.",
      en:
        "If an order has been booked via our platform www.juwelier-mercigold.de, this counts as an offer and conclusion of a contract for the respective order. By clicking the relevant button, you are making a binding offer.",
    },
  },
  {
    heading: {
      de: "Preise & Zahlung",
      en: "Prices & Payment",
    },
    content: {
      de: `Es gelten unsere nachfolgenden Preise zuzüglich Mehrwertsteuer in jeweils gültiger Höhe. Sofern wir ein anderes Angebot über unsere Plattform www.juwelier-mercigold.de annehmen, gilt dieser stattdessen.<br/>

        1)	Abholservice: 50 €<br/>
        2)	Reparaturservice: 50 €<br/>
        3)	Reinigungsservice: 50 €<br/>
        4)	Anpassungsservice: <br/>
        -	Ringvergrößerung: 50<br/>
        -	Ringverkleinerung: 50<br/>
        Die Zahlungen erfolgt immer zum Monatsende.
        `,
      en: `Our following prices apply plus VAT at the currently applicable rate. If we accept another offer via our platform www.juwelier-mercigold.de, this will apply instead.<br/>
        1) Pick-up service: 50 € <br/>
        2) Repair service: 50 €<br/>
        3) Cleaning service: 50 €<br/>
        4) Customization service:<br/>
        - Ring enlargement: 50<br/>
        - Ring reduction: 50<br/>

        Payments are always made at the end of the month.
        `,
    },
  },
  {
    heading: {
      de: "Pflichten des Auftragnehmers",
      en: "Obligations of the contractor",
    },
    content: {
      de: `1.	Die Aufträge sind  in der Weise zu erfüllen, dass alle Qualitätsstandards eingehalten
        werden, die für einen ordnungsgemäßen Juwelier- und Goldschmiedebetrieb gegenwärtig üblich sind. 
        Bei Mängeln sind wir berechtigt, die Vergütung zu mindern oder ggf. Schadensersatz zu verlangen.<br/>
        2.	Dem Auftragnehmer oder seinen Verrichtungsgehilfen ist es untersagt, einem
        Kunden der Merci Gold GmbH Waren anzubieten oder die Waren der 
        Merci Gold GmbH einem Urteil zu unterziehen (Beratungsverbot). 
        Dies ist insbesondere beim Abholservice zu beachten. Andernfalls macht der Auftragnehmer sich Schadensersatzpflichtig.<br/>
        3.	Der Auftragnehmer ist verpflichtet jede Übergabe der Ware auf Nachfrage
        nachzuweisen, Unterlagen ordnungsgemäß aufzubewahren und ggf. bei der Aufklärung mitzuwirken.<br/>
        4.	Der Auftragnehmer ist verpflichtet, über alle Informationen, die ihm im
        Zusammenhang mit seiner Tätigkeit für den Auftraggeber bekannt werden, Stillschweigen zu bewahren. 
        An allen in Zusammenhang mit der Auftragserteilung dem Auftragnehmer überlassenen Unterlagen wie z. 
        B. Nachweise über den Versand oder der Übergabe der Ware etc., behalten wir uns Eigentums- und Urheberrechte vor.
         Diese Unterlagen dürfen Dritten ohne ausdrückliche schriftliche Zustimmung nicht zugänglich gemacht werden.<br/>
        5.	Der Auftragnehmer erklärt sich mit der Bezeichnung Partnerjuwelier sowie der
        Veröffentlichung der Kontaktdaten auf der Webseite www.mercigold.de einverstanden.<br/>
        6. Zur Ausübung eines Zurückbehaltungsrechts ist der Auftragnehmer nicht befugt.<br/>
        `,
      en: `1. The orders are to be fulfilled in such a way that all quality standards are proper comply with currently jewelry and goldsmith's business. In the event of deficit, we are entitled to reduce the remuneration or, if necessary, to demand compensation.<br/>
      2. The contractor or his vicarious agents are prohibited to offer goods to customers of Merci Gold GmbH or the offer of Merci Gold GmbH to submit a opinion (prohibition of advice). This is particularly important for the pick-up service. Otherwise the contractor is liable for damages.<br/>
      3. The contractor is obliged to handing over the provide proof of every handing over, to keep documents properly and, if necessary, to participate in the clarification.<br/>
      4. The contractor is obliged to have all information given to him in
      Connection with his work for the client become known, to maintain silence. All documents provided to the contractor in connection with the placing of the order, such as evidence of the dispatch or handover of the goods, etc., we reserve property rights and copyrights. These documents may not be made accessible to third parties without express written consent.<br/>
      5. The contractor agree to signifies as partner jeweler and agree to publication of contact details on the website www.mercigold.de.<br/>
      6. The contractor is not authorized to exercise a right of retention.<br/>
      `,
    },
  },
  {
    heading: {
      de: "Vertragsdauer & Kündigung",
      en: "Contract duration & termination",
    },
    content: {
      de: `1.	Sofern der Auftragnehmer nach der erstmaligen Freischaltung seines Kontos mit den AGB´s nicht einverstanden ist, ist er berechtigt, innerhalb von 2 Wochen seine Vertragsbeziehung sofort zu beenden. <br/>
        2.	Andernfalls läuft der Vertrag auf unbestimmte Zeit. Jede Partei ist berechtigt, den Vertrag mit einer Frist von 3 Monaten zum Monatsende zu kündigen. Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt unberührt.<br/>
        3.	Kündigungen bedürfen zu ihrer Wirksamkeit der Schriftform.<br/>
        `,
      en: `1. If the contractor does not agree to the terms and conditions after the first activation of his account, he is entitled to terminate his contractual relationship immediately within 2 weeks.<br/>
      2. Otherwise the contract runs for an indefinite period. Each party is entitled to terminate the contract with a notice period of 3 months to the end of the month. 
      The right to terminate without notice for an important reason remains unaffected.<br/>
      3. Terminations must be made in writing to be effective.<br/>
      `,
    },
  },
  {
    heading: {
      de: "Sonstiges",
      en: "Miscellaneous",
    },
    content: {
      de: `1.	Dieser Vertrag und die gesamten Rechtsbeziehungen der Parteien unterliegen dem Recht der Bundesrepublik Deutschland.<br/>
        2.	Erfüllungsort und ausschließlicher Gerichtsstand und für alle Streitigkeiten aus diesem Vertrag ist unser Geschäftssitz.<br/> 
        3.	Alle Vereinbarungen, die zwischen den Parteien zwecks Ausführung der Geschäftsbeziehungen getroffen werden, sind in diesen Bedingungen grundsätzlich schriftlich niedergelegt.<br/>
        `,
      en: `1. This contract and all legal relationships between the parties are subject to the law of the Federal Republic of Germany.<br/>
      2. Place of performance and exclusive place of jurisdiction and for all disputes arising from this contract is our place of business.<br/>
      3. All agreements made between the parties for the purpose of executing the business relationship are set out in writing in these terms and conditions.<br/>
      `,
    },
  },
];

const Terms = ({ currentLanguage }) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="card">
      <div>
        <h5 className="font-weight-bold">
          {language[currentLanguage].termsOfUse}
        </h5>
        <hr />
      </div>
      <div className="card-body m-3">
        <ol className="card-text  p-0 list-decoration">
          {TermsData.map(({ heading, content }) => (
            <li>
              {console.log("heading", heading, currentLanguage)}
              <b>{heading[currentLanguage]}</b>
              <p
                dangerouslySetInnerHTML={{ __html: content[currentLanguage] }}
              ></p>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Terms;
