import { isEmpty } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getNotificationsData } from "../../services/dataServices";
import language from "../../utils/language.json";

const Notifications = (props) => {
  const {
    currentLanguage,
    logout,
    location: { key },
  } = props;
  const [notificationsList, setNotifcationsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [key]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await getNotificationsData();
      setNotifcationsList(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        toast.error(currentLanguage === "en" ? message : message_de);
      }
      if (e.response && e.response.status === 403) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading ? (
        <div className="reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border full-page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h5 className="font-weight-bold">
            {language[currentLanguage].notifications}
          </h5>

          <hr />

          {isEmpty(notificationsList) ? (
            <h5>{language[currentLanguage].noNotifications}</h5>
          ) : (
            <div>
              {notificationsList.map(
                ({
                  ju_id,
                  ju_orderId,
                  product_image,
                  notification_message,
                  notification_message_de,
                  notification_time,
                  ju_notification_id,
                }) => (
                  <>
                    <Link
                      className="link-decoration"
                      key={ju_notification_id}
                      to={`/home/order-details/${ju_orderId}`}
                    >
                      <div className="card border-light flex-row">
                        <img
                          className="card-img mr-3"
                          src={product_image}
                          alt="Card"
                        />
                        <div className="card-body">
                          <p className="card-text font-weight-bold my-1 p-0">
                            {language[currentLanguage].myOrderId}: {ju_orderId}
                          </p>

                          <p className="card-text font-weight-bold my-1 p-0">
                            {language[currentLanguage].message}:{" "}
                            {currentLanguage === "en"
                              ? notification_message
                              : notification_message_de}
                          </p>
                          <p className="card-text text-right">
                            <small className="text-muted">
                              {moment(notification_time).format(
                                "DD.MM.YYYY H:mm"
                              )}
                            </small>
                          </p>
                        </div>
                      </div>
                    </Link>
                    <hr />
                  </>
                )
              )}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Notifications;
