import React from "react";

const DetailsProperties = ({
  data: {
    language,
    ju_id,
    ju_order_id,
    product_id,
    product_gender,
    ring_surface,
    material_color,
    ring_size,
    material_karat,
    main_stone,
    gravur,
    currentLanguage,
  },
}) => (
  <>
    <strong className="ring-properties">
      {language[currentLanguage].juOrderId}: {ju_order_id}
    </strong>
    <strong className="ring-properties">
      {language[currentLanguage].productId}: {product_id}
    </strong>
    <strong className="ring-properties">
      {language[currentLanguage].gender}: {product_gender}
    </strong>
    <strong className="ring-properties">
      {language[currentLanguage].material}:{" "}
      {material_color && material_color.toLowerCase() === "gelb"
        ? language[currentLanguage].yellowGold
        : material_color.toLowerCase() === "rot"
        ? language[currentLanguage].redGold
        : material_color.toLowerCase() === "weiß"
        ? language[currentLanguage].whiteGold
        : material_color.toLowerCase() === "platin"
        ? language[currentLanguage].platinum
        : material_color.toLowerCase() === "rose"
        ? language[currentLanguage].roseGold
        : material_color.toLowerCase() === "zirkonium"
        ? language[currentLanguage].zirconium
        : language[currentLanguage].titanium}
    </strong>
    <strong className="ring-properties">
      {language[currentLanguage].size}: {ring_size}
    </strong>
    <strong className="ring-properties">
      {language[currentLanguage].weight}: {material_karat}
    </strong>
    <strong className="ring-properties">
      {language[currentLanguage].stone}:{" "}
      {main_stone && main_stone.toLowerCase() === "diamond"
        ? language[currentLanguage].diamond
        : main_stone}
    </strong>
    <strong className="ring-properties">
      {language[currentLanguage].surface}:{" "}
      {ring_surface.toLowerCase() === "matt"
        ? language[currentLanguage].matt
        : language[currentLanguage].glanz}
    </strong>
    <strong className="ring-properties">
      {language[currentLanguage].engraving}: {gravur}
    </strong>
  </>
);

export default DetailsProperties;
