import { Formik } from "formik";
import { useState } from "react";
import MaskedInput from "react-maskedinput";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FloatingInputField, QRModal } from "../../components";
import { getProductDetails } from "../../services/dataServices";
import language from "../../utils/language.json";

const AllProducts = ({ currentLanguage, history, logout }) => {
  const [loading, setLoading] = useState(false);
  const [camLoading, setCamLoading] = useState(false);
  const [displayReader, setDisplayReader] = useState(false);
  const [result, setResult] = useState(false);
  const [showInputField, setShowInputField] = useState(false);
  const handleError = (e) => {
    setDisplayReader(false);
  };

  const handleScan = async (data) => {
    if (data != null) {
      const updatedData = data.split("/");
      fetchData({ orderId: updatedData[0], orderDate: updatedData[1] });
    }
  };

  const fetchData = async ({ orderId, orderDate }) => {
    setLoading(true);
    setResult(true);
    try {
      const {
        data: { data, success, message, message_de },
      } = await getProductDetails({
        orderId,
        orderDate,
      });
      setResult(false);
      setLoading(false);
      if (success === true) {
        setDisplayReader(false);
        history.push({
          pathname: `/home/scanned-product/${orderId}`,
          state: { rings: data[0] },
        });
      } else {
        toast.error(currentLanguage === "en" ? message : message_de);
      }
    } catch (e) {
      setResult(false);
      if (e.response && e.response.status === 403) {
        logout();
      }
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        toast.error(currentLanguage === "en" ? message : message_de);
      }
    }
  };

  const handleClose = () => {
    setDisplayReader(false);
  };
  const valueSchema = Yup.object().shape({
    orderId: Yup.string().required(language[currentLanguage].required),
    orderDate: Yup.string().required(language[currentLanguage].required),
  });

  return (
    <div className="mx-1">
      {loading ? (
        <div className="reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border full-page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {displayReader && (
            <QRModal
              handleError={handleError}
              handleScan={handleScan}
              modal={true}
              result={result}
              closeModal={handleClose}
              currentLanguage={currentLanguage}
              setCamLoading={setCamLoading}
            />
          )}

          <div className="col-md-12 px-0">
            <div className="d-flex justify-content-between">
              <span className="text-align-left">
                {" "}
                <h5 className="font-weight-bold">
                  {language[currentLanguage].home}{" "}
                </h5>
              </span>
            </div>
          </div>
          <hr />
          <div className="products-top-section shadow">
            <h5>{language[currentLanguage].importantInfo}</h5>
            <Link to="/home/quality-standards" className="link-decoration">
              <p className="text-custom-font">
                {language[currentLanguage].qualityStandards}
                <i
                  className="fa fa-angle-right fa-lg ml-2"
                  aria-hidden="true"
                />
              </p>
            </Link>
          </div>
          <hr />
          <div className="row d-flex justify-content-center d-flex flex-column">
            <button
              className="btn btn-outline-primary selection-btn my-3 "
              onClick={() => {
                setDisplayReader(true);
                setShowInputField(false);
                setCamLoading(true);
              }}
            >
              {language[currentLanguage].qrReader}
            </button>

            <button
              className="btn btn-outline-primary w-100 selection-btn mb-2"
              onClick={() => setShowInputField(!showInputField)}
            >
             {language[currentLanguage].enterValue}
            </button>

            <Formik
              initialValues={{ orderId: "", orderDate: "" }}
              validationSchema={valueSchema}
              onSubmit={(values) => {
                fetchData(values);
              }}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} className="mt-3">
                  {showInputField && (
                    <>
                      <FloatingInputField
                        id="orderId"
                        name="orderId"
                        type="number"
                        value={values.orderId}
                        onChange={(e) => {
                          if (e.target.value.match(/^[0-9]+$/)) {
                            setFieldValue("orderId", e.target.value);
                          } else {
                            setFieldValue("orderId", "");
                          }
                        }}
                        min="1"
                        placeholderValue={language[currentLanguage].valuePlace}
                        error={
                          errors.orderId && touched.orderId && errors.orderId
                        }
                      />
                      <div className="w-100 mb-2 pb-2">
                        <div className="floating-label-field">
                          <MaskedInput
                            type="text"
                            name="orderDate"
                            id="orderDate"
                            className="fullname"
                            value={values.orderDate}
                            placeholder="DD.MM.YYYY"
                            onChange={handleChange}
                            mask="11.11.1111"
                          />
                          <label className="fullname" htmlFor="orderDate">
                            {language[currentLanguage].holdOrderDate}
                          </label>
                        </div>
                        <span className="text-danger">
                          {errors.orderDate &&
                            touched.orderDate &&
                            errors.orderDate}
                        </span>
                      </div>
                      <button
                        className="btn btn-primary w-100 mb-4"
                        type="submit"
                      >
                        {loading
                          ? language[currentLanguage].loading
                          : language[currentLanguage].submit}
                      </button>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProducts;
