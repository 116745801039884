import { Formik } from "formik";
import * as _ from "lodash";
import React, { Component } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { changeCompanyAddress } from "../services/authService";
import language from "../utils/language.json";
import FloatingInputField from "./floattingInput";

class UpdateAccountInfo extends Component {
  state = {};

  handleUpdate = async (values) => {
    const { currentLanguage, setUpdateData, logout } = this.props;
    const {
      company_address: address,
      company_address_comment: address_comment,
      company_city: city,
      company_country: country,
      company_name: name,
      company_postcode: postcode,
      company_shipping_address: shipping_address,
      company_shipping_address_comment: shipping_address_comment,
      company_shipping_city: shipping_city,
      company_shipping_country: shipping_country,
      company_shipping_name: shipping_name,
      company_shipping_postcode: shipping_postcode,
      company_pickup_address: pickup_address,
      company_pickup_address_comment: pickup_address_comment,
      company_pickup_city: pickup_city,
      company_pickup_country: pickup_country,
      company_pickup_name: pickup_name,
      company_pickup_postcode: pickup_postcode,
    } = values;
    const updatedObj = {
      address,
      address_comment,
      city,
      country,
      name,
      postcode,
      shipping_address,
      shipping_address_comment,
      shipping_city,
      shipping_country,
      shipping_name,
      shipping_postcode,
      pickup_address,
      pickup_address_comment,
      pickup_city,
      pickup_country,
      pickup_name,
      pickup_postcode,
    };
    try {
      const {
        data: { message, message_de },
      } = await changeCompanyAddress(updatedObj);
      setUpdateData(true);
      toast.success(currentLanguage === "en" ? message : message_de);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        toast.error(currentLanguage === "de" ? message_de : message);
      }
      if (e.response && e.response.status === 403) {
        logout();
      }
    }
  };

  render() {
    const { currentLanguage } = this.props;
    const addressSchema = Yup.object().shape({
      company_country: Yup.string().required(
        language[currentLanguage].required
      ),

      company_name: Yup.string().required(language[currentLanguage].required),
      company_address: Yup.string().required(
        language[currentLanguage].required
      ),
      company_address_comment: Yup.string(),
      company_postcode: Yup.number().required(
        language[currentLanguage].required
      ),
      company_city: Yup.string().required(language[currentLanguage].required),
      company_shipping_country: Yup.string(),
      company_shipping_name: Yup.string().required(
        language[currentLanguage].required
      ),
      company_shipping_address: Yup.string().required(
        language[currentLanguage].required
      ),
      company_shipping_address_comment: Yup.string(),
      company_shipping_postcode: Yup.number().required(
        language[currentLanguage].required
      ),
      company_shipping_city: Yup.string().required(
        language[currentLanguage].required
      ),
      company_pickup_country: Yup.string(),
      company_pickup_name: Yup.string().required(
        language[currentLanguage].required
      ),
      company_pickup_address: Yup.string().required(
        language[currentLanguage].required
      ),
      company_pickup_address_comment: Yup.string(),
      company_pickup_postcode: Yup.number().required(
        language[currentLanguage].required
      ),
      company_pickup_city: Yup.string().required(
        language[currentLanguage].required
      ),
    });
    const {
      company_country,
      company_shipping_country,
      company_pickup_country,
      company_name,
      company_address,
      company_address_comment,
      company_postcode,
      company_city,
      company_shipping_name,
      company_shipping_address,
      company_shipping_address_comment,
      company_shipping_postcode,
      company_shipping_city,
      company_pickup_name,
      company_pickup_address,
      company_pickup_address_comment,
      company_pickup_postcode,
      company_pickup_city,
    } = this.props.data;

    return (
      <div>
        <Formik
          initialValues={{
            company_country,
            company_name,
            company_address,
            company_address_comment,
            company_postcode,
            company_city,
            company_shipping_country,
            company_shipping_name,
            company_shipping_address,
            company_shipping_address_comment,
            company_shipping_postcode,
            company_shipping_city,
            company_pickup_country,
            company_pickup_name,
            company_pickup_address,
            company_pickup_address_comment,
            company_pickup_postcode,
            company_pickup_city,
          }}
          validationSchema={addressSchema}
          onSubmit={(values, { setSubmitting }) => {
            this.handleUpdate(values);
            setSubmitting(true);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 p-0">
                  <h6>
                    <u>{language[currentLanguage].companyAddressTitle}</u>
                  </h6>
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <CountryDropdown
                    defaultOptionLabel={language[currentLanguage].selectCountry}
                    name="company_country"
                    value={values.company_country}
                    className="country-list mb-4"
                    onChange={(_, e) => handleChange(e)}
                  />
                  {!_.isEmpty(errors.company_country) && (
                    <p className="error">{errors.company_country}</p>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3 mt-2">
                  <FloatingInputField
                    type="text"
                    name="company_name"
                    value={values.company_name}
                    placeholderValue={language[currentLanguage].name}
                    onChange={handleChange}
                    error={
                      errors.company_name &&
                      touched.company_name &&
                      errors.company_name
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="text"
                    name="company_address"
                    value={values.company_address}
                    placeholderValue={language[currentLanguage].address}
                    onChange={handleChange}
                    error={
                      errors.company_address &&
                      touched.company_address &&
                      errors.company_address
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="text"
                    name="company_address_comment"
                    value={values.company_address_comment}
                    placeholderValue={language[currentLanguage].addressComment}
                    onChange={handleChange}
                    error={
                      errors.company_address_comment &&
                      touched.company_address_comment &&
                      errors.company_address_comment
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="number"
                    name="company_postcode"
                    value={values.company_postcode}
                    placeholderValue={language[currentLanguage].postCode}
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9]+$/)) {
                        setFieldValue("company_postcode", e.target.value);
                      }
                    }}
                    error={
                      errors.company_postcode &&
                      touched.company_postcode &&
                      errors.company_postcode
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="text"
                    name="company_city"
                    value={values.company_city}
                    placeholderValue={language[currentLanguage].place}
                    onChange={handleChange}
                    error={
                      errors.company_city &&
                      touched.company_city &&
                      errors.company_city
                    }
                  />
                </div>
                <br />
                <div className="col-12 p-0">
                  <h6>
                    <u>{language[currentLanguage].shippingLabelTitle}</u>
                  </h6>
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <CountryDropdown
                    defaultOptionLabel={language[currentLanguage].selectCountry}
                    name="company_shipping_country"
                    value={values.company_shipping_country}
                    className="country-list mb-4"
                    onChange={(_, e) => handleChange(e)}
                  />
                  {!_.isEmpty(errors.company_shipping_country) && (
                    <p className="error">{errors.company_shipping_country}</p>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3 mt-2">
                  <FloatingInputField
                    type="text"
                    name="company_shipping_name"
                    value={values.company_shipping_name}
                    placeholderValue={language[currentLanguage].name}
                    onChange={handleChange}
                    error={
                      errors.company_shipping_name &&
                      touched.company_shipping_name &&
                      errors.company_shipping_name
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="text"
                    name="company_shipping_address"
                    value={values.company_shipping_address}
                    placeholderValue={language[currentLanguage].address}
                    onChange={handleChange}
                    error={
                      errors.company_shipping_address &&
                      touched.company_shipping_address &&
                      errors.company_shipping_address
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="text"
                    name="company_shipping_address_comment"
                    value={values.company_shipping_address_comment}
                    placeholderValue={language[currentLanguage].addressComment}
                    onChange={handleChange}
                    error={
                      errors.company_shipping_address_comment &&
                      touched.company_shipping_address_comment &&
                      errors.company_shipping_address_comment
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="number"
                    name="company_shipping_postcode"
                    value={values.company_shipping_postcode}
                    placeholderValue={language[currentLanguage].postCode}
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9]+$/)) {
                        setFieldValue(
                          "company_shipping_postcode",
                          e.target.value
                        );
                      }
                    }}
                    error={
                      errors.company_shipping_postcode &&
                      touched.company_shipping_postcode &&
                      errors.company_shipping_postcode
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="text"
                    name="company_shipping_city"
                    value={values.company_shipping_city}
                    placeholderValue={language[currentLanguage].place}
                    onChange={handleChange}
                    error={
                      errors.company_shipping_city &&
                      touched.company_shipping_city &&
                      errors.company_shipping_city
                    }
                  />
                </div>
                <br />
                <div className="col-12 p-0">
                  <h6>
                    <u>{language[currentLanguage].pickupLabelTitle}</u>
                  </h6>
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <CountryDropdown
                    defaultOptionLabel={language[currentLanguage].selectCountry}
                    name="company_pickup_country"
                    value={values.company_pickup_country}
                    className="country-list mb-4"
                    onChange={(_, e) => handleChange(e)}
                  />
                  {!_.isEmpty(errors.company_pickup_country) && (
                    <p className="error">{errors.company_pickup_country}</p>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3 mt-2">
                  <FloatingInputField
                    type="text"
                    name="company_pickup_name"
                    value={values.company_pickup_name}
                    placeholderValue={language[currentLanguage].name}
                    onChange={handleChange}
                    error={
                      errors.company_pickup_name &&
                      touched.company_pickup_name &&
                      errors.company_pickup_name
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="text"
                    name="company_pickup_address"
                    value={values.company_pickup_address}
                    placeholderValue={language[currentLanguage].address}
                    onChange={handleChange}
                    error={
                      errors.company_pickup_address &&
                      touched.company_pickup_address &&
                      errors.company_pickup_address
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="text"
                    name="company_pickup_address_comment"
                    value={values.company_pickup_address_comment}
                    placeholderValue={language[currentLanguage].addressComment}
                    onChange={handleChange}
                    error={
                      errors.company_pickup_address_comment &&
                      touched.company_pickup_address_comment &&
                      errors.company_pickup_address_comment
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="number"
                    name="company_pickup_postcode"
                    value={values.company_pickup_postcode}
                    placeholderValue={language[currentLanguage].postCode}
                    onChange={(e) => {
                      if (e.target.value.match(/^[0-9]+$/)) {
                        setFieldValue(
                          "company_pickup_postcode",
                          e.target.value
                        );
                      }
                    }}
                    error={
                      errors.company_pickup_postcode &&
                      touched.company_pickup_postcode &&
                      errors.company_pickup_postcode
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pr-0 pr-md-3">
                  <FloatingInputField
                    type="text"
                    name="company_pickup_city"
                    value={values.company_pickup_city}
                    placeholderValue={language[currentLanguage].place}
                    onChange={handleChange}
                    error={
                      errors.company_pickup_city &&
                      touched.company_pickup_city &&
                      errors.company_pickup_city
                    }
                  />
                </div>

                <button className="btn btn-primary save-button" type="submit">
                  {language[currentLanguage].saveChanges}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(UpdateAccountInfo);
