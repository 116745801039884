import { Component } from "react";
import { toast } from "react-toastify";
import {
  EmailInputField,
  FinalNotification,
  FooterLogo,
} from "../../components";
import { forgotPassword } from "../../services/authService";
import language from "../../utils/language.json";

class PasswordReset extends Component {
  state = {
    emailSection: true,
    loading: false,
  };

  handleSubmission = async (values, { setSubmitting }) => {
    const { currentLanguage } = this.props;
    const updatedObj = { ...values, currentLanguage };
    this.setState({ loading: true });
    try {
      await forgotPassword(updatedObj);
      this.setState({ emailSection: false, loading: false });
    } catch (ex) {
      if (ex.response && (ex.response.status === 404 || ex.response.status === 400)) {
        this.setState({ loading: false });
        const { message,message_de } = ex.response.data;
        toast.error(currentLanguage === "en" ? message : message_de);
      }
    }
    setSubmitting(false);
  };

  render() {
    const { emailSection, loading } = this.state;
    const { currentLanguage } = this.props;

    return (
      <>
        <div className="reset-page-height">
          {emailSection === true ? (
            <EmailInputField
              resendEmail={this.handleSubmission}
              loading={loading}
              currentLanguage={currentLanguage}
            />
          ) : (
            <FinalNotification
              heading={language[currentLanguage].resetPassword}
              bodyTitle={language[currentLanguage].bodyTitle}
              bodyText={language[currentLanguage].bodyText}
              bodyEnd={language[currentLanguage].thankYou}
            />
          )}
        </div>
        <FooterLogo />
      </>
    );
  }
}

export default PasswordReset;
