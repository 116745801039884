import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "../firebase";
import { notificationToken } from "../services/authService";
import { getCounters } from "../services/dataServices";
import language from "../utils/language.json";
import { AppContext } from "../appContext";

const SideBar = (props) => {
  const { currentLanguage, handleSideBar, logout, name } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state, setState } = useContext(AppContext);

  const sendToken = async (token) => {
    await notificationToken(token);
  };

  useEffect(() => {
    getAllCounters();
    setState({ showNotification: false });
    try {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          sendToken(token);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log("eror", e.message);
    }
  }, []);

  useEffect(() => {
    setInterval(getAllCounters, 10000);
  }, []);

  const getAllCounters = async () => {
    if (!localStorage.getItem("authorization")) return;
    try {
      const {
        data: { data },
      } = await getCounters();
      setData(data[0]);
      if (data[0].notifications > 0) setState({ showNotification: true });
    } catch (e) {
      if (e.response && e.response.status === 403) {
        logout();
      }
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        toast.error(currentLanguage === "en" ? message : message_de);
      }
    }
  };

  const { active_orders, messages, offers, past_orders, notifications } = data;
  return (
    <>
      {loading ? (
        <div className="d-lg-none reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border full-page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="mx-1">
          <div>
            <ul className="list-group shadow mt-3 mb-3">
              <li className="list-group-item shadow">
                <i className="fa fa-user-o" aria-hidden="true"></i>
                {name}
              </li>
              <Link
                to="/home"
                className="link-decoration"
                onClick={handleSideBar}
              >
                <li className="list-group-item shadow">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  {language[currentLanguage].home}
                </li>
              </Link>
              <Link
                to="/home/messages"
                className="link-decoration"
                onClick={handleSideBar}
              >
                <li className="list-group-item shadow">
                  <i className="fa fa-comments-o" aria-hidden="true"></i>
                  {language[currentLanguage].messages}
                  <span
                    className={
                      messages === 0
                        ? "badge badge-light"
                        : "badge badge-danger"
                    }
                  >
                    {messages}
                  </span>
                </li>
              </Link>
              <Link
                to="/home/notifications"
                className="link-decoration"
                onClick={handleSideBar}
              >
                <li className="list-group-item shadow">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                  {language[currentLanguage].notifications}
                  <span
                    className={
                      notifications === 0
                        ? "badge badge-light"
                        : "badge badge-danger"
                    }
                  >
                    {notifications}
                  </span>
                </li>
              </Link>
            </ul>
          </div>
          <div>
            <ul className="list-group shadow mt-3 mb-3">
              <Link
                to="/home/active-orders"
                className="link-decoration"
                onClick={handleSideBar}
              >
                <li className="list-group-item shadow">
                  <i className="fa fa-shopping-bag" aria-hidden="true"></i>
                  {language[currentLanguage].myActiveOrders}
                  <span
                    className={
                      active_orders > 0
                        ? "badge badge-primary"
                        : "badge badge-light"
                    }
                  >
                    {active_orders}
                  </span>
                </li>
              </Link>
              <Link
                to="/home/past-orders"
                className="link-decoration"
                onClick={handleSideBar}
              >
                <li className="list-group-item shadow">
                  <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                  {language[currentLanguage].myFinishOrders}
                  <span className="badge badge-light">{past_orders}</span>
                </li>
              </Link>
              <Link
                to="/home/active-offers"
                className="link-decoration"
                onClick={handleSideBar}
              >
                <li className="list-group-item shadow">
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                  {language[currentLanguage].offers}
                  <span
                    className={
                      offers > 0 ? "badge badge-warning" : "badge badge-light"
                    }
                  >
                    {offers}
                  </span>
                </li>
              </Link>
              <Link
                to="/home/receipts"
                className="link-decoration"
                onClick={handleSideBar}
              >
                <li className="list-group-item shadow">
                  <i className="fa fa-files-o" aria-hidden="true"></i>
                  {language[currentLanguage].receipts}
                </li>
              </Link>

              <Link
                to="/home/contact"
                className="link-decoration"
                onClick={handleSideBar}
              >
                <li className="list-group-item shadow">
                  <i className="fa fa-comments-o" aria-hidden="true"></i>
                  {language[currentLanguage].contact}
                </li>
              </Link>
              <Link
                to="/home/faq"
                className="link-decoration"
                onClick={handleSideBar}
              >
                <li className="list-group-item shadow">
                  <i className="fa fa-question" aria-hidden="true"></i>
                  FAQ
                </li>
              </Link>
              <Link
                to="/home/settings"
                className="link-decoration"
                onClick={handleSideBar}
              >
                <li className="list-group-item shadow">
                  <i className="fa fa-sliders" aria-hidden="true"></i>
                  {language[currentLanguage].settings}
                </li>
              </Link>
            </ul>
          </div>
        </div>
      )}
    </>
    // </div>
  );
};

export default SideBar;
