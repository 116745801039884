import React, { useEffect, useState } from "react";

import language from "../../utils/language.json";
import { confirmedBankDetails } from "../../services/authService";
import { toast } from "react-toastify";

export default function BankDetailsConfirmation(props) {
  const {
    currentLanguage,
    match: {
      params: { token },
    },
  } = props;
  const [finalMessage, setFinalMessage] = useState("");
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const {
        data: { message, message_de, bank_verified },
      } = await confirmedBankDetails(token);
      if (bank_verified === true) {
        setVerified(true);
        setFinalMessage(currentLanguage === "en" ? message : message_de);
      } else {
        setFinalMessage(currentLanguage === "en" ? message : message_de);
        toast.info(currentLanguage === "en" ? message : message_de);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        setFinalMessage(currentLanguage === "en" ? message : message_de);
        toast.error(currentLanguage === "en" ? message : message_de);
      }
    }
  };
  return (
    <>
      {loading ? (
        <div className="reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border full-page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-sm m-auto text-center h-100 py-4">
          <h4 className="mb-4">
            {finalMessage}
          </h4>
        </div>
      )}
    </>
  );
}
