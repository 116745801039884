import { isEmpty } from "lodash";
import language from "../utils/language.json";

const ActiveOrderButtons = ({
  currentLanguage,
  handleClick,
  rings,
  prices,
}) => {
  const { store_name, store_address } = rings;
  const { pickup_price } = prices;
  return (
    <div className="d-flex flex-column ">
      {!isEmpty(store_name) && !isEmpty(store_address) && (
        <button
          onClick={() => handleClick({ name: "pickup" })}
          className="btn btn-outline-secondary selected-btn mb-2"
        >
          {`${language[currentLanguage].pickupBtn}`}
        </button>
      )}
      {/*
      <button
        onClick={() => handleClick({ name: "return" })}
        className="btn btn-outline-secondary selected-btn mb-2"
      >
        {language[currentLanguage].returnBtn}
      </button> */}
      <button
        onClick={() => handleClick({ name: "repair" })}
        className="btn btn-outline-secondary selected-btn mb-2"
      >
        {language[currentLanguage].repairBtn}
      </button>
      <button
        onClick={() => handleClick({ name: "cleanup" })}
        className="btn btn-outline-secondary selected-btn mb-2"
      >
        {language[currentLanguage].cleanupBtn}
      </button>
      <button
        onClick={() => handleClick({ name: "adjustment" })}
        className="btn btn-outline-secondary selected-btn mb-2"
      >
        {language[currentLanguage].adjustmentBtn}
      </button>
    </div>
  );
};

export default ActiveOrderButtons;
