import { Formik } from "formik";
import React, { Component } from "react";
import * as Yup from "yup";
import { isEmpty } from "lodash";

import { FloatingInputField } from "../../../components";
import language from "../../../utils/language.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const phoneRegex = RegExp("^[a-zA-Z0-9]{0,16}$");

class PersonalDetails extends Component {
  state = {};
  displayNext = (e) => {
    this.props.handleNext();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    const { currentLanguage, completeData } = this.props;
    const PersonalDetailsSchema = Yup.object().shape({
      company_name: Yup.string().required(
        language[currentLanguage].companyNameRequired
      ),
      firstName: Yup.string().required(
        language[currentLanguage].firstNameRequired
      ),
      lastName: Yup.string().required(
        language[currentLanguage].surNameRequired
      ),
      email: Yup.string()
        .email(language[currentLanguage].invalidEmail)
        .required(language[currentLanguage].emailRequired),
      repeatEmail: Yup.string().oneOf(
        [Yup.ref("email"), null],
        language[currentLanguage].emailNotMatched
      ),
      password: Yup.string()
        .min(4)
        .required(language[currentLanguage].passwordRequired),
      phone: Yup.string()
        .matches(phoneRegex, language[currentLanguage].phoneLength)
        .required(language[currentLanguage].phoneNumberRequired),
    });
    return (
      <>
        <div className="app-width p-3 d-flex flex-column justify-content-center ">
          <h5 className="mb-3">{language[currentLanguage].hello}</h5>
          <h5 className="reset-password-title-heading pb-4">
            {language[currentLanguage].setUpNewAcc}
          </h5>
          <Formik
            initialValues={{
              company_name: "",
              firstName: "",
              lastName: "",
              email: "",
              repeatEmail: "",
              password: "",
              phone: "",
              ...completeData,
            }}
            validationSchema={PersonalDetailsSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              this.props.collectValues(values);
              this.displayNext();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <FloatingInputField
                  type="text"
                  name="company_name"
                  value={values.company_name}
                  placeholderValue={language[currentLanguage].companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.company_name &&
                    touched.company_name &&
                    errors.company_name
                  }
                />
                <div className="pb-2">
                  <PhoneInput
                    country={"de"}
                    name="phone"
                    value={values.phone}
                    onChange={(e) => setFieldValue("phone", e)}
                  />
                  {!isEmpty(errors.phone) &&
                    errors.phone &&
                    touched.phone &&
                    errors.phone && <p className="error">{errors.phone}</p>}
                </div>
                <FloatingInputField
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  placeholderValue={language[currentLanguage].firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={
                    errors.firstName && touched.firstName && errors.firstName
                  }
                />

                <FloatingInputField
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  placeholderValue={language[currentLanguage].surName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName && touched.lastName && errors.lastName}
                />
                <FloatingInputField
                  type="email"
                  name="email"
                  value={values.email}
                  placeholderValue={language[currentLanguage].email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email && touched.email && errors.email}
                />
                <FloatingInputField
                  type="email"
                  name="repeatEmail"
                  value={values.repeatEmail}
                  placeholderValue={language[currentLanguage].emailRepeat}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.repeatEmail &&
                    touched.repeatEmail &&
                    errors.repeatEmail
                  }
                />
                <FloatingInputField
                  type="password"
                  name="password"
                  currentLanguage={currentLanguage}
                  value={values.password}
                  placeholderValue={language[currentLanguage].password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password && touched.password && errors.password}
                />

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary btn-block mb-2"
                >
                  {language[currentLanguage].next}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </>
    );
  }
}

export default PersonalDetails;
