import { Formik } from "formik";
import { isEmpty } from "lodash";
import React, { Component } from "react";
import { CountryDropdown } from "react-country-region-selector";
import * as Yup from "yup";

import { FloatingInputField } from "../../../components";
import language from "../../../utils/language.json";

class AddressDetails extends Component {
  state = {};
  displayNext = (e) => {
    this.props.handleNext();
  };
  displayBack = (e) => {
    this.props.handleBack();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const {
      currentLanguage,
      completeData,
      handleRadioSelection,
      handleSecondRadioSelection,
      radioCheck1,
      radioCheck2,
    } = this.props;
    const addressSchema = Yup.object().shape({
      company_country: Yup.string().required(
        language[currentLanguage].countryRequired
      ),
      company_name: Yup.string().required(
        language[currentLanguage].nameRequired
      ),
      company_address: Yup.string().required(
        language[currentLanguage].addressRequired
      ),
      company_address_comment: Yup.string(),
      company_postcode: Yup.number().required(
        language[currentLanguage].postCodeRequired
      ),
      company_city: Yup.string().required(
        language[currentLanguage].placeRequired
      ),
      company_shipping_country: Yup.string().required(
        language[currentLanguage].countryRequired
      ),
      company_shipping_name: Yup.string().required(
        language[currentLanguage].nameRequired
      ),
      company_shipping_address: Yup.string().required(
        language[currentLanguage].addressRequired
      ),
      company_shipping_address_comment: Yup.string(),
      company_shipping_postcode: Yup.number().required(
        language[currentLanguage].postCodeRequired
      ),
      company_shipping_city: Yup.string().required(
        language[currentLanguage].placeRequired
      ),
      company_pickup_country: Yup.string().required(
        language[currentLanguage].countryRequired
      ),
      company_pickup_name: Yup.string().required(
        language[currentLanguage].nameRequired
      ),
      company_pickup_address: Yup.string().required(
        language[currentLanguage].addressRequired
      ),
      company_pickup_address_comment: Yup.string(),
      company_pickup_postcode: Yup.number().required(
        language[currentLanguage].postCodeRequired
      ),
      company_pickup_city: Yup.string().required(
        language[currentLanguage].placeRequired
      ),
    });

    return (
      <div className="app-width d-flex m-auto flex-column p-3 justify-content-center ">
        <p className="mb-4">{language[currentLanguage].companyAddress}</p>
        <Formik
          initialValues={{
            company_country: "",
            company_shipping_country: "",
            company_name: "",
            company_address: "",
            company_address_comment: "",
            company_postcode: "",
            company_city: "",
            company_shipping_name: "",
            company_shipping_address: "",
            company_shipping_address_comment: "",
            company_shipping_postcode: "",
            company_shipping_city: "",
            company_pickup_country: "",
            company_pickup_name: "",
            company_pickup_address: "",
            company_pickup_address_comment: "",
            company_pickup_postcode: "",
            company_pickup_city: "",
            ...completeData,
          }}
          validationSchema={addressSchema}
          onSubmit={(values, { setSubmitting }) => {
            this.props.collectValues(values);
            this.displayNext();
            setSubmitting(true);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit}>
              <CountryDropdown
                defaultOptionLabel={language[currentLanguage].selectCountry}
                name="company_country"
                value={values.company_country}
                onBlur={handleBlur}
                className="country-list mb-4"
                onChange={(_, e) => handleChange(e)}
              />
              {!isEmpty(
                errors.company_country &&
                  touched.company_country &&
                  errors.company_country
              ) && <p className="error">{errors.company_country}</p>}

              <FloatingInputField
                type="text"
                name="company_name"
                value={values.company_name}
                placeholderValue={language[currentLanguage].name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.company_name &&
                  touched.company_name &&
                  errors.company_name
                }
              />
              <FloatingInputField
                type="text"
                name="company_address"
                onBlur={handleBlur}
                value={values.company_address}
                placeholderValue={language[currentLanguage].address}
                onChange={handleChange}
                error={
                  errors.company_address &&
                  touched.company_address &&
                  errors.company_address
                }
              />
              <FloatingInputField
                type="text"
                name="company_address_comment"
                value={values.company_address_comment}
                placeholderValue={language[currentLanguage].addressComment}
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  errors.company_address_comment &&
                  touched.company_address_comment &&
                  errors.company_address_comment
                }
              />
              <FloatingInputField
                type="number"
                name="company_postcode"
                value={values.company_postcode}
                placeholderValue={language[currentLanguage].postCode}
                onChange={(e) => {
                  if (e.target.value.match(/^[0-9]+$/)) {
                    setFieldValue("company_postcode", e.target.value);
                  } else {
                    setFieldValue("company_postcode", "");
                  }
                }}
                onBlur={handleBlur}
                error={
                  errors.company_postcode &&
                  touched.company_postcode &&
                  errors.company_postcode
                }
              />
              <FloatingInputField
                type="text"
                name="company_city"
                value={values.company_city}
                placeholderValue={language[currentLanguage].place}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.company_city &&
                  touched.company_city &&
                  errors.company_city
                }
              />
              <div className="form-check mt-3 align-box-center ">
                <input
                  className="form-check-input checkbox-size"
                  type="radio"
                  name="radio1"
                  value={"isCompany"}
                  checked={radioCheck1 === "isCompany"}
                  onChange={(e) => {
                    console.log(
                      "values.company_country",
                      values.company_country
                    );
                    handleRadioSelection(e);
                    setFieldValue(
                      "company_shipping_country",
                      values.company_country
                    );

                    setFieldValue("company_shipping_name", values.company_name);
                    setFieldValue(
                      "company_shipping_address",
                      values.company_address
                    );
                    setFieldValue(
                      "company_shipping_address_comment",
                      values.company_address_comment
                    );
                    setFieldValue(
                      "company_shipping_postcode",
                      values.company_postcode
                    );
                    setFieldTouched("company_shipping_postcode", false);
                    setFieldValue("company_shipping_city", values.company_city);
                    setFieldTouched("company_shipping_country", false);
                  }}
                />
                <label
                  className="form-check-label text-align-checkbox ml-2"
                  htmlFor="radio1"
                >
                  {language[currentLanguage].equalAddress}
                </label>
              </div>
              <div className="form-check mt-2 mb-5 align-box-center ">
                <input
                  className="form-check-input checkbox-size"
                  type="radio"
                  name="radio1"
                  value={"isShipping"}
                  checked={radioCheck1 === "isShipping"}
                  onChange={(e) => {
                    handleRadioSelection(e);
                    setFieldValue("company_shipping_country", "");
                    setFieldTouched("company_shipping_country", true);
                    setFieldValue("company_shipping_name", "");
                    setFieldTouched("company_shipping_name", true);
                    setFieldValue("company_shipping_address", "");
                    setFieldTouched("company_shipping_address", true);
                    setFieldValue("company_shipping_address_comment", "");
                    setFieldTouched("company_shipping_address_comment", true);
                    setFieldValue("company_shipping_postcode", "");
                    setFieldTouched("company_shipping_postcode", true);
                    setFieldValue("company_shipping_city", "");
                    setFieldTouched("company_shipping_city", true);
                  }}
                />
                <label
                  className="form-check-label text-align-checkbox ml-2"
                  htmlFor="radio2"
                >
                  {language[currentLanguage].newShipping}
                </label>
              </div>
              <CountryDropdown
                defaultOptionLabel={language[currentLanguage].selectCountry}
                name="company_shipping_country"
                onBlur={handleBlur}
                value={values.company_shipping_country}
                className="country-list mb-4"
                onChange={(_, e) => handleChange(e)}
              />
              {!isEmpty(
                errors.company_shipping_country &&
                  touched.company_shipping_country &&
                  errors.company_shipping_country
              ) && <p className="error">{errors.company_shipping_country}</p>}

              <FloatingInputField
                type="text"
                name="company_shipping_name"
                value={values.company_shipping_name}
                placeholderValue={language[currentLanguage].name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  isEmpty(values.company_shipping_name) &&
                  errors.company_shipping_name &&
                  touched.company_shipping_name &&
                  errors.company_shipping_name
                }
              />
              <FloatingInputField
                type="text"
                name="company_shipping_address"
                value={values.company_shipping_address}
                placeholderValue={language[currentLanguage].address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  isEmpty(values.company_shipping_address) &&
                  errors.company_shipping_address &&
                  touched.company_shipping_address &&
                  errors.company_shipping_address
                }
              />
              <FloatingInputField
                type="text"
                name="company_shipping_address_comment"
                value={values.company_shipping_address_comment}
                placeholderValue={language[currentLanguage].addressComment}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  isEmpty(values.company_shipping_address_comment) &&
                  errors.company_shipping_address_comment &&
                  touched.company_shipping_address_comment &&
                  errors.company_shipping_address_comment
                }
              />
              <FloatingInputField
                type="number"
                name="company_shipping_postcode"
                value={values.company_shipping_postcode}
                placeholderValue={language[currentLanguage].postCode}
                onChange={(e) => {
                  if (e.target.value.match(/^[0-9]+$/)) {
                    setFieldValue("company_shipping_postcode", e.target.value);
                  } else {
                    setFieldValue("company_shipping_postcode", "");
                  }
                }}
                onBlur={handleBlur}
                error={
                  isEmpty(values.company_shipping_postcode) &&
                  errors.company_shipping_postcode &&
                  touched.company_shipping_postcode &&
                  errors.company_shipping_postcode
                }
              />
              <FloatingInputField
                type="text"
                name="company_shipping_city"
                value={values.company_shipping_city}
                placeholderValue={language[currentLanguage].place}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  isEmpty(values.company_shipping_city) &&
                  errors.company_shipping_city &&
                  touched.company_shipping_city &&
                  errors.company_shipping_city
                }
              />
              <div className="form-check mt-3 align-box-center ">
                <input
                  className="form-check-input checkbox-size"
                  type="radio"
                  name="radio4"
                  value={"isCompanyPickup"}
                  checked={radioCheck2 === "isCompanyPickup"}
                  onChange={(e) => {
                    handleSecondRadioSelection(e);
                    setFieldValue(
                      "company_pickup_country",
                      values.company_country
                    );
                    setFieldValue("company_pickup_name", values.company_name);
                    setFieldValue(
                      "company_pickup_address",
                      values.company_address
                    );
                    setFieldValue(
                      "company_pickup_address_comment",
                      values.company_address_comment
                    );
                    setFieldValue(
                      "company_pickup_postcode",
                      values.company_postcode
                    );
                    setFieldTouched("company_pickup_postcode", false);
                    setFieldValue("company_pickup_city", values.company_city);
                    setFieldTouched("company_pickup_country", false);
                  }}
                />
                <label
                  className="form-check-label text-align-checkbox ml-2"
                  htmlFor="radio4"
                >
                  {language[currentLanguage].equalAddress}
                </label>
              </div>
              <div className="form-check mt-2 mb-5 align-box-center ">
                <input
                  className="form-check-input checkbox-size"
                  type="radio"
                  name="radio4"
                  value={"isPickup"}
                  checked={radioCheck2 === "isPickup"}
                  onChange={(e) => {
                    handleSecondRadioSelection(e);
                    setFieldValue("company_pickup_country", "");
                    setFieldTouched("company_pickup_country", true);
                    setFieldValue("company_pickup_name", "");
                    setFieldTouched("company_pickup_name", true);
                    setFieldValue("company_pickup_address", "");
                    setFieldTouched("company_pickup_address", true);
                    setFieldValue("company_pickup_address_comment", "");
                    setFieldTouched("company_pickup_address_comment", true);
                    setFieldValue("company_pickup_postcode", "");
                    setFieldTouched("company_pickup_postcode", true);
                    setFieldValue("company_pickup_city", "");
                    setFieldTouched("company_pickup_city", true);
                  }}
                />
                <label
                  className="form-check-label text-align-checkbox ml-2"
                  htmlFor="radio4"
                >
                  {language[currentLanguage].newShipping}
                </label>
              </div>
              <CountryDropdown
                defaultOptionLabel={language[currentLanguage].selectCountry}
                name="company_pickup_country"
                onBlur={handleBlur}
                value={values.company_pickup_country}
                className="country-list mb-4"
                onChange={(_, e) => handleChange(e)}
              />
              {!isEmpty(
                errors.company_pickup_country &&
                  touched.company_pickup_country &&
                  errors.company_pickup_country
              ) && <p className="error">{errors.company_pickup_country}</p>}

              <FloatingInputField
                type="text"
                name="company_pickup_name"
                value={values.company_pickup_name}
                placeholderValue={language[currentLanguage].name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  isEmpty(values.company_pickup_name) &&
                  errors.company_pickup_name &&
                  touched.company_pickup_name &&
                  errors.company_pickup_name
                }
              />
              <FloatingInputField
                type="text"
                name="company_pickup_address"
                value={values.company_pickup_address}
                placeholderValue={language[currentLanguage].address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  isEmpty(values.company_pickup_address) &&
                  errors.company_pickup_address &&
                  touched.company_pickup_address &&
                  errors.company_pickup_address
                }
              />
              <FloatingInputField
                type="text"
                name="company_pickup_address_comment"
                value={values.company_pickup_address_comment}
                placeholderValue={language[currentLanguage].addressComment}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  isEmpty(values.company_pickup_address_comment) &&
                  errors.company_pickup_address_comment &&
                  touched.company_pickup_address_comment &&
                  errors.company_pickup_address_comment
                }
              />
              <FloatingInputField
                type="number"
                name="company_pickup_postcode"
                value={values.company_pickup_postcode}
                placeholderValue={language[currentLanguage].postCode}
                onChange={(e) => {
                  if (e.target.value.match(/^[0-9]+$/)) {
                    setFieldValue("company_pickup_postcode", e.target.value);
                  } else {
                    setFieldValue("company_pickup_postcode", "");
                  }
                }}
                onBlur={handleBlur}
                error={
                  isEmpty(values.company_pickup_postcode) &&
                  errors.company_pickup_postcode &&
                  touched.company_pickup_postcode &&
                  errors.company_pickup_postcode
                }
              />
              <FloatingInputField
                type="text"
                name="company_pickup_city"
                value={values.company_pickup_city}
                placeholderValue={language[currentLanguage].place}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  isEmpty(values.company_pickup_city) &&
                  errors.company_pickup_city &&
                  touched.company_pickup_city &&
                  errors.company_pickup_city
                }
              />

              <div className="row m-0">
                <div className="col-6 pl-0 pr-1">
                  <button
                    onClick={this.displayBack}
                    className="btn btn-primary btn-block mb-2"
                  >
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="col-6 pr-0 pl-1">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary btn-block"
                  >
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default AddressDetails;
