const faqData = [
  {
    heading: {
      en:
        "1) What does Merci Gold GmbH stand for and what is the relationship with the participating jewelers?",
      de:
        "1) Wofür steht die Merci Gold GmbH und wie ist das Verhältnis zu den teilnehmenden Juwelieren?",
    },
    content: {
      en:
        "- The Merci Gold GmbH is an internet and technology company. The stationary customers of the jewelers are basically to be distinguished from the online customers of Merci Gold GmbH. The participating jewelers perform services for the customers of Merci Gold GmbH.",
      de:
        "- Die Merci Gold GmbH ist ein Internet- und Technologieunternehmen. Die stationären Kunden der Juweliere sind grundsätzlich von den online Kunden der Merci Gold GmbH zu unterscheiden. Die teilnehmenden Juweliere führen Serviceleistung für die Kunden der Merci Gold GmbH durch.",
    },
  },
  {
    heading: {
      en: "2) Why is the cooperation with Merci Gold so profitable for the jewelers? ",
      de: `2) Warum ist die Zusammenarbeit mit Merci Gold so gewinnbringend für die Juweliere?`,
    },
    content: {
      en:
        "- Merci Gold GmbH places his own online customers in an uncomplicated and simple way to the participating stationary jewelers and pays the services for 12 months, provided that the services have been booked beforehand on the website www.juwelier-mercigold.de. It is advantageous for the participating jewelers that paying and possibly non-paying online customers can be referred to the jewelers in this way. The walk-in customers generated in this way are a welcome added value for every company.",
      de:
        "- Die Merci Gold GmbH vermittelt Ihre eigenen online Kunden unkompliziert und einfach an die teilnehmenden stationären Juweliere und vergütet die Serviceleistungen für 12 Monate, sofern die Serviceleistungen über die Webseite www.juwelier-mercigold.de zuvor gebucht wurden. Vorteilhaft für die teilnehmenden Juweliere ist, dass zahlende und ggf. nicht zahlende online Kunden so an die Juweliere vermittelt werden können. Die so generierte Laufkundschaft ist ein willkommener Mehrwert für jedes Unternehmen.",
    },
  },
  {
    heading: {
      en:
        "3) What is the sense & purpose of the ban on advice and what do I have to consider?",
      de:
        "3) Was ist Sinn & Zweck des Beratungsverbotes und was muss ich beachten?",
    },
    content: {
      en: `- The Merci Gold GmbH offers similar products and services as the participating jewelers. Therefore it is always forbidden to sell the own assortment or to subject the products of Merci Gold GmbH to a judgment in any way. The prohibition of advice must be observed in particular with the "pickup service". Please hand over the goods to the customer in an uncomplicated and simple manner`,
      de:
        "- Die Merci Gold GmbH bietet ähnliche Produkte und Dienstleistungen an wie die teilnehmenden Juweliere. Daher ist es stets verboten das eigene Sortiment zu verkaufen oder die Produkte der Merci Gold GmbH in irgendeiner Weise einem Urteil zu unterziehen. Das Beratungsverbot muss insbesondere beim „Abholservice“ beachtet werden. Bitte übergeben Sie die Ware dem Kunden unkompliziert und einfach.",
    },
  },
  {
    heading: {
      en:
        "4) What do I do if I have started an order/service by mistake? How do I cancel this again?",
      de:
        "4) Was mache ich, wenn ich irrtümlich einen Auftrag/Service gestartet habe? Wie storniere ich diesen wieder?",
    },
    content: {
      en:
        "- Please contact us. We look for the erroneously started service and cancel it for you immediately. Please tell us the product-Id and the type of service which was started incorrectly.",
      de:
        "- Bitte kontaktieren Sie uns. Wir suchen den irrtümlich gestarteten Service und stornieren diesen umgehend für Sie. Bitte teilen Sie uns die Produkt-Id sowie die Art des gestarteten Services mit.",
    },
  },
  {
    heading: {
      en:
        "5) How do I create a bill? Where can I find my monthly sales? When do I get my money?",
      de:
        "5) Wie erstelle ich eine Rechnung? Wo finde ich meine monatlichen Umsätze? Wann erhalte ich mein Geld?",
    },
    content: {
      en: `- Under the tab "Bills" all monthly bills can be created for you as PDF files. Either you create an invoice without a serial number for your records or you add your own invoice and customer number. Send us the generated invoice with invoice number or any other invoice by email. We process your request and transfer the amount to your bank account. Whenever a process has been completed, we bill it at the end of the month.`,
      de: `- Unter dem Reiter "Rechnungen" sind alle Rechnungen monatlich als PDF Datei für Sie erstellbar. Entweder Sie erstellen eine Rechnung ohne laufende Nummer für Ihre Unterlagen oder Sie fügen Ihre eigene Rechnungs- und Kundennummer hinzu. Lassen Sie uns die generierte Rechnung mit Rechnungsnummer oder jede beliebige Rechnung per E-Mail zukommen. Wir bearbeiten Ihr Anliegen und überweisen den Betrag auf Ihr Bankkonto. Immer wenn ein Prozess vollständig durchgeführt wurde, rechnen wir diesen zum Monatsende ab.`,
    },
  },
  {
    heading: {
      en: "6) What service does the participating jeweler have to provide?",
      de: "6) Welche Serviceleistung muss der teilnehmende Juwelier erbringen?",
    },
    content: {
      en: `1) Pickup service: The jeweler must offer a pickup service. The customer must be able to collect delivered goods at a participating jeweler.
      Receiving or shipping parcels is also part of this area of responsibility.<br/>
      2) Cleaning: The jeweler must be able to clean, polish or rhodium plating. The customer must be able to clean delivered goods at a participating jeweler. <br/>
      3) Repair: The jeweler must offer a repair service. The customer must be able to repair delivered goods at a participating jeweler.
      The pure repair costs will be settled by the Merci Gold GmbH. Lost stones or the like are excluded from this and are to be paid by the customer himself.<br/>
      4) Adaptation & Restoration: The jeweler must be able to adapt a piece of jewelery in its size and restore it to its original condition including engraving. The customer must be able to adjust delivered goods at a participating jeweler. <br/>
      <br/>
      <b>As a result, every participating jeweler must be able to perform standard goldsmith's work. <b>
      `,
      de: `1) Abholservice: Der Juwelier muss einen Abholservice anbieten. Zuvor von uns
      gelieferte Ware muss der Kunde bei einem teilnehmenden Juwelier abholen können.
      Auch der Empfangs- oder Versandservice von Paketen ist Teil dieses Aufgabengebietes.<br/>
      2) Reinigung: Der Juwelier muss ein Schmuckstück reinigen, polieren oder
      rhodinieren können. Zuvor von uns gelieferte Ware muss der Kunde bei einem
      teilnehmenden Juwelier reinigen lassen können. <br/>
      3) Reparatur: Der Juwelier muss ein Schmuckstück reparieren können. Zuvor von
      uns gelieferte Ware muss der Kunde bei einem teilnehmenden Juwelier
      reparieren lassen können. Die reinen Reparaturkosten werden durch die Merci Gold GmbH beglichen. Verlorene Steine oder dergleichen sind davon ausgenommen und sind vom Kunden selber zu entrichten.<br/>
      4) Anpassung & Widerherstellen: Der Juwelier muss ein Schmuckstück in seiner Größe anpassen können und in seinen ursprünglichen Zustand inkl. Gravur versetzen können. Zuvor von uns gelieferte Ware muss der Kunde bei einem teilnehmenden Juwelier anpassen lassen können<br/>
<br/>
      <b>Im Ergebnis muss jeder teilnehmende Juwelier  Standardarbeiten eines Goldschmiedes durchführen können.<b>
      `,
    },
  },
  {
    heading: {
      en:
        "7)	What is the process and how do I book a service?",
      de: "7) Wie ist der Ablauf bzw. wie buche ich einen Service?",
    },
    content: {
      en: `<b>Pickup Service:</b> <br/>
      1) Log in to our website www.juwelier-mercigold.de.<br/> 
      2) Open the package that you received from us. <br/>
      3) Scan the barcode that you can find on the order slip or enter it manually using the order number and date.<br/> 
      4) Then click on ,,Jeweler Pickup Service\" and follow the further instructions. <br/>
      5) Add the name and Id number of the person collecting the parcel to the order slip and have it signed. Keep the order slip in a safe place, it serves as proof of the collection.<br/> 
      6) Give the pre-packed bag to the customer. <br/>
      <br/>
      <b>Cleaning / Repair / Adjustment Service:</b> <br/>
      1) Log in to our website www.juwelier-mercigold.de.<br/> 
      2) Have the customer show you the service card. <br/>
      3) Scan the barcode that you can find on the service card or enter it manually using the order number and date.<br/> 
      4) Then click on the respective service to start it.<br/>
      5) You now have the option of booking the preset remuneration directly without our consent being required. Follow the further instructions. <br/>
      <br/>
      5b) or if the remuneration does not seem appropriate, select „Send Price“ proposal. We will try to answer you within 30 minutes. If we cannot accept your price proposal, we will commission a DHL courier to collect the package from you. Please pack the product in an envelope and hand it over to the courier without a label. We will give the delivery person the pickup address that you have deposited in your account. Then get a receipt for the tracking number and keep it. <br/>
      <br/>
      It does not matter whether you choose subpoint 5 or 5b, the customer can leave the goods with you and will be informed about the further procedure by e-mail. In the procedure under point 5 and if necessary 5b, the customer can pick up the goods from you again. If we reject your price proposal under 5b, the goods will be finished by our company and shipped to the customer's home address. In this case, please pack the goods ready for shipment. The postal service provider will come to pick it up. You do not need your own shipping label, the shipping service provider will bring one. Then follow the instructions and buttons.<br/>
      `,
      de: `<b>Abhol-Service: </b><br/>
      1) Loggen Sie sich auf unserer Webseite www.juwelier-mercigold.de ein.<br/> 
      2) Öffnen Sie das Paket, das Sie von uns erhalten haben. <br/>
      3) Scannen Sie den Barcode ab, den Sie auf dem Abholschein finden oder führen Sie Eingabe manuell anhand von Bestellnummer und Datum durch.<br/> 
      4) Klicken Sie anschließend auf "Juwelier Abholservice\" und folgen Sie den weiteren Anweisungen.<br/>
      5) Fügen Sie den Namen und die Ausweisnummer des Abholers in den Abholschein ein und lassen Sie es sich unterschreiben. Bewahren Sie den Abholschein gut auf, dieser dient als Nachweis für die Abholung.<br/>
      6) Übergeben Sie dem Kunden die vorgepackte Tüte.<br/>
      <br/>
      <b>Reparatur-/Reinigung-/Anpassung-Service:</b> <br/>
      1) Loggen Sie sich auf unserer Webseite www.juwelier-mercigold.de ein.<br/> 
      2) Lassen Sie sich vom Kunden die Servicekarte vorzeigen. <br/>
      3) Scannen Sie den Barcode ab, den Sie auf der Servicekarte finden oder führen Sie Eingabe manuell anhand von Bestellnummer und Datum durch.<br/> 
      4) Klicken Sie anschließend auf den jeweiligen Service, den Sie starten möchten. <br/>
      5) Nun haben Sie die Möglichkeit die voreingestellte Vergütung direkt zu buchen, ohne das unsere Zustimmung nötig ist. Folgen Sie den weiteren Anweisungen.<br/>
      <br/>
      5b) oder sofern die Vergütung nicht angemessen erscheint, wählen Sie Preisvorschlag senden. Wir versuchen Ihnen innerhalb von 30 Minuten zu antworten. Sollten wir Ihren Preisvorschlag nicht annehmen können, beauftragen wir einen Versanddienstleister damit, das Paket bei Ihnen abzuholen. Bitte verpacken Sie das Produkt in einen Umschlag und übergeben Sie es dem Paketboten ohne Label. Wir übermitteln dem Paketboten die Abholadresse, die Sie in Ihrem Konto unter Einstellungen hinterlegt haben. Lassen Sie sich anschließend eine Quittung über die Sendungsnummer geben und bewahren Sie diesen auf.<br/>
      <br/>
      Es ist unerheblich ob Sie Unterpunkt 5 oder 5b wählen, der Kunde kann die Ware bei Ihnen lassen und wird über das weitere Verfahren per E-Mail informiert. 
      Beim Verfahren unter Punkt 5 und ggf. 5b kann der Kunde die Ware wieder bei Ihnen abholen. 
      Sollten wir unter 5b Ihren Preisvorschlag ablehnen, wird die Ware durch unser Unternehmen fertiggestellt und an die Hausadresse des Kunden versandt.
      In diesem Fall verpacken Sie bitte die Ware versandbereit. Der Versanddienstleister wird es abholen kommen. Sie benötigen kein eigenes Versandetikett, 
      der Versanddienstleister bringt eins mit. Folgen Sie anschließend den Anweisungen und Schaltflächen.<br/>
      `,
    },
  },
  {
    heading: {
      en:
        "8) How do I change my data such as address, bank details or tax number?",
      de:
        "8) Wie ändere ich meine Daten wie bspw. Adresse, Bankdaten oder Steuernummer?",
    },
    content: {
      en: `You can change your details under the tab "Settings". If you want to change your address, you can do this in one step by clicking on "Save Changes". If you want to change your bank or tax data, click on "Save Changes" first. To complete the curtain click on "Confirm" bank or tax data in the confirmation email. You will then find this in your email inbox.`,
      de: `Ihre Angaben können Sie unter dem Punkt "Einstellungen" ändern. Sofern Sie ihre Adresse ändern möchten, ist dies in einem Schritt möglich, indem Sie auf "Änderung Speichern" klicken. Sofern Sie ihre Bank- oder Steuerdaten ändern möchten, klicken Sie zunächst auf "Änderung Speichern". Um den Vorhang abzuschließen, klicken Sie in der Bestätigungsmail auf Bank- oder Steuerdaten bestätigen. Diese finden Sie im Anschluss in Ihrem E-Mail Postfach.`,
    },
  },
];
const FAQ = ({ currentLanguage }) => {
  return (
    <>
      <div className="accordion mx-1" id="accordionExample">

        <h5 className="font-weight-bold ">FAQ</h5>
        <hr />

        {faqData.map(({ content, heading }, index) => (
          <div className="card">
            <div className="card-header cursor  h-100 text-justify collapsed linkk-style-hover" 
                  type="text"
                  data-toggle="collapse"
                  data-target={`#collapse${index}`}
                  aria-expanded={(index === 0).toString()}
                  aria-controls={`collapse${index}`} 
                  id={`heading${index}`}>
                  <h6 className="mb-0">
                  {heading[currentLanguage]}
                  </h6>
            </div>

            <div
              id={`collapse${index}`}
              className={index === 0 ? "collapse show" : "collapse"}
              aria-labelledby={`heading${index}`}
              data-parent="#accordionExample"
            >
              <div
                className="card-body m-3 text-justify"
                dangerouslySetInnerHTML={{ __html: content[currentLanguage] }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FAQ;
