import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { Logo } from "../images";

// const filterIcon = require("../images/Logo_Juwelier-Mercigold.svg");

export default function Header(props) {
  const { isAuthenticated, currentLanguage, handleSelect } = props;

  return (
    <>
      <p className="container-lg d-flex justify-content-center m-auto pt-1">
        <NavLink
          className="link-style text-center mb-1"
          to={isAuthenticated ? "/home" : "/"}
        >
          <img className="logo-image" src={Logo} alt="header" />
        </NavLink>
      </p>

      <div className="dropdown cursor mr-2 pt-1">
        <span
          // type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={faGlobe} className="header-btn-size" />
        </span>
        <div
          className="dropdown-menu p-0 forms-position cursor"
          aria-labelledby="dropdownMenu2"
        >
          <div className="d-flex flex-column dropdown-control">
            <div className="hover-on" onClick={(e) => handleSelect(e, "en")}>
              <input
                readOnly
                type="radio"
                name="options"
                id="en"
                className="hide-radio"
                checked={currentLanguage === "en" ? true : false}
                value={currentLanguage}
              />
              <label htmlFor="en" className="label-text-pos cursor">
                En
              </label>
            </div>
            <div className="hover-on" onClick={(e) => handleSelect(e, "de")}>
              <input
                readOnly
                type="radio"
                name="options"
                id="de"
                className="hide-radio"
                checked={currentLanguage === "de" ? true : false}
                value={currentLanguage}
                onChange={(e) => handleSelect(e)}
              />
              <label htmlFor="de" className="label-text-pos cursor">
                De
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
