import { Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FloatingInputField } from "../../../components";
import { changePassword } from "../../../services/authService";
import language from "../../../utils/language.json";

const ChangePassword = (props) => {
  const { currentLanguage } = props;
  const [loading, setLoading] = useState(false);
  const loginSchema = Yup.object().shape({
    newPassword: Yup.string().required(
      language[currentLanguage].passwordRequired
    ),
    oldPassword: Yup.string().required(
      language[currentLanguage].passwordRequired
    ),
  });

  const handleSubmission = async (values, { setSubmitting }) => {
    const { oldPassword, newPassword } = values;
    try {
      const data = { oldPassword, newPassword };
      const {
        data: { message, message_de },
      } = await changePassword(data);
      setLoading(false);
      toast.success(currentLanguage === "en" ? message : message_de);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setLoading(false);
        const { message, message_de } = ex.response.data;
        toast.error(currentLanguage === "de" ? message_de : message);
      }
      if (ex.response && ex.response.status === 404) {
        setLoading(false);
        const { message, message_de } = ex.response.data;
        toast.error(currentLanguage === "de" ? message_de : message);
      }
    }
  };
  return (
    <div className="app-width m-auto p-0 py-3 d-flex flex-column justify-content-center ">
      <h5>{language[currentLanguage].changeYourPassword}</h5>
      <br />
      <Formik
        initialValues={{ oldPassword: "", newPassword: "" }}
        validationSchema={loginSchema}
        onSubmit={handleSubmission}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FloatingInputField
              error={
                errors.oldPassword && touched.oldPassword && errors.oldPassword
              }
              type="password"
              name="oldPassword"
              value={values.password}
              currentLanguage={currentLanguage}
              placeholderValue={language[currentLanguage].oldPassword}
              onChange={handleChange}
            />
            <FloatingInputField
              error={
                errors.newPassword && touched.newPassword && errors.newPassword
              }
              type="password"
              name="newPassword"
              value={values.password}
              currentLanguage={currentLanguage}
              placeholderValue={language[currentLanguage].newPassword}
              onChange={handleChange}
            />
            <>
              {loading && (
                <div className="spinner-control">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary btn-sm btn-block btn-final"
              >
                {!loading ? (
                  <strong>{language[currentLanguage].confirm}</strong>
                ) : null}
              </button>
            </>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
