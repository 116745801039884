import {
  ServiceModal,
  JReturnService,
  ReturnBooked,
  ReturnPickupComplete,
} from "../../components";
import { useState } from "react";

const ReturnServices = ({ loading, modal, closeModal, currentLanguage }) => {
  const [displayModal, setDisplayModal] = useState(1);

  return (
    <ServiceModal
      loading={loading}
      modal={modal}
      closeModal={closeModal}
      body={cardBody({ displayModal, setDisplayModal, currentLanguage })}
      currentLanguage={currentLanguage}
    />
  );
};
const cardBody = ({ displayModal, setDisplayModal, currentLanguage }) => {
  switch (displayModal) {
    case 1:
      return (
        <JReturnService
          setDisplayModal={setDisplayModal}
          currentLanguage={currentLanguage}
        />
      );
    case 2:
      return (
        <ReturnBooked
          setDisplayModal={setDisplayModal}
          currentLanguage={currentLanguage}
        />
      );
    case 3:
      return (
        <ReturnPickupComplete
          setDisplayModal={setDisplayModal}
          currentLanguage={currentLanguage}
        />
      );
  }
};

export default ReturnServices;
