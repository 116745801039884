import { useState } from "react";
import { ShadowIcon } from "../../images";
import language from "../../utils/language.json";

const PickupBooked = ({
  setDisplayModal,
  currentLanguage,
  pickupOrderUpdate,
  time,
  loading,
}) => {
  const [confirmBtn, setConfirmBtn] = useState(false);
  const [returnConfirmBtn, setReturnConfirmBtn] = useState(false);
  const [timeConfirmBtn, setTimeConfirmBtn] = useState(false);

  const handleConfirm = (value) => {
    pickupOrderUpdate(value);
  };
  return (
    <div>
      <div>{language[currentLanguage].pickupBookedTitle}</div>
      <hr />
      <div className="p-0 py-4">
        <div className="d-flex flex-column">
          <div className="pb-4">
            <div
              // onClick={() => handleButton()}
              className="mb-3 h-100  w-100 d-flex justify-content-between"
            >
              <p className="ul-text my-auto">
                {language[currentLanguage].custIsAllowed} 
              </p>
              <span className="my-auto">
                <i className="fa fa-check" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div className="booked-body p-3 mb-3 px-4">
            <p className="card-mid-title">
              {language[currentLanguage].jwlrPickupBkd}
            </p>
            <p className="mb-6 card-mid-body">
              {language[currentLanguage].custWillInformed}
            </p>
            <img
              src={ShadowIcon}
              className="check-circle-style"
              alt="approved"
            />
            <span className="card-end-body">
              {language[currentLanguage].thankYou}
            </span>
          </div>
          <div>
            <button
              className="btn btn-outline-secondary mb-3 w-100 selected-btn"
              onClick={() => {
                setConfirmBtn(true);
                setReturnConfirmBtn(false);
              }}
            >
              {language[currentLanguage].custPickedUpSelect}
            </button>
            {confirmBtn && (
              <button
                onClick={() => handleConfirm(2)}
                className="btn btn-primary w-100 m-auto my-6"
              >
                {loading
                  ? language[currentLanguage].loading
                  : language[currentLanguage].confirm}
              </button>
            )}
            <button
              className={
                confirmBtn
                  ? "btn btn-outline-secondary mb-3 w-100 d-flex flex-column mt-3"
                  : "btn btn-outline-secondary mb-3 w-100 d-flex flex-column"
              }
              onClick={() => {
                setReturnConfirmBtn(true);
                setConfirmBtn(false);
              }}
            >
              <span className="m-auto">
                {language[currentLanguage].returnLabel}
              </span>
              <span className="m-auto">
                {language[currentLanguage].custDontPickedUp}
              </span>
            </button>
            {returnConfirmBtn && (
              <button
                onClick={() => handleConfirm(3)}
                className="btn btn-primary w-100 m-auto my-6"
              >
                {loading
                  ? language[currentLanguage].loading
                  : language[currentLanguage].confirm}
              </button>
            )}

            <button
              disabled={time < 16}
              className={
                returnConfirmBtn
                  ? "btn btn-outline-secondary mb-3 w-100 d-flex flex-column mt-3"
                  : "btn btn-outline-secondary mb-3 w-100 d-flex flex-column mt-3"
              }
              onClick={() => {
                setTimeConfirmBtn(true);
                setReturnConfirmBtn(false);
                setConfirmBtn(false);
              }}
            >
              <span className="m-auto">
                {language[currentLanguage].returnLabel}
              </span>
              <span className="m-auto">
                {language[currentLanguage].custNotHere}
              </span>

              {time < 16 && (
                <span className="m-auto">
                  {`${language[currentLanguage].willBeAvail} ${16 - time} ${
                    language[currentLanguage].days
                  }`}
                </span>
              )}
            </button>
            {timeConfirmBtn && (
              <button
                onClick={() => handleConfirm(5)}
                className="btn btn-primary w-100 m-auto my-6"
              >
                {loading
                  ? language[currentLanguage].loading
                  : language[currentLanguage].confirm}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickupBooked;
