import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import language from "../../utils/language.json";

const Selection = ({
  setDisplayModal,
  currentLanguage,
  setServiceGender,
  order_id,
  product_gender,
  serviceCode,
  logout,
  history,
}) => {
  const handleButton = async (gender) => {
    setServiceGender(gender);
  };

  return (
    <div>
      <Modal.Header closeButton>
        <div className="w-100">
          <div className="col-6"></div>
          <div className="col-6 m-auto text-center mob-col-width">
            {language[currentLanguage].coupleRing}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 py-4">
        <div className="d-flex flex-column">
          <div className="couple-ring-btn">
            <button
              onClick={() => handleButton("male")}
              className="btn btn-outline-secondary selected-btn mb-3 h-100  w-100 mx-auto"
            >
              {language[currentLanguage].menRing}
            </button>
            <button
              onClick={() => handleButton("female")}
              className="btn btn-outline-secondary mb-3 h-100 selected-btn  w-100 mx-auto"
            >
              {language[currentLanguage].womenRing}
            </button>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default withRouter(Selection);
