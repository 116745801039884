import language from "../../utils/language.json";
import { ShadowIcon } from "../../images";

const PickupComplete = ({ currentLanguage }) => {
  return (
    <div>
      <div>{language[currentLanguage].pickupCompleteTitle}</div>
      <div className="p-0 py-4">
        <div className="d-flex flex-column">
          <div className="pb-4">
            <div className="mb-3 h-100 w-100 d-flex justify-content-between">
              <p className="ul-text my-auto">
                {language[currentLanguage].custIsAllowed}
              </p>
              <span className="my-auto">
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
            </div>
            <div className="mb-3 h-100 w-100 d-flex justify-content-between">
              <p className="ul-text my-auto">
                {language[currentLanguage].custPickedUp}
              </p>
              <span className="my-auto">
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div className="booked-body p-3 mb-3">
            <p className="card-mid-title">
              {language[currentLanguage].processComplete}
            </p>
            <p className="mb-6 card-mid-body">
              {language[currentLanguage].willfindUnder}
            </p>

            <img
              src={ShadowIcon}
              alt="approved"
              className="check-circle-style"
            />
            <span className="card-end-body">
              {language[currentLanguage].thankYou}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickupComplete;
