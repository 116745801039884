import { useState } from "react";
import { ShadowIcon } from "../../images";
import language from "../../utils/language.json";

const JReturnService = ({
  pickupOrderUpdate,
  currentLanguage,
  changeText,
  loading,
}) => {
  const [confirmBtn, setConfirmBtn] = useState(false);

  const handleConfirm = () => {
    pickupOrderUpdate(6);
  };

  return (
    <div>
      <div>{language[currentLanguage].jreturnServiceTitle}</div>
      <hr />
      <div className="text-center p-0 py-4">
        <div className="d-flex flex-column px-3">
          <div className="pb-4">
            <div className="mb-3 h-100 w-100 d-flex justify-content-between">
              <p className="ul-text my-auto">
                {" "}
                {language[currentLanguage].custIsAllowed}
              </p>
              <span>
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
            </div>
            <div className="mb-3 h-100 w-100 d-flex justify-content-between">
              <p className="ul-text my-auto">
                {" "}
                {language[currentLanguage].returnLabel} {language[currentLanguage].custNotHere}
              </p>
              <span>
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div className="p-0 pb-4">
            <div className="d-flex flex-column">
              <div className="booked-body p-3 mb-3">
                <p className="card-mid-title">
                  {language[currentLanguage].jwlrPickupBkd}
                </p>
                <p className="mb-6 card-mid-body">
                  {language[currentLanguage].custReturnInfo}
                </p>

                <img
                  src={ShadowIcon}
                  alt="approved"
                  className="check-circle-style"
                />
                <span className="card-end-body">
                  {language[currentLanguage].thankYou}
                </span>
              </div>
            </div>
          </div>
          <div className="py-5">
            <button
              className="btn btn-outline-secondary mb-3 w-100 selected-btn"
              onClick={() => setConfirmBtn(true)}
            >
              {language[currentLanguage].dhlPicked}
            </button>
            {confirmBtn && (
              <button
                onClick={() => handleConfirm()}
                className="btn btn-primary w-100 m-auto my-6"
              >
                {language[currentLanguage].confirm}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JReturnService;
