import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import QrReader from "react-qr-reader";
import language from "../utils/language.json";

const QRModal = (props) => {
  const {
    modal,
    closeModal,
    handleError,
    handleScan,
    result,
    currentLanguage,
    setCamLoading,
  } = props;
  const [loadCam, setLoadCam] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadCam(true);
    }, 3000);
    return () => setLoadCam(false);
  }, []);

  return (
    <div>
      <Modal
        show={modal}
        // onHide={closeModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="align-items-center">
          <div>
            {result
              ? language[currentLanguage].scanning
              : language[currentLanguage].scanQr}
          </div>
          <div>
            {!loadCam ? (
              <div className="spinner-control">
                <div className="spinner-border-scan" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <span>
                <i
                  class="fa fa-times fa-2x cursor"
                  aria-hidden="true"
                  onClick={() => closeModal()}
                ></i>
              </span>
            )}
          </div>
        </Modal.Header>

        <Modal.Body className="p-0">
          <QrReader delay={300} onError={handleError} onScan={handleScan} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default QRModal;
