import React, { Component } from "react";
import language from "../../../utils/language.json";

class CompanyDetails extends Component {
  state = {
    pickup: 0,
    receipt: 0,
    shipping: 0,
    repair_ring: 0,
    repair_necklace: 0,
    repair_earrings: 0,
    repair_bracelet: 0,
    adjustment_ring: 0,
    adjustment_necklace: 0,
    adjustment_earrings: 0,
    adjustment_bracelet: 0,
    cleaning_cleaning: 0,
    cleaning_rhodium: 0,
    engraving: false,
    materials: [],
    products: [],
    adjustmentProducts: [],
    works: [],
    cleaningObj: [],
    allMaterials: false,
    allProducts: false,
    allAdjustmentProducts: false,
    allCleaningServices: false,
    allWorks: false,
  };

  componentDidUpdate(prevProps) {
    const { currentLanguage } = this.props;
    if (prevProps.currentLanguage !== this.props.currentLanguage) {
      this.getMaterial(currentLanguage);
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { currentLanguage, completeData } = this.props;
    this.setState({ completeData });
    this.getMaterial(currentLanguage);
  };

  getMaterial = (index) => {
    const { currentLanguage, completeData } = this.props;
    const {
      pickup = 0,
      receipt = 0,
      shipping = 0,
      repair_ring = 0,
      repair_necklace = 0,
      repair_earrings = 0,
      repair_bracelet = 0,
      adjustment_ring = 0,
      adjustment_necklace = 0,
      adjustment_earrings = 0,
      adjustment_bracelet = 0,
      cleaning_rhodium = 0,
      cleaning_cleaning = 0,
      engraving = false,
    } = completeData;
    this.setState({
      pickup,
      receipt,
      shipping,
      repair_ring,
      repair_necklace,
      repair_earrings,
      repair_bracelet,
      adjustment_ring,
      adjustment_necklace,
      adjustment_earrings,
      adjustment_bracelet,
      cleaning_cleaning,
      cleaning_rhodium,
      engraving,
    });
    let materials = [
      {
        id: 1,
        label: language[currentLanguage].pickUp,
        name: "Pick Up",
        value: "pickup",
        isChecked: pickup === 1 ? true : false,
      },
      {
        id: 2,
        label: language[currentLanguage].receipt,
        name: "Receipt",
        value: "receipt",
        isChecked: receipt === 1 ? true : false,
      },
      {
        id: 3,
        label: language[currentLanguage].shipping,
        name: "Shipping",
        value: "shipping",
        isChecked: shipping === 1 ? true : false,
      },
    ];
    let products = [
      {
        id: 1,
        label: language[currentLanguage].ring,
        name: "Ring",
        value: "repair_ring",
        isChecked: repair_ring === 1 ? true : false,
      },
      {
        id: 2,
        label: language[currentLanguage].necklace,
        name: "Necklace",
        value: "repair_necklace",
        isChecked: repair_necklace === 1 ? true : false,
      },
      {
        id: 3,
        label: language[currentLanguage].earrings,
        name: "Earrings",
        value: "repair_earrings",
        isChecked: repair_earrings === 1 ? true : false,
      },
      {
        id: 4,
        label: language[currentLanguage].bracelet,
        name: "Bracelet",
        value: "repair_bracelet",
        isChecked: repair_bracelet === 1 ? true : false,
      },
    ];
    let adjustmentProducts = [
      {
        id: 1,
        label: language[currentLanguage].ring,
        name: "Ring",
        value: "adjustment_ring",
        isChecked: adjustment_ring === 1 ? true : false,
      },
      {
        id: 2,
        label: language[currentLanguage].necklace,
        name: "Necklace",
        value: "adjustment_necklace",
        isChecked: adjustment_necklace === 1 ? true : false,
      },
      {
        id: 3,
        label: language[currentLanguage].earrings,
        name: "Earrings",
        value: "adjustment_earrings",
        isChecked: adjustment_earrings === 1 ? true : false,
      },
      {
        id: 4,
        label: language[currentLanguage].bracelet,
        name: "Bracelet",
        value: "adjustment_bracelet",
        isChecked: adjustment_bracelet === 1 ? true : false,
      },
    ];
    let cleaningObj = [
      {
        id: 1,
        label: language[currentLanguage].cleaning,
        name: "Cleaning",
        value: "cleaning_cleaning",
        isChecked: cleaning_cleaning === 1 ? true : false,
      },
      {
        id: 2,
        label: language[currentLanguage].rhodium,
        name: "Rhodium Plating",
        value: "cleaning_rhodium",
        isChecked: cleaning_rhodium === 1 ? true : false,
      },
    ];

    const materialSelection = materials.filter((m) => m.isChecked === true);
    if (materialSelection.length === materials.length) {
      this.setState({ allMaterials: true });
    } else {
      this.setState({ allMaterials: false });
    }
    const productsSelection = products.filter((m) => m.isChecked === true);
    if (productsSelection.length === products.length) {
      this.setState({ allProducts: true });
    } else {
      this.setState({ allProducts: false });
    }
    const adjustmentProductsSelection = adjustmentProducts.filter(
      (m) => m.isChecked === true
    );
    const cleaningServicesSelection = cleaningObj.filter(
      (m) => m.isChecked === true
    );
    if (adjustmentProductsSelection.length === adjustmentProducts.length) {
      this.setState({ allAdjustmentProducts: true });
    } else {
      this.setState({ allAdjustmentProducts: false });
    }
    if (cleaningServicesSelection.length === cleaningObj.length) {
      this.setState({ allCleaningServices: true });
    } else {
      this.setState({ allCleaningServices: false });
    }
    const cleaningSelection = cleaningObj.filter((m) => m.isChecked === true);
    if (cleaningSelection.length === cleaningObj.length) {
      this.setState({ allProducts: true });
    } else {
      this.setState({ allProducts: false });
    }

    this.setState({
      materials,
      products,
      adjustmentProducts,
      cleaningObj,
    });
  };
  displayBack = (e) => {
    this.props.handleBack();
  };

  displayNext = (e) => {
    const { currentLanguage } = this.props;
    this.getMaterial(currentLanguage);
    e.preventDefault();
    const {
      pickup,
      receipt,
      shipping,
      repair_ring,
      repair_necklace,
      repair_earrings,
      repair_bracelet,
      adjustment_ring,
      adjustment_necklace,
      adjustment_earrings,
      adjustment_bracelet,
      cleaning_cleaning,
      cleaning_rhodium,
      engraving,
    } = this.state;
    const companyData = {
      pickup,
      receipt,
      shipping,
      repair_ring,
      repair_necklace,
      repair_earrings,
      repair_bracelet,
      adjustment_ring,
      adjustment_necklace,
      adjustment_earrings,
      adjustment_bracelet,
      cleaning_cleaning,
      cleaning_rhodium,
      engraving,
    };
    this.props.collectValues(companyData);
    this.props.handleNext();
  };

  handleMaterialCheck = ({ target: { value, checked } }) => {
    this.setState({ allMaterials: checked });
    let materials = this.state.materials;
    materials.forEach((material) => (material.isChecked = checked));
    if (checked) {
      this.setState({
        receipt: 1,
        shipping: 1,
        material_rosegold: 1,
        material_titanium: 1,
        material_whitegold: 1,
        pickup: 1,
        material_zirconium: 1,
      });
    } else {
      this.setState({
        receipt: 0,
        shipping: 0,
        material_rosegold: 0,
        material_titanium: 0,
        material_whitegold: 0,
        pickup: 0,
        material_zirconium: 0,
      });
    }
    this.setState({ materials });
  };
  handleCheckElement = ({ target: { value, checked } }) => {
    let isAllChecked = true;
    if (!checked) {
      this.setState({ allMaterials: false });
    }

    this.setState({ [value]: checked });

    let materials = this.state.materials;
    materials.forEach((material) => {
      if (material.value === value) {
        material.isChecked = checked;
        this.setState({ ...this.state, [value]: 1 });
      }
    });
    this.setState({ materials }, () => {
      this.state.materials.map(({ isChecked }) => {
        !isChecked && (isAllChecked = false);
      });
      if (isAllChecked) {
        this.setState({ allMaterials: true });
      } else {
        this.setState({ allMaterials: false });
      }
    });
  };
  handleProductCheck = ({ target: { value, checked } }) => {
    this.setState({ allProducts: checked });

    let products = this.state.products;
    products.forEach((product) => (product.isChecked = checked));
    if (checked) {
      this.setState({
        repair_bracelet: 1,
        repair_earrings: 1,
        repair_necklace: 1,
        repair_ring: 1,
      });
    } else {
      this.setState({
        repair_bracelet: 0,
        repair_earrings: 0,
        repair_necklace: 0,
        repair_ring: 0,
      });
    }
    this.setState({ products });
  };
  handleAdjustmentProductCheck = ({ target: { value, checked } }) => {
    this.setState({ allAdjustmentProducts: checked });
    let adjustmentProducts = this.state.adjustmentProducts;
    adjustmentProducts.forEach(
      (adjustedItem) => (adjustedItem.isChecked = checked)
    );
    if (checked) {
      this.setState({
        adjustment_bracelet: 1,
        adjustment_earrings: 1,
        adjustment_necklace: 1,
        adjustment_ring: 1,
      });
    } else {
      this.setState({
        adjustment_bracelet: 0,
        adjustment_earrings: 0,
        adjustment_necklace: 0,
        adjustment_ring: 0,
      });
    }
    this.setState({ adjustmentProducts });
  };
  handleCleaningServiceCheck = ({ target: { value, checked } }) => {
    this.setState({ allCleaningServices: checked });
    let cleaningObj = this.state.cleaningObj;
    cleaningObj.forEach((cleaningItem) => (cleaningItem.isChecked = checked));
    if (checked) {
      this.setState({
        cleaning_cleaning: 1,
        cleaning_rhodium: 1,
      });
    } else {
      this.setState({
        cleaning_cleaning: 1,
        cleaning_rhodium: 1,
      });
    }
    this.setState({ cleaningObj });
  };
  handleCheckProduct = ({ target: { value, checked } }) => {
    let isAllChecked = true;
    if (!checked) {
      this.setState({ allProducts: false });
    }

    this.setState({ [value]: checked });

    let products = this.state.products;
    products.forEach((product) => {
      if (product.value === value) {
        product.isChecked = checked;
        this.setState({ ...this.state, [value]: 1 });
      }
    });
    this.setState({ products }, () => {
      this.state.products.map(({ isChecked }) => {
        !isChecked && (isAllChecked = false);
      });
      if (isAllChecked) {
        this.setState({ allProducts: true });
      } else {
        this.setState({ allProducts: false });
      }
    });
  };
  handleCheckAdjustmentProduct = ({ target: { value, checked } }) => {
    let isAllChecked = true;
    if (!checked) {
      this.setState({ allAdjustmentProducts: false });
    }

    this.setState({ [value]: checked });

    let adjustmentProducts = this.state.adjustmentProducts;
    adjustmentProducts.forEach((adjustItem) => {
      if (adjustItem.value === value) {
        adjustItem.isChecked = checked;
        this.setState({ ...this.state, [value]: 1 });
      }
    });
    this.setState({ adjustmentProducts }, () => {
      this.state.adjustmentProducts.map(({ isChecked }) => {
        !isChecked && (isAllChecked = false);
      });
      if (isAllChecked) {
        this.setState({ allAdjustmentProducts: true });
      } else {
        this.setState({ allAdjustmentProducts: false });
      }
    });
  };
  handleCleaningServices = ({ target: { value, checked } }) => {
    let isAllChecked = true;
    if (!checked) {
      this.setState({ allCleaningServices: false });
    }

    this.setState({ [value]: checked });

    let cleaningObj = this.state.cleaningObj;
    cleaningObj.forEach((adjustItem) => {
      if (adjustItem.value === value) {
        adjustItem.isChecked = checked;
        this.setState({ ...this.state, [value]: 1 });
      }
    });
    this.setState({ cleaningObj }, () => {
      this.state.cleaningObj.map(({ isChecked }) => {
        !isChecked && (isAllChecked = false);
      });
      if (isAllChecked) {
        this.setState({ allCleaningServices: true });
      } else {
        this.setState({ allCleaningServices: false });
      }
    });
  };

  handleCheckEngraving = ({ target: { value } }) => {
    this.setState({ engraving: !this.state.engraving });
  };
  render() {
    const { currentLanguage } = this.props;
    const {
      allMaterials,
      allProducts,
      engraving,
      allAdjustmentProducts,
      allCleaningServices,
    } = this.state;

    return (
      <div className="app-width m-auto p-3 flex-column d-flex justify-content-center ">
        <div>
          <h6 className="mb-4">{language[currentLanguage].companyDetails}</h6>

          <div className="mb-3">
            <h5>{language[currentLanguage].pickupService}</h5>
            <div className="details-li-decoration mb-2 d-flex align-items-center">
              <input
                type="checkbox"
                onChange={this.handleMaterialCheck}
                name="mainSelection"
                value="selectall"
                className="checkbox-size"
                checked={allMaterials}
              />
              <label className="ml-2">
                {language[currentLanguage].selectAll}
              </label>
            </div>
            {this.state.materials.map((material) => (
              <li
                className="details-li-decoration mb-1 d-flex align-items-center"
                key={material.id}
              >
                <input
                  type="checkbox"
                  className="checkbox-size"
                  onChange={this.handleCheckElement}
                  checked={material.isChecked}
                  value={material.value}
                />

                <label className="ml-2">{material.label}</label>
              </li>
            ))}
          </div>
          <div className="mb-3">
            <h5>{language[currentLanguage].repairService} </h5>
            <div className="details-li-decoration mb-2  d-flex align-items-center">
              <input
                type="checkbox"
                onChange={this.handleProductCheck}
                value="checkedAll"
                checked={allProducts}
                className="mr-2  checkbox-size "
              />
              <label>{language[currentLanguage].selectAll}</label>
            </div>
            {this.state.products.map((product) => (
              <li
                className="details-li-decoration  d-flex align-items-center"
                key={product.id}
              >
                <input
                  type="checkbox"
                  className="checkbox-size"
                  onChange={this.handleCheckProduct}
                  checked={product.isChecked}
                  value={product.value}
                />
                <label className="ml-2 text-align-checkbox">
                  {product.label}
                </label>
              </li>
            ))}
          </div>
          <div className="mb-3">
            <h5>{language[currentLanguage].adjustmentService}</h5>
            <div className="details-li-decoration mb-2  d-flex align-items-center">
              <input
                type="checkbox"
                onChange={this.handleAdjustmentProductCheck}
                value="checkedAll"
                checked={allAdjustmentProducts}
                className="mr-2  checkbox-size "
              />
              <label>{language[currentLanguage].selectAll}</label>
            </div>
            {this.state.adjustmentProducts.map((product) => (
              <li
                className="details-li-decoration d-flex align-items-center"
                key={product.id}
              >
                <input
                  type="checkbox"
                  className="checkbox-size"
                  onChange={this.handleCheckAdjustmentProduct}
                  checked={product.isChecked}
                  value={product.value}
                />
                <label className="ml-2 text-align-checkbox">
                  {product.label}
                </label>
              </li>
            ))}
          </div>
          <div className="mb-3">
            <h5>{language[currentLanguage].cleaningService}</h5>
            <div className="details-li-decoration mb-2  d-flex align-items-center">
              <input
                type="checkbox"
                onChange={this.handleCleaningServiceCheck}
                value="checkedAll"
                checked={allCleaningServices}
                className="mr-2  checkbox-size "
              />
              <label>{language[currentLanguage].selectAll}</label>
            </div>

            {this.state.cleaningObj.map((product) => (
              <li
                className="details-li-decoration d-flex align-items-center"
                key={product.id}
              >
                <input
                  type="checkbox"
                  className="checkbox-size"
                  onChange={this.handleCleaningServices}
                  checked={product.isChecked}
                  value={product.value}
                />
                <label className="ml-2 text-align-checkbox">
                  {product.label}
                </label>
              </li>
            ))}
          </div>
          <div className="mb-3">
            <h5>{language[currentLanguage].engravingServices}</h5>
            <li className="details-li-decoration d-flex align-items-center">
              <input
                type="checkbox"
                className="checkbox-size"
                onChange={this.handleCheckEngraving}
                value={engraving}
                checked={engraving}
                // value={value}
              />
              <label className="ml-2 text-align-checkbox">
                {language[currentLanguage].yes}
              </label>
            </li>
          </div>

          <div className="row m-0">
            <div className="col-6 pl-0 pr-1">
              <button
                onClick={this.displayBack}
                className="btn btn-primary  btn-block mb-2"
              >
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              </button>
            </div>
            <div className="col-6 pr-0 pl-1">
              <button
                onClick={this.displayNext}
                type="submit"
                className="btn btn-primary btn-block"
              >
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyDetails;
