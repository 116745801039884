import { PDFExport } from "@progress/kendo-react-pdf";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import { toast } from "react-toastify";
import { PDFForm, PageTemplate } from "../../components";
import { getReceipts } from "../../services/dataServices";
import language from "../../utils/language.json";
import DatePicker from "react-datepicker";
import { subMonths, parseISO, addMonths } from "date-fns";

const Receipts = (props) => {
  const {
    currentLanguage,
    logout,
    location: { key },
  } = props;
  const inputEl = useRef(null);
  const [start, setStartDate] = useState(`${moment().format("YYYY")}`);
  const [receiptData, setReceiptData] = useState();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [getData, setGetData] = useState(false);
  const [displayDates, setDisplayDates] = useState(false);

  useEffect(() => {
    fetchData();
  }, [start, key]);

  // useEffect(() => {
  //   fetchData();
  // }, [key]);

  const fetchData = async () => {
    setLoading(true);

    try {
      const {
        data: { data, message },
      } = await getReceipts({ start });
      setReceiptData(data);
      setLoading(false);
      setMessage(message);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        toast.error(currentLanguage === "en" ? message : message_de);
      }
      if (e.response && e.response.status === 403) logout();
    }
  };

  const generatePDF = () => {
    inputEl.current.save();
  };

  const handleSelect = (start) => {
    setStartDate(start._d);
    setGetData(!getData);
  };

  return (
    <>
      {loading ? (
        <div className="reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border full-page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h5 className="font-weight-bold">
            {language[currentLanguage].receipts}
          </h5>
          <hr className="" />
          <div className="d-flex flex-column flex-md-row justify-content-between mb-0">
            {displayDates && (
              <>
                <DatePicker
                  className="btn btn-outline-secondary w-sm-100"
                  selected={new Date(start)}
                  onChange={(date) =>
                    setStartDate(moment(date).format("YYYY-MM"))
                  }
                  startDate={new Date("2020/01")}
                  endDate={subMonths(new Date(), 1)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  maxDate={subMonths(new Date(), 1)}
                />
              </>
            )}
            {displayDates === false && (
              <button
                className="btn btn-outline-secondary w-sm-100"
                onClick={() => setDisplayDates(true)}
              >
                {language[currentLanguage].receiptBtn}
              </button>
            )}

            {!isEmpty(receiptData) && (
              <div
                className="btn btn-outline-secondary mt-3 mt-md-0 w-100 generate-pdf-btn-size"
                onClick={generatePDF}
              >
                {language[currentLanguage].generatePDF}
              </div>
            )}
          </div>
          <hr className="" />
          {receiptData ? (
            <>
              <PDFExport
                scale={0.6}
                pageTemplate={(e) => (
                  <PageTemplate
                    e={e}
                    currentLanguage={currentLanguage}
                    receiptData={receiptData}
                  />
                )}
                forcePageBreak=".page-break"
                forceProxy="true"
                proxyURL="https://backend.juwelier-mercigold.de/api/juwelier/data/save-pdf"
                ref={inputEl}
                fileName={`${receiptData[0].ju_id}-${moment(start).format(
                  "MM.YYYY"
                )}.pdf`}
                paperSize="A4"
                margin={{ top: 20, left: 50, right: 30, bottom: 130 }}
              >
                {/* <div className="ml-xs-0 ml-md-4"> */}
                <div className="ml-xs-0 mr-md-5 ml-md-3 text-dark pt-2">
                  <PDFForm
                    date={start}
                    receiptData={receiptData}
                    currentLanguage={currentLanguage}
                    start={moment(start).format("DD.MM.YYYY")}
                  />
                </div>
              </PDFExport>
            </>
          ) : (
            <h5 className="my-5">{message}</h5>
          )}
        </div>
      )}
    </>
  );
};

export default Receipts;
