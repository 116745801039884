import { Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FloatingInputField, UpdateAccountInfo } from "../../../components";
import { updateBankDetails } from "../../../services/authService";
import language from "../../../utils/language.json";

export default function Settings(props) {
  const { currentLanguage, handleSelect, data, logout, setUpdateData } = props;
  const {
    bank_owner,
    bank_name,
    bank_currency,
    bank_Iban,
    bank_code,
    tax_id,
  } = data;

  const handleClick = (event) => {
    const checkbox = event.target;
    var checkboxes = document.getElementsByName("language");
    checkboxes.forEach((item) => {
      if (item !== checkbox) item.checked = false;
    });
  };

  const handleUpdateBankDetails = async (values) => {
    try {
      const {
        data: { message, message_de, success },
      } = await updateBankDetails({
        bank_details: values.bank_Iban,
        ...values,
      });
      if (success)
        toast.success(currentLanguage === "en" ? message : message_de);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        toast.error(e.response.data.message);
      }
      if (e.response && e.response.status === 403) {
        logout();
      }
    }
  };

  const bankDetailsSchema = Yup.object().shape({
    bank_owner: Yup.string().required(language[currentLanguage].required),
    bank_name: Yup.string().required(language[currentLanguage].required),
    bank_Iban: Yup.string().required(language[currentLanguage].required),
    bank_currency: Yup.string().required(language[currentLanguage].required),
    bank_code: Yup.string().required(language[currentLanguage].required),
    tax_id: Yup.string().required(language[currentLanguage].required),
  });

  return (
    <div className="container p-0 mx-1">
      <div className="d-flex justify-content-between">
        <h5 className="w-100 font-weight-bold">
          {language[currentLanguage].settings}
        </h5>
      </div>
      <hr />
      <div>
        <h5>
          <strong>{language[currentLanguage].account}</strong>
        </h5>
        <UpdateAccountInfo
          currentLanguage={currentLanguage}
          data={data}
          setUpdateData={setUpdateData}
          logout={logout}
        />
      </div>
      <hr />
      <div>
        <h5>
          <strong>{language[currentLanguage].paymentDetails}</strong>
        </h5>

        <h6>
          <u>{language[currentLanguage].bankData}</u>
        </h6>
        <br />
        <Formik
          initialValues={{
            bank_owner,
            bank_name,
            bank_currency,
            bank_Iban,
            bank_code,
            tax_id,
          }}
          validationSchema={bankDetailsSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleUpdateBankDetails({ ...values, currentLanguage });
            setSubmitting(true);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} className="row m-0 p-0">
              <div className="mb-0 col-lg-6 col-sm-12 pl-0 pr-0 pr-md-3">
                <FloatingInputField
                  type="text"
                  name="bank_owner"
                  value={values.bank_owner}
                  placeholderValue={language[currentLanguage].firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.bank_owner && touched.bank_owner && errors.bank_owner
                  }
                />
              </div>
              <div className="mb-0 col-lg-6 col-sm-12 pl-0 pr-0 pr-md-3">
                <FloatingInputField
                  type="text"
                  name="bank_name"
                  value={values.bank_name}
                  placeholderValue={language[currentLanguage].bankName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.bank_name && touched.bank_name && errors.bank_name
                  }
                />
              </div>
              <div className="mb-0 col-lg-6 col-sm-12 pl-0 pr-0 pr-md-3">
                <FloatingInputField
                  type="text"
                  name="bank_Iban"
                  value={values.bank_Iban}
                  placeholderValue="IBAN"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.bank_Iban && touched.bank_Iban && errors.bank_Iban
                  }
                />
              </div>
              <div className="mb-0 col-lg-6 col-sm-12 pl-0 pr-0 pr-md-3">
                <FloatingInputField
                  type="text"
                  name="bank_code"
                  value={values.bank_code}
                  placeholderValue={language[currentLanguage].bankCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.bank_code && touched.bank_code && errors.bank_code
                  }
                />
              </div>
              <div className="mb-0 col-lg-6 col-sm-12 pl-0 pr-0 pr-md-3">
                <FloatingInputField
                  type="text"
                  name="tax_id"
                  value={values.tax_id}
                  placeholderValue={language[currentLanguage].taxId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.tax_id && touched.tax_id && errors.tax_id}
                />
              </div>
              <div className="mb-0 col-lg-6 col-sm-12 pl-0 pr-0 pr-md-3">
                <FloatingInputField
                  type="text"
                  name="bank_currency"
                  value={values.bank_currency}
                  onBlur={handleBlur}
                  error={
                    errors.bank_currency &&
                    touched.bank_currency &&
                    errors.bank_currency
                  }
                />
              </div>

              <div className="col-12 pl-0">
                <button type="submit" className="btn btn-primary">
                  {language[currentLanguage].saveChanges}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <hr />
      <div>
        <h5 onClick={() => logout()} className="cursor">
          <strong>{language[currentLanguage].logout}</strong>
        </h5>
      </div>
      <hr />
      <div>
        <Link to="/home/change-password" className="link-decoration">
          <h5 className="cursor">
            <strong>{language[currentLanguage].changePassword}</strong>
          </h5>
        </Link>
      </div>
      <hr />

      <div>
        <h5 className="mb-1">
          <strong>{language[currentLanguage].languageSelection}</strong>
        </h5>
        <div className=" list-group-item d-flex justify-content-between align-items-center w-100 p-0">
          <label>English</label>
          <input
            type="checkbox"
            name="language"
            id="en"
            checked={currentLanguage === "en"}
            onChange={(e) => handleSelect(e, "en")}
            onClick={handleClick}
          />
        </div>
        <div className="mb-0 list-group-item d-flex justify-content-between align-items-center w-100 p-0">
          <label>German</label>
          <input
            type="checkbox"
            checked={currentLanguage === "de"}
            className="text-align-checkbox"
            name="language"
            id="de"
            onChange={(e) => handleSelect(e, "de")}
            onClick={handleClick}
          />
        </div>
      </div>
      <hr />
      <div>
        <h5>
          <strong>{language[currentLanguage].legalInformation}</strong>
        </h5>
        <Link to="/home/terms" className="link-decoration">
          <h6 className="p-0">{language[currentLanguage].termsOfUse}</h6>
        </Link>
        {/* <Link to="/home/privacy" className="link-decoration">
          <h6 className="p-0">{language[currentLanguage].privacy}</h6>
        </Link> */}
        <Link to="/home/imprint" className="link-decoration">
          <h6 className="p-0">{language[currentLanguage].imprint}</h6>
        </Link>
      </div>
    </div>
  );
}
