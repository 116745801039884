import React from "react";
import language from "../utils/language.json";

const SingleDetailPageProperties = ({
  data: {
    product_id,
    product_gender,
    ring_size,
    material_karat,
    main_stone,
    gravur,
    currentLanguage,
    ju_order_id,
    material_color,
    ring_surface,
  },
}) => {
  // console.log("ju_order", ju_order_id);
  return (
    <>
      {/* <p className="single-page-ring-prop">
        {language[currentLanguage].juOrderId}: {ju_order_id}
      </p> */}
      <p className="single-page-ring-prop">
        {language[currentLanguage].productId}: {product_id}
      </p>
      <p className="single-page-ring-prop">
        {language[currentLanguage].gender}: {product_gender}
      </p>
      <p className="single-page-ring-prop">
        {language[currentLanguage].material}:{" "}
      {material_color && material_color.toLowerCase() === "gelb"
        ? language[currentLanguage].yellowGold
        : material_color.toLowerCase() === "rot"
        ? language[currentLanguage].redGold
        : material_color.toLowerCase() === "weiß"
        ? language[currentLanguage].whiteGold
        : material_color.toLowerCase() === "platin"
        ? language[currentLanguage].platinum
        : material_color.toLowerCase() === "rose"
        ? language[currentLanguage].roseGold
        : material_color.toLowerCase() === "zirkonium"
        ? language[currentLanguage].zirconium
        : language[currentLanguage].titanium}
      </p>
      <p className="single-page-ring-prop">
        {language[currentLanguage].size}: {ring_size}
      </p>
      <p className="single-page-ring-prop">
        {language[currentLanguage].weight}: {material_karat}
      </p>
      <p className="single-page-ring-prop">
        {language[currentLanguage].stone}: {main_stone && main_stone.toLowerCase() === "diamond"
        ? language[currentLanguage].diamond
        : main_stone}
      </p>
      <p className="single-page-ring-prop">
        {language[currentLanguage].surface}:{" "}
        {ring_surface.toLowerCase() === "matt"
          ? language[currentLanguage].matt
          : language[currentLanguage].glanz}
      </p>
      <p className="single-page-ring-prop">
        {language[currentLanguage].engraving}: {gravur}
      </p>
    </>
  );
};

export default SingleDetailPageProperties;
