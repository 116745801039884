import React, { useEffect } from "react";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";

const options = {
  caption: {
    showCaption: false,
  },
};

function FullScreenImages(props) {
  const lightBoxImages = props.images.map((image) => ({
    src: image.original,
    thumbnail: image.thumbnail,
  }));
  const callbacks = {
    onLightboxClosed: (obj) => {
      props.handleHideFullScreen(obj.currentSlide.id);
    },
  };
  const { openLightbox } = useLightbox();
  const open1 = (indexImage) => {
    openLightbox(indexImage);
  };
  useEffect(() => {
    setTimeout(() => {
      open1(props.currentImageIndex);
    }, 50);
  }, []);

  return (
    <div className="FullScreenImages">
      <SRLWrapper
        options={options}
        callbacks={callbacks}
        images={lightBoxImages}
        currentSlide={1}
      />
    </div>
  );
}

export default FullScreenImages;
