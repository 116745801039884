export default function FinalNotification(props) {
  return (
    <>
      <div className="app-width m-auto pl-4 ">
        <h5 className="my-4">{props.heading}</h5>
      </div>
      <div className="w-100 bg-color-final pb-3">
        <div className="app-width m-auto px-4 p">
          <h2 className="py-2 pt-5">{props.greeting || ""}</h2>
          <h6 className="py-2">{props.bodyTitle}</h6>
          <p>{props.bodyText}</p>
          <p className="pb-4">{props.bodyEnd}</p>
        </div>
      </div>
    </>
  );
}
