import logo from "../images/logo.df5eaee1.png";

export default function FooterLogo() {
  return (
    <>
      <hr />
      <div className="row-height">
        <div className="hr-line mx-4" />
        <img src={logo} alt="merci" className="img-size" />
        <div className="hr-line mx-4" />
      </div>
    </>
  );
}
