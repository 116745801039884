import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Messages } from "../components";
import {
  getMessageDetails,
  sendMessageFromInbox,
} from "../services/dataServices";
import language from "../utils/language.json";

const defaultImage = require("../images/default.png");

const MessageDetails = (props) => {
  const {
    state: { image, subject },
  } = props.location;
  const [title, setTitle] = useState("");
  const [error, setError] = useState(true);
  const [imageSrc, setImageSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [writeMessage, setWriteMessage] = useState("");
  const [messagesList, setMessagesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [reloadMessages, setReloadMessages] = useState(false);
  const [newMessage, setNewMessage] = useState(false);

  useEffect(() => {
    const { subject } = props.location.state;
    setTitle(subject);
    setImageSrc(imageSrc);

    allMessagesApi();
  }, [reloadMessages]);

  const allMessagesApi = async () => {
    const { id } = props.match.params;
    try {
      const {
        data: { data },
      } = await getMessageDetails(id);

      if (data.length > messagesList.length) {
        setLoading(true);
        setMessagesList(data);
        setLoading(false);
        setNewMessage(true);
      } else {
        return;
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        toast.error(currentLanguage === "de" ? message_de : message);
        setLoading(false);
      }
      if (e.response && e.response.status === 403) {
        props.logout();
      }
    } finally {
      setLoading(false);
    }
  };
  const handleChange = ({ target: { value } }) => {
    if (value.replace(/\s/g, "").length === 0) {
      setWriteMessage("");
      setError(true);
      setErrorMessage(true);
    } else {
      setWriteMessage(value);
      setError(false);
      setErrorMessage(false);
    }
  };
  const onKeyPress = (e) => {
    if (e.which === 13) {
      sendMessage();
    }
  };

  const sendMessage = async (e) => {
    setMessagesList([
      { isJuwelier: 1, message: writeMessage },
      ...messagesList,
    ]);
    setWriteMessage("");
    const { id } = props.match.params;

    if (isNull(writeMessage)) {
      setErrorMessage(true);
    } else {
      try {
        await sendMessageFromInbox({
          message_id: id,
          message: writeMessage,
        });
        setLoading(false);
        setWriteMessage("");
        setReloadMessages(!reloadMessages);
      } catch (e) {
        if (e.response && e.respnose.status === 400) {
          const { message, message_de } = e.response.data;
          toast.error(currentLanguage === "de" ? message_de : message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const { currentLanguage } = props;
  return (
    <div className="h-100">
      {loading ? (
        <div className="reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border full-page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div>
            <div className="row my-2 align-items-center">
              <div className="col">
                <h5 className="font-weight-bold">
                  {language[currentLanguage].customerService}
                </h5>
              </div>
              <Link
                to={{
                  pathname: `/home/details/${subject}`,
                  state: {
                    categoryName: language[currentLanguage].allProducts,
                  },
                }}
              >
                <img
                  src={image === null ? defaultImage : image}
                  alt="rings"
                  className="inbox-img-size"
                />
              </Link>
            </div>
            <hr className="mt-0" />
          </div>
          <Messages
            messagesList={messagesList}
            allMessagesApi={allMessagesApi}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
          />
          <div className="message-footer w-100 mt-2">
            <input
              type="text"
              name="writeMessage"
              className="form-control"
              id="messageInputField"
              value={writeMessage}
              onChange={(e) => handleChange(e)}
              placeholder={language[currentLanguage].typeMessage}
              onKeyPress={onKeyPress}
            />
            {errorMessage && (
              <div className="col-sm-4">
                <small id="messageInputField" className="text-danger">
                  {language[currentLanguage].errorMessage}
                </small>
              </div>
            )}
            <div className="w-100 text-right mt-2 d-flex justify-content-end">
              <button
                disabled={error}
                type="submit"
                className="message-footer btn btn-primary"
                onClick={(e) => sendMessage(e)}
              >
                {language[currentLanguage].sendMessage}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(MessageDetails);
