import moment from "moment";
import { useEffect, useState } from "react";

export default function Messages(props) {
  const { messagesList, allMessagesApi, newMessage } = props;
  const [displayIcon, setDisplayIcon] = useState(false);

  useEffect(() => {
    var objDiv = document.getElementById("MyDivElement");
    objDiv.scrollTop = objDiv.scrollHeight;
    const chatInterval = setInterval(allMessagesApi, 3000);
    return () => clearInterval(chatInterval);
  }, []);

  function scrollFunction() {
    var objDiv = document.getElementById("MyDivElement");

    if (objDiv.scrollHeight - objDiv.scrollTop !== objDiv.clientHeight) {
      setDisplayIcon(true);
    } else {
      setDisplayIcon(false);
    }
  }
  function topFunction() {
    var objDiv = document.getElementById("MyDivElement");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  return (
    <div
      className="container p-0 w-100 overflow-auto messages-height"
      id="MyDivElement"
      onScroll={() => scrollFunction()}
    >
      {messagesList.map((list, index) => (
        <div key={list.ju_messages_juweliers}>
          <div
            className={list.isJuwelier === 0 ? "my-2" : "producer-field my-2"}
          >
            <span
              role="input"
              type="text"
              readOnly
              size="100"
              className={
                list.isJuwelier === 0
                  ? "bg-light form-control w-50 text-justify p-2 checkingwrap"
                  : "bg-dark text-white form-control w-50 text-justify p-2 checkingwrap"
              }
              id="formGroupExampleInput"
            >
              {list.message}
            </span>
            <div className="row">
              <small
                id="formGroupExampleInput"
                className="form-text text-muted"
              >
                {moment(list.message_time).format("DD.MM.YYYY H:mm")}
              </small>
            </div>
          </div>
        </div>
      ))}
      {displayIcon && (
        <button
          onClick={() => topFunction()}
          id="myBtn"
          title="Go to top"
          className="myBtn"
        >
          <span
            className={newMessage === true ? "badge badge-danger" : "d-none"}
          ></span>
          <i className="fa fa-arrow-down" aria-hidden="true"></i>
        </button>
      )}
    </div>
  );
}
