import * as _ from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

import { DetailsCard } from "../../components";
import { getActiveOrders } from "../../services/dataServices";
import language from "../../utils/language.json";

const ActiveOrders = (props) => {
  const {
    currentLanguage,
    logout,
    location: { key },
  } = props;
  const [loading, setLoading] = useState(true);
  const [ringsData, setRingsData] = useState([]);
  const [count, setCount] = useState("");
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [mount, setMount] = useState(true);
  useEffect(() => {
    fetchData({ updatedOffset: 0 });
    return () => {
      setMount(false);
    };
  }, [key]);

  const handleLoadMore = () => {
    fetchData({ updatedOffset: offset + limit });
  };

  const fetchData = async ({ updatedOffset }) => {
    setLoading(true);
    try {
      const {
        data: { data, orders_count },
      } = await getActiveOrders({ limit, offset: updatedOffset });
      if (mount === true) {
        setRingsData(updatedOffset > offset ? [...ringsData, ...data] : data);
        setCount(orders_count ? orders_count : "");
        setOffset(updatedOffset);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 403) {
        logout();
      }
    }
  };

  return (
    <div className="mx-1">
      {loading ? (
        <div className="reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border full-page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h5 className="font-weight-bold ">
            {language[currentLanguage].activeOrders}
          </h5>
          <hr />
          <div className="row d-flex justify-content-center ">
            {_.isEmpty(ringsData) ? (
              <h5>{language[currentLanguage].noActiveOrders}</h5>
            ) : (
              <div>
                {ringsData.map((rings) => (
                  <Link
                    key={rings.ju_order_id}
                    className={
                      rings.offer_status === 2
                        ? "disable-link link-decoration"
                        : "link-decoration"
                    }
                    to={{
                      pathname: `/home/order-details/${rings.ju_order_id}`,

                      state: {
                        categoryName: language[currentLanguage].activeOrders,
                      },
                    }}
                  >
                    <DetailsCard
                      categoryName={language[currentLanguage].activeOrders}
                      rings={rings}
                      key={rings.ju_order_id}
                      currentLanguage={currentLanguage}
                    />
                  </Link>
                ))}
                <div className="text-right">
                  {!isEmpty(ringsData) && count > ringsData.length && (
                    <div
                      className="btn btn-outline-secondary text-center"
                      onClick={() => handleLoadMore()}
                    >
                      {language[currentLanguage].loadMoreBtn}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveOrders;
