import { Formik } from "formik";
import React, { Component } from "react";

import { FloatingInputField } from "../components";
import language from "../utils/language.json";

class EmailInputField extends Component {
  handleValidation = (values) => {
    const errors = {};
    const { currentLanguage } = this.props;
    if (!values.juwelier_email) {
      errors.juwelier_email = language[currentLanguage].emailRequired;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.juwelier_email)
    ) {
      errors.juwelier_email = language[currentLanguage].invalidEmail;
    }
    return errors;
  };
  render() {
    const { loading, currentLanguage } = this.props;

    return (
      <>
        <div className="app-width m-auto p-3 d-flex flex-column justify-content-center">
          <>
            <h5 className="reset-password-title-heading">
              {language[currentLanguage].enterEmailReset}
            </h5>
            <br />
            <Formik
              initialValues={{ juwelier_email: "" }}
              validate={this.handleValidation}
              onSubmit={this.props.resendEmail}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FloatingInputField
                    placeholderValue={language[currentLanguage].email}
                    type="email"
                    name="juwelier_email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.juwelier_email}
                    error={
                      errors.juwelier_email &&
                      touched.juwelier_email &&
                      errors.juwelier_email
                    }
                  />
                  <>
                    {loading && (
                      <div className="spinner-control">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary btn-sm btn-block btn-final"
                    >
                      {loading ? null : (
                        <strong>{language[currentLanguage].next}</strong>
                      )}
                    </button>
                  </>
                </form>
              )}
            </Formik>
          </>
        </div>
      </>
    );
  }
}

export default EmailInputField;
