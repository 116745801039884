import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  JPickupService,
  JReturnService,
  PickupBooked,
  PickupComplete,
  RepairOfferSent,
  RepairOfferServiceBooked,
  RepairPickupBooked,
  RepairPickupComplete,
  RepairReturnBooked,
  RepairReturnPickupComplete,
  ReturnBooked,
  ReturnComplete,
  ReturnPickupComplete,
} from "../../components";
import { getOrderDetails, pickupOrder } from "../../services/dataServices";
import language from "../../utils/language.json";

const ActiveOrderDetails = (props) => {
  const {
    currentLanguage,
    logout,
    location: { state },
  } = props;
  const [displayModal, setDisplayModal] = useState("0");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("");
  const [reset, setReset] = useState(false);
  const [id, setId] = useState(props.match.params.id);
  const [catName, setCatName] = useState("");
  const [response, setResponse] = useState({});

  useEffect(() => {
    fetchData();
    setCatName(
      state !== undefined
        ? state.categoryName
        : language[currentLanguage].activeOrders
    );
  }, [reset]);

  const fetchData = async () => {
    setLoading(true);
    const { id } = props.match.params;
    try {
      const {
        data: { data, current_time, message, message_de },
      } = await getOrderDetails(id);
      setResponse({ message, message_de });
      setLoading(false);
      if (data?.length) {
        setTime(
          moment(current_time).diff(moment(data[0].ju_order_time), "days")
        );
        setData(data[0]);
        if (!isEmpty(data[0]) && data[0].ju_service !== 1) {
          const { offer_status, ju_service_status } = data[0];
          if (offer_status === 1 || offer_status === 3 || offer_status === 4) {
            setDisplayModal(0);
          } else {
            setDisplayModal(ju_service_status);
          }
        } else if (!isEmpty(data[0]) && data[0].ju_service === 1) {
          setDisplayModal(4);
        }
      } else {
        setDisplayModal(5);
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 403) {
        props.logout();
      }
    }
  };

  const pickupOrderUpdate = async (status) => {
    setLoading(true);
    const { id } = props.match.params;
    try {
      await pickupOrder({ ju_order_id: id, status });
      fetchData();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 403) {
        logout();
      }
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        toast.error(currentLanguage === "en" ? message : message_de);
      }
    }
  };
  return (
    <div>
      <div className="col-xs-12 px-0">
        <div className="d-flex justify-content-between">
          <p className="text-align-left ">
            {" "}
            {catName && (
              <>
                <Link className="text-decoration-none " to="/home">
                  {language[currentLanguage].home}
                </Link>{" "}
                |{" "}
                <Link
                  className="text-decoration-none "
                  to={
                    catName ===
                    (language[currentLanguage].activeOrders || undefined)
                      ? `/home/active-orders`
                      : catName === language[currentLanguage].pastOrders
                      ? `/home/past-orders`
                      : `/home/active-offers`
                  }
                >
                  {catName ===
                  (language[currentLanguage].activeOrders || undefined)
                    ? language[currentLanguage].activeOrders
                    : catName === language[currentLanguage].pastOrders
                    ? language[currentLanguage].pastOrders
                    : language[currentLanguage].myActiveOffers}
                </Link>{" "}
                |{" "}
              </>
            )}
            {catName === language[currentLanguage].activeOrders
              ? language[currentLanguage].order
              : catName === language[currentLanguage].pastOrders
              ? language[currentLanguage].order
              : language[currentLanguage].offers}{" "}
            {language[currentLanguage].details}
          </p>
        </div>
      </div>
      <hr />
      {(() => {
        switch (displayModal) {
          case 0:
            return offerCards(
              data.offer_status,
              data.ju_service_status,
              currentLanguage,
              data,
              id,
              loading,
              setReset
            );
          case 1:
            return (
              <RepairOfferServiceBooked
                setDisplayModal={setDisplayModal}
                currentLanguage={currentLanguage}
                loading={loading}
                data={data}
              />
            );
          case 2:
            return (
              <RepairPickupBooked
                setDisplayModal={setDisplayModal}
                currentLanguage={currentLanguage}
                loading={loading}
                data={data}
              />
            );
          case 3:
            return (
              <RepairPickupComplete
                setDisplayModal={setDisplayModal}
                currentLanguage={currentLanguage}
                loading={loading}
                data={!isEmpty(data) && data}
              />
            );
          case 4:
            return pickupCards(
              data.ju_pickup_status,
              currentLanguage,
              pickupOrderUpdate,
              time,
              loading,
              data.service_price
            );
          case 5:
            return (
              <h5 className="row d-flex justify-content-center">
                {currentLanguage === "en"
                  ? response.message
                  : response.message_de}
              </h5>
            );
          default:
            return (
              <div className="reset-page-height">
                <div className="spinner-control">
                  <div
                    className="spinner-border full-page-spinner"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            );
        }
      })()}
    </div>
  );
};

const offerCards = (
  status,
  serviceStat,
  currentLanguage,
  data,
  id,
  loading,
  setReset
) => {
  if (status === 1 && serviceStat === 0) {
    return <RepairOfferSent currentLanguage={currentLanguage} />;
  }

  if (status === 3) {
    return (
      <RepairReturnBooked
        currentLanguage={currentLanguage}
        data={data}
        id={id}
        setReset={setReset}
        loading={loading}
      />
    );
  }
  if (status === 4) {
    return (
      <RepairReturnPickupComplete
        loading={loading}
        currentLanguage={currentLanguage}
      />
    );
  }
};

const pickupCards = (
  status,
  currentLanguage,
  pickupOrderUpdate,
  time,
  loading,
  price
) => {
  // console.log("data", data.service_price);
  switch (status) {
    case 0:
      return (
        <JPickupService
          currentLanguage={currentLanguage}
          pickupOrderUpdate={pickupOrderUpdate}
          loading={loading}
          price={price}
        />
      );
    case 1:
      return (
        <PickupBooked
          time={time}
          currentLanguage={currentLanguage}
          pickupOrderUpdate={pickupOrderUpdate}
          loading={loading}
        />
      );
    case 2:
      return <PickupComplete currentLanguage={currentLanguage} />;
    case 3:
      return (
        <ReturnBooked
          currentLanguage={currentLanguage}
          pickupOrderUpdate={pickupOrderUpdate}
          loading={loading}
        />
      );
    case 4:
      return (
        <ReturnPickupComplete
          changeText={true}
          currentLanguage={currentLanguage}
          pickupOrderUpdate={pickupOrderUpdate}
        />
      );
    case 5:
      return (
        <JReturnService
          currentLanguage={currentLanguage}
          pickupOrderUpdate={pickupOrderUpdate}
          loading={loading}
        />
      );
    case 6:
      return (
        <ReturnComplete
          changeText={false}
          currentLanguage={currentLanguage}
          pickupOrderUpdate={pickupOrderUpdate}
        />
      );
    default:
      return (
        <div className="reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border full-page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
  }
};
export default ActiveOrderDetails;
