import { Switch, Route, Redirect } from "react-router-dom";
import { useState, useEffect } from "react";

import {
  Welcome,
  Login,
  SignUp,
  PasswordReset,
  EmailConfirmationPage,
  BankDetailsConfirmation,
} from "../pages";
import { Header, ForgotPasswordConfirmation } from "../components";

const PublicRoutes = (props) => {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [languageLoading, setLanguageLoading] = useState(true);
  const { isAuthenticated, setIsAuthenticated, verifyToken } = props;

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      // setCurrentLanguage(navigator.language === "de" ? "de" : "en");
      setCurrentLanguage(navigator.language.includes("en") ? "en" : "de");
    } else {
      const preferredLanguage = localStorage.getItem("language");
      setCurrentLanguage(preferredLanguage);
    }
    setLanguageLoading(false);
  }, []);

  const handleSelect = (e, language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };
  return (
    !languageLoading && (
      <main>
        <div className="app-width mx-auto">
          <nav className="d-flex align-items-center m-2 pr-2 ">
            <Header
              handleSelect={handleSelect}
              currentLanguage={currentLanguage}
              isAuthenticated={isAuthenticated}
            />
          </nav>
        </div>
        <hr className="mt-0" />
        <div className="">
          <Switch>
            <Route
              path="/email-confirmation/:token"
              exact
              render={(props) => (
                <EmailConfirmationPage
                  {...props}
                  currentLanguage={currentLanguage}
                />
              )}
            />
            <Route
              path="/bank-confirmation/:token"
              exact
              render={(props) => (
                <BankDetailsConfirmation
                  {...props}
                  currentLanguage={currentLanguage}
                />
              )}
            />
            <Route
              path="/forgot-password/:token"
              exact
              render={(props) => (
                <ForgotPasswordConfirmation
                  {...props}
                  currentLanguage={currentLanguage}
                  isAuthenticated={true}
                />
              )}
            />
            <Route
              path="/password-reset"
              exact
              render={(props) => (
                <PasswordReset {...props} currentLanguage={currentLanguage} />
              )}
            />
            <Route
              path="/signup"
              exact
              render={(props) => (
                <SignUp {...props} currentLanguage={currentLanguage} />
              )}
            />
            <Route
              path="/login"
              exact
              render={(props) => (
                <Login
                  {...props}
                  setIsAuthenticated={setIsAuthenticated}
                  currentLanguage={currentLanguage}
                  verifyToken={verifyToken}
                />
              )}
            />
            <Route
              path="/"
              exact
              render={(props) => (
                <Welcome {...props} currentLanguage={currentLanguage} />
              )}
            />
            <Redirect to="/" />
          </Switch>
        </div>
      </main>
    )
  );
};

export default PublicRoutes;
