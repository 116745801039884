import { useState } from "react";
import { ShadowIcon } from "../../images";
import language from "../../utils/language.json";

const ReturnBooked = ({ pickupOrderUpdate, currentLanguage, loading }) => {
  const [confirmBtn, setConfirmBtn] = useState(false);

  const handleConfirm = () => {
    pickupOrderUpdate(4);
  };
  return (
    <div>
      <div>{language[currentLanguage].returnBookedTitle}</div>
      <div className="p-0 py-4">
        <div className="d-flex flex-column">
          <div className="pb-4">
            <div className=" mb-3 h-100 w-100 d-flex justify-content-between">
              <p className="ul-text my-auto">
                {language[currentLanguage].custIsAllowed}
              </p>
              <span>
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
            </div>
            <div
              // onClick={() => handleButton()}
              className=" mb-3 h-100  w-100 d-flex justify-content-between"
            >
              <div>
                <span className="ul-text my-auto">
                  {language[currentLanguage].returnLabel}
                </span>
                <br />
                <span className="ul-text my-auto">
                  {language[currentLanguage].custDontPickedUp}
                </span>
              </div>
              <span>
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div className="booked-body p-3 mb-3">
            <p className="card-mid-title">
              {language[currentLanguage].jwlrPickupBkd}
            </p>
            <p className="mb-6 card-mid-body">
              {language[currentLanguage].custReturnInfo}
            </p>
            <img
              src={ShadowIcon}
              className="check-circle-style"
              alt="approved"
            />
            <span className="card-end-body">
              {language[currentLanguage].thankYou}
            </span>
          </div>
          <div>
            <button
              className="btn btn-outline-secondary mb-3 w-100 selected-btn"
              onClick={() => setConfirmBtn(true)}
            >
              {language[currentLanguage].dhlPicked}
            </button>
            {confirmBtn && (
              <button
                onClick={() => handleConfirm()}
                className="btn btn-primary w-100 m-auto my-6"
              >
                {loading
                  ? language[currentLanguage].loading
                  : language[currentLanguage].confirm}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnBooked;
