import { ShadowIcon } from "../../images";
import language from "../../utils/language.json";

const RepairOfferSent = ({ setDisplayModal, currentLanguage, loading }) => {
  return (
    <>
      {loading ? (
        <div className="reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border full-page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>{language[currentLanguage].offerSent}</div>
          <hr />
          <div className="p-0 py-4">
            <div className="d-flex flex-column">
              <div className="pb-4">
                <div
                  // onClick={() => handleButton()}
                  className=" mb-3 h-100  w-100 d-flex justify-content-between"
                >
                  <p className="ul-text my-auto">
                    {language[currentLanguage].offerSentBooked}
                  </p>
                  <span className="my-auto">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div className="booked-body p-3 mb-3 px-4">
                <p className="card-mid-title">
                  {language[currentLanguage].offerisBooked.toUpperCase()}
                </p>
                <p className="mb-6 card-mid-body">
                  {language[currentLanguage].weWillAccept}
                </p>
                <img
                  src={ShadowIcon}
                  className="check-circle-style"
                  alt="approved"
                />
                <span className="card-end-body">
                  {language[currentLanguage].thankYou}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RepairOfferSent;
