import http from "./httpService";

const apiEndpoint = "/juwelier/data";

export async function getAllMessages() {
  return http.get(`${apiEndpoint}/get_messages_groups`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function getMessageDetails(id) {
  return http.get(`${apiEndpoint}/get_messages_groups_detail/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}

export async function sendMessageFromInbox(data) {
  return http.post(`${apiEndpoint}/send_message_inbox`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function sendMessageFromContact(message) {
  return http.post(`${apiEndpoint}/send_message_contactus`, message, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}

export async function getPastOrdersData({ limit = "", offset = "" }) {
  return http.get(
    `${apiEndpoint}/get_past_orders?limit=${limit}&offset=${offset}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}
export async function getActiveOrders({ limit = "", offset = "" }) {
  return http.get(
    `${apiEndpoint}/get_active_orders?limit=${limit}&offset=${offset}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}
export async function getMyOffers({ limit = "", offset = "" }) {
  return http.get(
    `${apiEndpoint}/get_my_offers?limit=${limit}&offset=${offset}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}

export async function getProductDetails({ orderId, orderDate }) {
  return http.get(
    `${apiEndpoint}/get_scanned_order/${orderId}?orderDate=${orderDate}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}
export async function getOrderDetails(id) {
  return http.get(`${apiEndpoint}/get_order_detail/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function getPickupInfo(id) {
  return http.get(`${apiEndpoint}/get_pickup_info?order_id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function orderUpdate(obj) {
  return http.post(`${apiEndpoint}/order_update`, obj, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function createPickupService({ id, pickup_price }) {
  return http.post(
    `${apiEndpoint}/create_pickup_service`,
    { order_id: id, pickup_price },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}
export async function offerDeclined(obj) {
  return http.post(`${apiEndpoint}/offer_declined_update`, obj, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function createNewService(obj) {
  return http.post(`${apiEndpoint}/create_service`, obj, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function getServicePrices() {
  return http.get(`${apiEndpoint}/get_service_prices`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function getNotificationsData() {
  return http.get(`${apiEndpoint}/get_notifications`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function pickupOrder(obj) {
  return http.post(`${apiEndpoint}/pickup_order_update`, obj, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function getServiceInfo({
  order_id,
  ju_service,
  ringtype,
  gender,
}) {
  return http.get(
    `${apiEndpoint}/get_service_info?order_id=${order_id}&ju_service=${ju_service}&ringtype=${ringtype}&gender=${gender}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}
export async function getCounters() {
  return http.get(
    `${apiEndpoint}/notification_counters`,

    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}
export async function getReceipts({ start }) {
  return http.get(
    `${apiEndpoint}/get_bill_orders?startDate=${start}&endDate=${start}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}

export default {
  getAllMessages,
  getMessageDetails,
  sendMessageFromInbox,
  sendMessageFromContact,
  getPastOrdersData,
  getActiveOrders,
  getMyOffers,
  getProductDetails,
  orderUpdate,
  offerDeclined,
  createNewService,
  getServiceInfo,
  getServicePrices,
  getOrderDetails,
  getPickupInfo,
  createPickupService,
  pickupOrder,
  getNotificationsData,
  getCounters,
  getReceipts,
};
