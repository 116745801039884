import language from "../utils/language.json";

const PageTemplate = (props) => {
  const { e, currentLanguage, receiptData } = props;
  console.log("receiptData", receiptData);
  const {
    company_name,
    company_address,
    company_postcode,
    company_city,
    company_country,
    ju_firstName,
    ju_lastName,
    tax_id,
    bank_owner,
    bank_Iban,
    bank_code,
    bank_name,
  } = receiptData[0];
  return (
    <div style={{
      position: 'absolute',
      bottom: '10px',
      width: '100%',
    }}>
      <div
        className="mb-3"
        // style={{
        //   marginTop: "330px",
        // }}
      >
        <div className="row">
          <div className="col d-flex">
            <p className="text-dark">
              <small>
                <span style={{ fontWeight: "bold" }}>{company_name}</span>{" "}
                <br />
                {company_address}
                <br />
                {`${company_postcode} ${company_city} ${company_country}`}{" "}
                <br />
                {language[currentLanguage].taxId}: {tax_id}
              </small>
            </p>
            <p className="field-margin text-dark">
              <small>
                <span style={{ fontWeight: "bold" }}>
                  {language[currentLanguage].bankdetails}
                </span>{" "}
                <br />
                {bank_owner}  <br />
                {bank_name} <br />
                IBAN: {bank_Iban} <br />
                Bic/Swift: {bank_code}
              </small>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="row d-flex align-items-end justify-content-center w-100 text-dark">
        <p
          style={{ marginRight: "40px" }}
        >{`Page ${e.pageNum}/${e.totalPages}`}</p>
      </div> */}

      <div className="text-center text-dark">
        <p>{`Page ${e.pageNum}/${e.totalPages}`}</p>
      </div>
    </div>
  );
};

export default PageTemplate;
