import { useState } from "react";
import language from "../../utils/language.json";

const JPickupService = ({
  currentLanguage,
  pickupOrderUpdate,
  loading,
  price,
}) => {
  const [confirmBtn, setConfirmBtn] = useState(false);

  const handleButton = () => {
    setConfirmBtn(true);
  };
  const handleConfirm = () => {
    pickupOrderUpdate(1);
  };

  return (
    <div>
      <div>{language[currentLanguage].jpickupServiceTitle}</div>
      <hr />
      <div className="text-center p-0 py-4">
        <div className="d-flex flex-column px-3">
          <button
            onClick={() => handleButton()}
            className="btn btn-outline-secondary selected-btn mb-3 h-100  w-100 mx-auto "
          >
            {language[currentLanguage].jpickupServiceBodyText} ({price}€)
          </button>
          {confirmBtn && (
            <button
              onClick={() => handleConfirm()}
              className="btn btn-primary w-100 m-auto my-6"
            >
              {loading
                ? language[currentLanguage].loading
                : language[currentLanguage].confirm}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default JPickupService;
