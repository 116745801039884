import React, { Component } from "react";

import PersonalDetails from "./personalDetails";
import CompanyDetails from "./companyDetails";
import AddressDetails from "./addressDetails";
import PaymentDetails from "./paymentDetails";

import { registerNewUser } from "../../../services/authService";
import { toast } from "react-toastify";

class SignUp extends Component {
  state = {
    currentStep: 1,
    completeData: {},
    loading: false,
    finalConfirmation: false,
    radioCheck1: "isShipping",
    radioCheck2: "isPickup",
  };

  handleChange = ({ target: { name: key, value } }) => {
    this.setState({ [key]: value });
  };

  handleClick = () => {
    this.setState({ companyData: true });
  };

  handleNext = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  };
  handleBack = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep - 1,
    });
  };
  collectValues = (rcvObj) => {
    this.setState((prevState) => ({
      completeData: {
        ...prevState.completeData,
        ...rcvObj,
      },
    }));
  };
  handleRadioSelection = ({ target: { name, checked, value } }) => {
    this.setState({ radioCheck1: value });
  };
  handleSecondRadioSelection = ({ target: { value } }) => {
    this.setState({ radioCheck2: value });
  };

  registerUser = async () => {
    const { completeData } = this.state;
    const { currentLanguage } = this.props;
    const updatedObj = { ...completeData, language: currentLanguage };
    this.setState({ loading: true });
    try {
      const {
        data: { message, message_de },
      } = await registerNewUser(updatedObj);
      this.setState({ loading: false, finalConfirmation: true });
      toast.info(this.props.currentLanguage === "en" ? message : message_de);
    } catch (ex) {
      this.setState({ loading: false });
      if (ex.response) {
        const { message, message_de } = ex.response.data;
        toast.error(this.props.currentLanguage === "en" ? message : message_de);
      }
    }
  };
  render() {
    const {
      currentStep,
      loading,
      completeData,
      radioCheck1,
      radioCheck2,
    } = this.state;
    const { currentLanguage } = this.props;
    switch (currentStep) {
      case 1:
        return (
          <PersonalDetails
            completeData={completeData}
            currentStep={currentStep}
            handleChange={this.handleChange}
            collectValues={this.collectValues}
            handleNext={this.handleNext}
            currentLanguage={currentLanguage}
          />
        );
      case 2:
        return (
          <CompanyDetails
            completeData={completeData}
            currentStep={currentStep}
            handleChange={this.handleChange}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            selectCountry={this.selectCountry}
            collectValues={this.collectValues}
            currentLanguage={currentLanguage}
          />
        );
      case 3:
        return (
          <AddressDetails
            currentStep={currentStep}
            handleChange={this.handleChange}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            selectCountry={this.selectCountry}
            collectValues={this.collectValues}
            completeData={completeData}
            handleRadioSelection={this.handleRadioSelection}
            handleSecondRadioSelection={this.handleSecondRadioSelection}
            radioCheck1={radioCheck1}
            radioCheck2={radioCheck2}
            currentLanguage={currentLanguage}
          />
        );
      case 4:
        return (
          <PaymentDetails
            completeData={completeData}
            currentStep={currentStep}
            handleChange={this.handleChange}
            collectValues={this.collectValues}
            handleBack={this.handleBack}
            registerUser={this.registerUser}
            loading={loading}
            finalConfirmation={this.state.finalConfirmation}
            currentLanguage={currentLanguage}
          />
        );
    }
  }
}

export default SignUp;
