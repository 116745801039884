import * as moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";

import language from "../utils/language.json";
import DetailsProperties from "./detailsProperties";

const DetailsCard = (props) => {
  const {
    product_id,
    product_gender,
    material_color,
    ring_size,
    ring_surface,
    material_karat,
    main_stone,
    gravur,
    ju_order_id,
    male_gravur,
    male_ring_surface,
    male_material_color,
    male_ring_size,
    male_main_stone,
    male_material_karat,
    product_image,
    ju_id,
    ju_order_time,
    pw_offer_id,
    offer_status,
    pw_listing_available,
    user_bill_firstName,
    user_bill_lastName,
  } = props.rings;

  const { currentLanguage } = props;

  return (
    <>
      <div className="d-flex flex-column pt-3 ">
        <div className="card cursor pb-4 " key={product_id}>
          <div className="d-none d-md-inline ">
            <div className="row ">
              <div className=" col-4 small-image-size pr-1">
                <img
                  className="card-img-top img-height"
                  src={product_image}
                  alt="Card cap"
                />
              </div>
              <div className="col-4 details-padding">
                <div className="card-body d-flex ml-md-5">
                  <div className="d-flex flex-column">
                    <DetailsProperties
                      data={{
                        language,
                        ju_id,
                        product_id,
                        ju_order_id,
                        product_gender:
                          product_gender &&
                          product_gender.toLowerCase() === "single"
                            ? language[currentLanguage].single
                            : language[currentLanguage].woman,
                        material_color,
                        ring_size,
                        material_karat,
                        main_stone,
                        gravur,
                        currentLanguage,
                        ring_surface,
                      }}
                    />
                  </div>
                </div>
              </div>
              {product_gender === "couple" && (
                <div className="col-4 details-padding">
                  <div className="card-body d-flex ml-md-5">
                    <div className="d-flex flex-column">
                      <DetailsProperties
                        data={{
                          language,
                          ju_id,
                          product_id,
                          ju_order_id,
                          product_gender:
                            product_gender &&
                            product_gender.toLowerCase() === "single"
                              ? language[currentLanguage].single
                              : language[currentLanguage].man,
                          ring_surface: male_ring_surface,
                          material_color: male_material_color,
                          ring_size: male_ring_size,
                          material_karat: male_material_karat,
                          main_stone: male_main_stone,
                          gravur: male_gravur,
                          currentLanguage,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="d-block d-md-none ">
            <div className="row ">
              <div className="col-12 p-0 m-0">
                <div className="col-12 small-image-size p-0 pb-3">
                  <img
                    className="card-img-top img-height"
                    src={product_image}
                    alt="Card cap"
                  />
                </div>
                <hr className="pt-2" />
                <h6 className="m-0 p-0">
                  {user_bill_firstName + " " + user_bill_lastName}
                </h6>
                <hr className="pb-2" />
                <div className="row">
                  <div className="col-6 m-0 p-0 details-padding">
                    <div className="card-body d-flex ml-md-5">
                      <div className="d-flex flex-column">
                        <DetailsProperties
                          data={{
                            language,
                            ju_id,
                            product_id,
                            ju_order_id,
                            product_gender:
                              product_gender &&
                              product_gender.toLowerCase() === "single"
                                ? language[currentLanguage].single
                                : language[currentLanguage].woman,
                            material_color,
                            ring_size,
                            material_karat,
                            main_stone,
                            gravur,
                            currentLanguage,
                            ring_surface,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {product_gender === "couple" && (
                    <div className="col-6 m-0 p-0 details-padding">
                      <div className="card-body d-flex ml-md-5">
                        <div className="d-flex flex-column">
                          <DetailsProperties
                            data={{
                              language,
                              ju_id,
                              product_id,
                              ju_order_id,
                              product_gender:
                                product_gender &&
                                product_gender.toLowerCase() === "single"
                                  ? language[currentLanguage].single
                                  : language[currentLanguage].man,
                              ring_surface: male_ring_surface,
                              material_color: male_material_color,
                              ring_size: male_ring_size,
                              material_karat: male_material_karat,
                              main_stone: male_main_stone,
                              gravur: male_gravur,
                              currentLanguage,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row  align-items-center d-flex">
          <div className="col-6 m-0 p-0 d-flex flex-column">
            {/* <h6
              className={
                offer_status === 1 || offer_status === 2
                  ? "text-success font-weight-bold"
                  : offer_status === 3
                  ? "text-danger font-weight-bold"
                  : "text-danger font-weight-bold"
              }
            >
              {!isNull(offer_status) && offer_status === 1
                ? language[currentLanguage].offerSent
                : offer_status === 2
                ? language[currentLanguage].offerAccepted
                : offer_status === 3
                ? language[currentLanguage].declined
                : null}
            </h6> */}
            <h6 className="d-none d-md-flex">
              {user_bill_firstName + " " + user_bill_lastName}
            </h6>
          </div>
          <div className="col-6 m-0 p-0">
            <h6 className="text-right card-footer-text d-flex flex-column font-weight-bold">
              <span>{`${language[currentLanguage].orderFrom} ${moment(
                ju_order_time
              ).format("DD.MM.YYYY")}`}</span>
            </h6>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default withRouter(DetailsCard);
