import { useEffect, useState } from "react";
import { AuthRoutes, PublicRoutes } from "../routing";
import { verifyTokenData } from "../services/authService";

const Navigation = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(
    // navigator.language === "de" ? "de" : "en"
    navigator.language.includes("en") ? "en" : "de"
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", currentLanguage);
      // setCurrentLanguage(navigator.language === "de" ? "de" : "en");
      setCurrentLanguage(navigator.language.includes("en") ? "en" : "de");
    } else {
      const preferredLanguage = localStorage.getItem("language");
      setCurrentLanguage(preferredLanguage);
    }
    if (localStorage.getItem("authorization")) {
      verifyToken();
    } else {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, []);
  const logout = () => {
    localStorage.removeItem("authorization");
    localStorage.removeItem("language");
    setIsAuthenticated(false);
  };

  const verifyToken = async () => {
    setLoading(true);
    const token = localStorage.getItem("authorization");
    try {
      const {
        data: { user_authenticated },
      } = await verifyTokenData(token);
      if (user_authenticated === false) {
        localStorage.removeItem("authorization");
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
        setLoading(false);
      }
    } catch (e) {
      if (e.response && e.response.status === 403) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="spinner-control ">
        <div className="spinner-border full-page-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        {!isAuthenticated ? (
          <PublicRoutes
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
            verifyToken={verifyToken}
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
          />
        ) : (
          <AuthRoutes
            logout={logout}
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
          />
        )}
      </div>
    );
  }
};

export default Navigation;
