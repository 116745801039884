import { Table } from "react-bootstrap";
import moment from "moment";
import language from "../utils/language.json";
import { image } from "../images";

const PDFForm = ({ receiptData, currentLanguage, start }) => {
  const {
    company_name,
    ju_email,
    ju_id,
    company_address,
    company_postcode,
    company_city,
    company_country,
    date,
  } = receiptData[0];

  const newDate = moment(start, "DD/MM/YYYY").toDate();

  return (
    <div className="mt-4">
      {/* <div className="pdf-header-details m-auto w-100 w-md-50"> */}

      <div className="row mt-2 mb-5 h-100 ">
        <div className="col-12 text-right pr-0" style={{display:'flex', flexDirection: 'column'}}>
          
          <div>
          <p className="col-6 col-md-3 text-left receipt-heading mb-2 text-right" style={{float:'right'}}>
            {language[currentLanguage].bill}
          </p>
        </div>

        <div>
        <p className="receipt-text form-group row mb-2" style={{float:'right'}} >
          {language[currentLanguage].invoiceNum}:  <input type="text" className="pdf-input-field ml-1 text-right text-dark" />
          </p>
        </div>

        <div>
        <p className="receipt-text form-group row mb-2" style={{float:'right'}} >
            {language[currentLanguage].custNum}:{" "}  <input type="text" className="pdf-input-field ml-1 text-right text-dark" />
          </p>
        </div>         

        <div> 
        <p className="receipt-text form-group row mb-2" style={{float:'right'}}>
          {language[currentLanguage].invoiceDate}: <input type="text" style={{border: 'none'}}  value={moment(newDate).endOf("month").format("MM.YYYY")} className="pdf-input-field ml-1 text-right text-dark" />
        </p>
        </div>

        <div> 
        <p className="receipt-text form-group row mb-2" style={{float:'right'}}>
          {language[currentLanguage].deliveryDate}: <input type="text" style={{border: 'none'}}  value={moment(newDate).endOf("month").format("DD.MM.YYYY")} className="pdf-input-field ml-1 text-right text-dark" />
        </p>
        </div>

        </div>
      </div>



      {/* <div className="row my-3 h-100 "> */}
      <div className="row mt-2 mb-5 h-100 ">
        <div className="col-6 pl-0">
          <p className="ul-text receipt-text">
            {company_name} {company_address}{" "}
            {`${company_postcode} ${" "} ${company_city} ${" "} ${company_country}`}{" "}
          </p>
          <p className="receipt-text">
            Merci Gold GmbH <br /> Friedenstraße 24 <br /> 51373 Leverkusen
          </p>
        </div>
        <div className="col-6 text-right pr-0">
          <p className="receipt-text">
            {company_name} <br />
            {company_address}
            <br />
            {`${company_postcode} ${company_city} ${company_country}`}{" "}
          </p>
        </div>
      </div>
      <p className="receipt-text pt-5">
        {language[currentLanguage].invoiceNotice}
      </p>
      <Table responsive bordered>
        <thead className="pdf-table-head">
          <tr>
            <th>{language[currentLanguage].serialNo}</th>
            <th>{language[currentLanguage].orderNo}</th>
            <th>{language[currentLanguage].productNo}</th>
            <th>{language[currentLanguage].service}</th>
            <th>{language[currentLanguage].time}</th>
            <th>{language[currentLanguage].price}</th>
          </tr>
        </thead>
        <tbody className="pdf-table-body">
          {receiptData.map(
            (
              {
                ju_order_id,
                orderId,
                ju_service,
                service_price,
                ju_order_time,
                ju_order_updatedTime,
              },
              index
            ) => (
              <tr key={index}>
                <td>{index + 1}.</td>
                <td>{ju_order_id}</td>
                <td>{orderId}</td>
                <td>
                  {ju_service === 1
                    ? language[currentLanguage].jpickupServiceTitle
                    : ju_service === 2
                    ? language[currentLanguage].repairServiceTitle
                    : ju_service === 3
                    ? language[currentLanguage].cleanupServiceTitle
                    : language[currentLanguage].adjustmentServiceTitle}
                </td>
                {/* {service_price ? <td>€ {service_price}</td> : <td>0</td>} */}
                <td>
                  {moment(ju_order_updatedTime).format("DD.MM.YYYY H:mm")}
                </td>
                <td>{`€ ${service_price ? service_price : 0}`}</td>
              </tr>
            )
          )}
          <tr>
            <td colSpan="5">
              <p>{language[currentLanguage].subTotal} </p>
              <p>{language[currentLanguage].salesTax}</p>
              <p className="">{language[currentLanguage].totalAmount}</p>
            </td>

            <td>
              <p>
                €{" "}
                {(
                  receiptData.reduce(
                    (accum, item) => accum + item.service_price,
                    0
                  ) / 1.19
                ).toFixed(2)}
              </p>
              <p>
                €{" "}
                {(
                  (receiptData.reduce(
                    (accum, item) => accum + item.service_price,
                    0
                  ) /
                    1.19) *
                  0.19
                ).toFixed(2)}
              </p>
              <p>
                €{" "}
                {receiptData.reduce(
                  (accum, item) => accum + item.service_price,
                  0
                )}
              </p>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="row d-flex flex-column">
        <p className="receipt-text mb-2">
          {language[currentLanguage].immediatePay}
        </p>
        <p className="receipt-text">{language[currentLanguage].gratitude}</p>
      </div>
    </div>
  );
};

export default PDFForm;
