import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import language from "../../utils/language.json";
import { isEmpty } from "lodash";

const RepairService = (props) => {
  const {
    currentLanguage,
    serviceCode,
    setJuType,
    setMessage,
    message,
    createService,
    loading,
    bigPrice,
    smallPrice,
  } = props;
  const [confirmBtn, setConfirmBtn] = useState(false);
  const [offerConfirmBtn, setOfferConfirmBtn] = useState(false);
  const [hideOfferBtn, setHideOfferBtn] = useState(true);
  const [hideBookBtn, setHideBookBtn] = useState(true);
  const [confirmBtnFlag, setConfirmBtnFlag] = useState(false);
  const [back, setBack] = useState(false);
  const [disbaled, setDisabled] = useState(true);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [priceField, setPriceField] = useState(false);
  const [price, setPrice] = useState("");
  const [bookingPrice, setBookingPrice] = useState("");
  const [disableOfferPrice, setDisableOfferPrice] = useState(false);
  const [smallBtn, setSmallBtn] = useState(smallPrice ? true : false);

  useEffect(() => {
    if (!isEmpty(message) && confirmBtnFlag) setDisabled(false);
    if (isEmpty(message)) setDisabled(true);
  }, [message]);
  const handleChange = ({ target: { value } }) => {
    setMessage(value);
  };
  const handlePriceChange = ({ target: { value } }) => {
    setPrice(value);
  };

  const handleButton = (num, token) => {
    if (token === "small") {
      setHideBookBtn(false);
      setBookingPrice(smallPrice);
    }
    if (token === "big") {
      setSmallBtn(false);
      setBookingPrice(bigPrice);
    }
    setConfirmBtnFlag(true);
    if (!isEmpty(message)) {
      setDisabled(false);
    }
    setJuType(num);
    if (num === 1) {
      setConfirmBtn(true);
      setHideOfferBtn(false);
      setBack(true);
      setDisplayMessage(true);
    }
    if (num === 0 && isEmpty(price)) {
      setOfferConfirmBtn(true);
      setPriceField(true);
      setHideBookBtn(false);
      setDisabled(true);
      setSmallBtn(false);
    }
    if (num === 0 && !isEmpty(price)) {
      setOfferConfirmBtn(false);
      setHideBookBtn(false);
      setBack(true);
      setDisplayMessage(true);
      setDisableOfferPrice(true);
      setSmallBtn(false);
    }
  };

  // const handleConfirm = (num) => {
  //   setBack(false);
  //   setConfirmBtnFlag(true);
  //   if (!isEmpty(message)) {
  //     setDisabled(false);
  //   }
  //   setJuType(num);
  //   if (num === 0) {
  //     setOfferConfirmBtn(false);
  //   }
  //   if (num === 1) {
  //     setConfirmBtn(false);
  //   }
  // };
  const handleReset = () => {
    setHideOfferBtn(true);
    setHideBookBtn(true);
    setConfirmBtn(false);
    setOfferConfirmBtn(false);
    setBack(false);
    setMessage("");
    setConfirmBtnFlag(false);
    setDisplayMessage(false);
    setPriceField(false);
    setPrice("");
    setDisableOfferPrice(false);
    setDisabled(true);
    setSmallBtn(smallPrice ? true : false);
  };
  const handleFinalCallConfirm = () => {
    createService(price || bookingPrice);
  };
  const handlePriceConfirm = () => {
    handleButton(0, "");
  };

  return (
    <div>
      <Modal.Header closeButton>
        <div className="d-flex w-100 modal-header-part">
          <span onClick={() => handleReset()}>
            {back === true && (
              <i className="fa fa-arrow-left cursor" aria-hidden="true"></i>
            )}
          </span>
          <div>
            {serviceCode === 2
              ? language[currentLanguage].repairServiceTitle
              : serviceCode === 3
              ? language[currentLanguage].cleanupServiceTitle
              : language[currentLanguage].adjustmentServiceTitle}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="text-center p-0 py-4">
        <div className="d-flex flex-column px-3">
          {smallBtn && (
            <button
              onClick={() => handleButton(1, "small")}
              className="btn btn-outline-secondary selected-btn mb-3 h-100  w-100 mx-auto"
            >
              {language[currentLanguage].bookAdjustmentServiceSmall} (
              {smallPrice}€)
            </button>
          )}
          {hideBookBtn && (
            <>
              <button
                onClick={() => handleButton(1, "big")}
                className="btn btn-outline-secondary selected-btn mb-3 h-100  w-100 mx-auto "
              >
                {serviceCode === 2
                  ? language[currentLanguage].bookRepairService
                  : serviceCode === 3
                  ? language[currentLanguage].bookCleanupService
                  : language[currentLanguage].bookAdjustmentService}{" "}
                ({bigPrice}€)
              </button>
            </>
          )}
          {/* {confirmBtn && (
            <button
              onClick={() => handleConfirm(1)}
              className="btn btn-secondary w-100 m-auto my-6"
            >
              {language[currentLanguage].confirm}
            </button>
          )} */}
          {hideOfferBtn && (
            <button
              onClick={() => handleButton(0)}
              className={
                confirmBtn
                  ? "btn btn-outline-secondary selected-btn my-3 h-100  w-100 mx-auto "
                  : "btn btn-outline-secondary selected-btn mb-3 h-100  w-100 mx-auto "
              }
            >
              {language[currentLanguage].sendOfferPrice}
            </button>
          )}
          {priceField && (
            <>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">(€)</div>
                </div>
                <input
                  disabled={disableOfferPrice}
                  type="number"
                  onChange={(e) => handlePriceChange(e)}
                  value={price}
                  placeholder={language[currentLanguage].enterPrice}
                  className="form-control"
                />
              </div>
            </>
          )}
          {offerConfirmBtn && (
            <button
              disabled={isEmpty(price)}
              onClick={() => handlePriceConfirm(0)}
              className="btn btn-info w-100 m-auto my-6"
            >
              {language[currentLanguage].confirm}
            </button>
          )}

          {displayMessage && (
            <>
              <div className="form-group mt-3">
                <textarea
                  className="form-control text-left"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  value={message}
                  onChange={(e) => handleChange(e)}
                  placeholder={language[currentLanguage].adjustPlaceHolder}
                  style={{ resize: "none" }}
                ></textarea>
              </div>
              <button
                disabled={disbaled}
                onClick={() => handleFinalCallConfirm()}
                className="btn btn-info w-100 m-auto my-6"
              >
                {loading
                  ? language[currentLanguage].loading
                  : language[currentLanguage].confirm}
              </button>
            </>
          )}
        </div>
      </Modal.Body>
    </div>
  );
};

export default RepairService;
