import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import React, { useEffect, useState, useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppContext } from "../appContext";
import {
  FooterLogo,
  Header,
  MessageDetails,
  ProductDetails,
  SideBar,
} from "../components";
import {
  ActiveOrderDetails,
  ActiveOrders,
  AllProducts,
  BankDetailsConfirmation,
  ChangePassword,
  Contact,
  FAQ,
  FinishedOrders,
  Imprint,
  MessagesGroup,
  Notifications,
  Offers,
  // Privacy,
  QualityStandards,
  Receipts,
  Settings,
  Terms,
} from "../pages";
import { getProfileData } from "../services/authService";

const AuthRoutes = (props) => {
  const [mobSideBar, setMobSideBar] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [updateData, setUpdateData] = useState(false);
  const [loader, setLoader] = useState(true);
  const [side, setSide] = useState(false);

  const {
    isAuthenticated,
    setIsAuthenticated,
    logout,
    currentLanguage,
    setCurrentLanguage,
  } = props;

  const {
    state: { showNotification },
    setState,
  } = useContext(AppContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const {
        data: { data },
      } = await getProfileData();
      setProfileData(data[0]);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      if (e.response && e.response.status === 403) {
        logout();
      }
    }
  };

  const handleSelect = (e, language) => {
    localStorage.setItem("language", language);
    setCurrentLanguage(language);
  };

  const handleClick = async () => {
    // await getNotificationsData();
    setMobSideBar(true);
    setSide(true);
  };

  const handleClose = async () => {
    // await getNotificationsData();
    // await getCounters();
    setState({ showNotification: false });
    setSide(false);
    setMobSideBar(false);
  };

  const handleSideBar = () => {
    setState({ showNotification: false });
    setSide(false);
    setMobSideBar(false);
  };
  return (
    <React.Fragment>
      <div className="container">
        <nav className="d-flex align-items-center m-2">
          <div
            className={
              mobSideBar === false
                ? "d-lg-none d-md-block icon-placement"
                : null
            }
          >
            <button
              className="navbar-toggler p-0 border-0 toggle-icon-size pt-1"
              type="button"
              data-toggle="offcanvas"
            >
              {side === false ? (
                <div>
                  <FontAwesomeIcon
                    onClick={handleClick}
                    icon={faBars}
                    className="header-btn-size"
                  />
                  {showNotification && (
                    <span className=" badge badge-notification badge-danger">
                      {" "}
                    </span>
                  )}
                </div>
              ) : (
                <FontAwesomeIcon
                  onClick={handleClose}
                  className="header-btn-size"
                  icon={faTimes}
                />
              )}
            </button>
          </div>
          <Header
            handleSelect={handleSelect}
            currentLanguage={currentLanguage}
            isAuthenticated={isAuthenticated}
          />
        </nav>
      </div>
      <hr className="mt-0" />

      <div className="page-height">
        <div className="container">
          <div className="row">
            {loader ? (
              <div className="sidebar-page-height">
                <div className="sidebar-spinner-control">
                  <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={
                    mobSideBar === true
                      ? "d-none"
                      : "col-lg-4 d-none d-lg-block"
                  }
                >
                  <SideBar
                    currentLanguage={currentLanguage}
                    handleSideBar={handleSideBar}
                    name={profileData.ju_firstName}
                    logout={logout}
                    authentication
                  />
                </div>
                <div
                  className={
                    mobSideBar === true ? "col-lg-12 p-0" : "col-lg-8 mt-3 "
                  }
                >
                  <main role="main mx-1">
                    {mobSideBar === true ? (
                      <SideBar
                        currentLanguage={currentLanguage}
                        handleSideBar={handleSideBar}
                        name={profileData.ju_firstName}
                        logout={logout}
                        authentication
                      />
                    ) : (
                      <Switch>
                        <Route
                          path="/home/order-details/:id"
                          exact
                          render={(props) => (
                            <ActiveOrderDetails
                              {...props}
                              currentLanguage={currentLanguage}
                              logout={logout}
                            />
                          )}
                        />
                        <Route
                          path="/home/scanned-product/:id"
                          exact
                          render={(props) => (
                            <ProductDetails
                              {...props}
                              currentLanguage={currentLanguage}
                              logout={logout}
                            />
                          )}
                        />
                        <Route
                          path="/bank-confirmation/:token"
                          exact
                          render={(props) => (
                            <BankDetailsConfirmation
                              {...props}
                              currentLanguage={currentLanguage}
                            />
                          )}
                        />
                        <Route
                          path="/home/messages/:id"
                          exact
                          render={(props) => (
                            <MessageDetails
                              {...props}
                              currentLanguage={currentLanguage}
                              handleSelect={handleSelect}
                              logout={logout}
                            />
                          )}
                        />
                        <Route
                          path="/home/messages"
                          exact
                          render={(props) => (
                            <MessagesGroup
                              {...props}
                              currentLanguage={currentLanguage}
                              handleSelect={handleSelect}
                              setIsAuthenticated={setIsAuthenticated}
                            />
                          )}
                        />
                        <Route
                          path="/home/receipts"
                          exact
                          render={(props) => (
                            <Receipts
                              {...props}
                              currentLanguage={currentLanguage}
                              handleSelect={handleSelect}
                              setIsAuthenticated={setIsAuthenticated}
                              logout={logout}
                            />
                          )}
                        />

                        <Route
                          path="/home/imprint"
                          exact
                          render={(props) => (
                            <Imprint
                              {...props}
                              currentLanguage={currentLanguage}
                            />
                          )}
                        />
                        {/* <Route
                          path="/home/privacy"
                          exact
                          render={(props) => (
                            <Privacy
                              {...props}
                              currentLanguage={currentLanguage}
                            />
                          )}
                        /> */}
                        <Route
                          path="/home/terms"
                          exact
                          render={(props) => (
                            <Terms
                              {...props}
                              currentLanguage={currentLanguage}
                            />
                          )}
                        />
                        <Route
                          path="/home/quality-standards"
                          exact
                          render={(props) => (
                            <QualityStandards
                              {...props}
                              currentLanguage={currentLanguage}
                            />
                          )}
                        />
                        <Route
                          path="/home/active-orders"
                          exact
                          render={(props) => (
                            <ActiveOrders
                              {...props}
                              currentLanguage={currentLanguage}
                              logout={logout}
                            />
                          )}
                        />
                        <Route
                          path="/home/past-orders"
                          exact
                          render={(props) => (
                            <FinishedOrders
                              {...props}
                              currentLanguage={currentLanguage}
                              logout={logout}
                            />
                          )}
                        />
                        <Route
                          path="/home/active-offers"
                          exact
                          render={(props) => (
                            <Offers
                              {...props}
                              currentLanguage={currentLanguage}
                              logout={logout}
                            />
                          )}
                        />
                        <Route
                          path="/home/notifications"
                          exact
                          render={(props) => (
                            <Notifications
                              {...props}
                              currentLanguage={currentLanguage}
                              logout={logout}
                            />
                          )}
                        />
                        <Route
                          path="/home/change-password"
                          exact
                          render={(props) => (
                            <ChangePassword
                              {...props}
                              currentLanguage={currentLanguage}
                            />
                          )}
                        />
                        <Route
                          path="/home/contact"
                          exact
                          render={(props) => (
                            <Contact
                              {...props}
                              currentLanguage={currentLanguage}
                              setIsAuthenticated={setIsAuthenticated}
                            />
                          )}
                        />
                        <Route
                          path="/home/faq"
                          exact
                          render={(props) => (
                            <FAQ {...props} currentLanguage={currentLanguage} />
                          )}
                        />

                        <Route
                          path="/home"
                          exact
                          render={(props) => (
                            <AllProducts
                              {...props}
                              currentLanguage={currentLanguage}
                              handleSelect={handleSelect}
                            />
                          )}
                        />

                        <Route
                          path="/home/settings"
                          exact
                          render={(props) => (
                            <Settings
                              setUpdateData={setUpdateData}
                              data={!isEmpty(profileData) && profileData}
                              logout={logout}
                              currentLanguage={currentLanguage}
                              handleSelect={handleSelect}
                              {...props}
                            />
                          )}
                        />
                        <Redirect to="/home" />
                      </Switch>
                    )}
                  </main>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <FooterLogo />
    </React.Fragment>
  );
};

export default AuthRoutes;
