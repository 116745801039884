import { Formik } from "formik";
import React, { Component } from "react";
import * as Yup from "yup";

import {
  FinalNotification,
  FloatingInputField,
  FooterLogo,
} from "../../../components";
import language from "../../../utils/language.json";

class PaymentDetails extends Component {
  state = {};
  displayBack = (e) => {
    this.props.handleBack();
  };

  render() {
    const { currentLanguage, completeData } = this.props;
    const PaymentDetailsSchema = Yup.object().shape({
      bank_owner: Yup.string().required(language[currentLanguage].nameRequired),
      bank_name: Yup.string().required(
        language[currentLanguage].bankNameRequired
      ),
      bank_currency: Yup.string().required(
        language[currentLanguage].currencyRequired
      ),
      bank_iban: Yup.string().required(language[currentLanguage].ibanRequired),
      bank_code: Yup.string().required(
        language[currentLanguage].bankCodeRequired
      ),
      tax_id: Yup.string().required(language[currentLanguage].taxId),
    });
    return (
      <>
        {this.props.finalConfirmation === false ? (
          <div className="app-width m-auto p-3 flex-column d-flex justify-content-center ">
            <h6>{language[currentLanguage].paymentDetails}</h6>
            <br />
            <Formik
              initialValues={{
                bank_owner: "",
                bank_name: "",
                bank_currency: "EUR (€)",
                bank_iban: "",
                bank_code: "",
                tax_id: "",
                ...completeData,
              }}
              validationSchema={PaymentDetailsSchema}
              onSubmit={(values, { setSubmitting }) => {
                this.props.collectValues(values);
                this.props.registerUser();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <FloatingInputField
                    type="text"
                    name="bank_owner"
                    value={values.bank_owner}
                    placeholderValue="Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      errors.bank_owner &&
                      touched.bank_owner &&
                      errors.bank_owner
                    }
                  />
                  <FloatingInputField
                    type="text"
                    name="bank_name"
                    value={values.bank_name}
                    placeholderValue="Bankname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      errors.bank_name && touched.bank_name && errors.bank_name
                    }
                  />
                  <FloatingInputField
                    type="text"
                    name="bank_iban"
                    value={values.bank_iban}
                    placeholderValue="IBAN"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      errors.bank_iban && touched.bank_iban && errors.bank_iban
                    }
                  />
                  <FloatingInputField
                    type="text"
                    name="bank_code"
                    value={values.bank_code}
                    placeholderValue={language[currentLanguage].bankCode}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      errors.bank_code && touched.bank_code && errors.bank_code
                    }
                  />
                  <FloatingInputField
                    type="text"
                    name="tax_id"
                    value={values.tax_id}
                    placeholderValue={language[currentLanguage].taxId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.tax_id && touched.tax_id && errors.tax_id}
                  />
                  <FloatingInputField
                    type="text"
                    name="bank_currency"
                    value={values.bank_currency}
                    onBlur={handleBlur}
                    error={
                      errors.bank_currency &&
                      touched.bank_currency &&
                      errors.bank_currency
                    }
                  />

                  <>
                    {/* {this.props.loading && (
                      <div className="spinner-control">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )} */}
                    <button
                      onClick={this.displayBack}
                      className="btn btn-primary btn-sm btn-block mb-2"
                    >
                      <b>{language[currentLanguage].previous}</b>
                    </button>
                    <button
                      type="submit"
                      disabled={this.props.loading}
                      className="btn btn-primary btn-sm btn-sm btn-block"
                    >
                      <b>
                        {!this.props.loading
                          ? language[currentLanguage].newAccount
                          : language[currentLanguage].loadingText}
                      </b>
                    </button>
                  </>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <>
            <div className="reset-page-height ">
              <div className="flex-grow-1">
                <FinalNotification
                  greeting={language[currentLanguage].welcome}
                  heading={language[currentLanguage].newAccount}
                  bodyTitle={language[currentLanguage].confirmEmail}
                  bodyText={language[currentLanguage].accBodyText}
                  bodyEnd={language[currentLanguage].thankYou}
                />
              </div>
            </div>
            <FooterLogo />
          </>
        )}
      </>
    );
  }
}

export default PaymentDetails;
