import { ServiceModal, Selection, RepairService } from "../../components";
import { useState, useEffect } from "react";
import { createNewService, getServiceInfo } from "../../services/dataServices";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

const RepairServices = ({
  modal,
  closeModal,
  currentLanguage,
  rings,
  serviceCode,
  history,
  logout,
  bigPrice,
  smallPrice,
}) => {
  const { product_gender, service_price, order_id } = rings;
  const [displayModal, setDisplayModal] = useState(
    product_gender === "couple" ? 1 : 2
  );
  const [serviceGender, setServiceGender] = useState("");
  const [ju_type, setJuType] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (product_gender === "single") {
      fetchData();
    }
  }, []);
  useEffect(() => {
    if (product_gender === "couple" && !isEmpty(serviceGender)) {
      fetchData();
    }
  }, [serviceGender]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const {
        data: { data, allowed, message, message_de },
      } = await getServiceInfo({
        order_id,
        ringtype: product_gender,
        ju_service: serviceCode,
        gender: product_gender === "single" ? "single" : serviceGender,
      });
      if (allowed === true) {
        setDisplayModal(
          !isEmpty(serviceGender) || serviceGender !== "couple" ? 2 : 1
        );
      } else if (!isEmpty(data[0]) && allowed === false) {
        history.push(`/home/order-details/${data[0].ju_order_id}`);
      } else if (allowed === false) {
        toast.error(currentLanguage === "en" ? message : message_de);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        toast.error(currentLanguage === "en" ? message : message_de);
      }
      if (e.response && e.response.status === 403) {
        logout();
      }
    }
  };

  const createService = async (price) => {
    setLoading(true);
    const obj = {
      order_id,
      ju_service: serviceCode,
      ju_type,
      ringtype: product_gender,
      gender: product_gender === "single" ? "single" : serviceGender,
      service_price: parseInt(price),
      service_message: message,
    };
    try {
      const {
        data: { ju_order_id },
      } = await createNewService(obj);
      setLoading(false);
      closeModal();
      history.push(`/home/order-details/${ju_order_id}`);
    } catch (e) {
      setLoading(false);
      closeModal();
      if (e.response && e.response.status === 403) {
        logout();
      }
      if (e.response && e.response.status === 400) {
        const { message, message_de } = e.response.data;
        toast.error(currentLanguage === "en" ? message : message_de);
      }
    }
  };

  return (
    <ServiceModal
      modal={modal}
      closeModal={closeModal}
      body={cardBody({
        displayModal,
        setDisplayModal,
        currentLanguage,
        serviceCode,
        setServiceGender,
        setJuType,
        setMessage,
        message,
        service_price,
        createService,
        loading,
        ju_type,
        order_id,
        product_gender,
        logout,
        bigPrice,
        smallPrice,
      })}
      currentLanguage={currentLanguage}
    />
  );
};
const cardBody = ({
  displayModal,
  setDisplayModal,
  currentLanguage,
  serviceCode,
  setServiceGender,
  setJuType,
  setMessage,
  message,
  service_price = 40,
  createService,
  loading,
  ju_type,
  order_id,
  product_gender,
  logout,
  bigPrice,
  smallPrice,
}) => {
  switch (displayModal) {
    case 1:
      return (
        <Selection
          setDisplayModal={setDisplayModal}
          currentLanguage={currentLanguage}
          setServiceGender={setServiceGender}
          serviceCode={serviceCode}
          order_id={order_id}
          product_gender={product_gender}
          logout={logout}
        />
      );
    case 2:
      return (
        <RepairService
          ju_type={ju_type}
          loading={loading}
          createService={createService}
          service_price={service_price}
          message={message}
          setMessage={setMessage}
          serviceCode={serviceCode}
          setJuType={setJuType}
          setDisplayModal={setDisplayModal}
          currentLanguage={currentLanguage}
          bigPrice={bigPrice}
          smallPrice={smallPrice}
        />
      );
    default:
      return (
        <div className="reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border " role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
  }
};

export default withRouter(RepairServices);
