import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { emailConfirmation } from "../../services/authService";
import language from "../../utils/language.json";

export default function EmailConfirmationPage(props) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const { currentLanguage } = props;

  useEffect(() => {
    async function fetchData() {
      try {
        const urlData = props.match.params.token;
        const { data } = await emailConfirmation(urlData);
        const { message, message_de } = data;
        setMessage(currentLanguage === "en" ? message : message_de);
        setLoading(false);
      } catch (e) {
        if (e.response && e.response.status === 400) {
          const { message, message_de } = e.response.data;
          toast.error(currentLanguage === "en" ? message : message_de);
          console.log(
            currentLanguage,
            currentLanguage === "en",
            message,
            message_de
          );
          setMessage(currentLanguage === "en" ? message : message_de);
          setLoading(false);
        }
      }
    }
    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <div className="spinner-control">
          <div className="spinner-border full-page-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="app-width m-auto h-100 py-4 p-3">
          <h6 className="mb-4">{message}</h6>
          <NavLink to="/login">
            <button className="btn btn-primary btn-block m-auto">
              <b>{language[currentLanguage].login}</b>
            </button>
          </NavLink>
        </div>
      )}
    </>
  );
}
