import http from "./httpService";

const apiEndpoint = "/juwelier/users";
const tokenKey = "authorization";

export async function login(loginData) {
  const response = await http.post(`${apiEndpoint}/login`, loginData);
  const {
    data: { token },
  } = response;
  if (token !== undefined) {
    await localStorage.setItem(tokenKey, token);
  }
  return response;
}

export async function forgotPassword(mail) {
  return await http.post(`${apiEndpoint}/forgot-password`, mail);
}

export async function verifyTokenData() {
  return await http.post(
    `${apiEndpoint}/verify_token`,
    {},
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}

export async function changePassword(data) {
  return await http.post(`${apiEndpoint}/changePassword`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}

export async function registerNewUser(details) {
  return await http.post(`${apiEndpoint}/signup`, details);
}

export async function emailConfirmation(verificationToken) {
  return await http.get(
    `${apiEndpoint}/email-confirmation/${verificationToken}`,

    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}

export async function forgotPasswordConfirm(verificationToken) {
  return await http.post(
    `${apiEndpoint}/forgot-password-confirmation`,
    verificationToken,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}

export async function resendEmailConfirmation(email) {
  return await http.post(`${apiEndpoint}/resend_email`, email);
}
export async function getProfileData() {
  return await http.get(
    `${apiEndpoint}/profile`,

    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}
export async function changeCompanyAddress(data) {
  return http.post(`${apiEndpoint}/change_company_address`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function updateBankDetails(data) {
  return http.post(`${apiEndpoint}/change_bank_details`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
}
export async function notificationToken(token) {
  return http.post(
    `${apiEndpoint}/notification_token`,
    { fcmToken: token },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}
export async function getServicePrices() {
  return http.get(
    `${apiEndpoint}/get_service_prices`,

    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}
export async function confirmedBankDetails(token) {
  return http.get(
    `${apiEndpoint}/bank-confirmation/${token}`,

    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authorization")}`,
      },
    }
  );
}

export default {
  registerNewUser,
  login,
  forgotPassword,
  forgotPasswordConfirm,
  emailConfirmation,
  changeCompanyAddress,
  updateBankDetails,
  verifyTokenData,
  changePassword,
  getProfileData,
  notificationToken,
  getServicePrices,
  resendEmailConfirmation,
  confirmedBankDetails,
};
