import React from "react";

import language from "../../utils/language.json";

import { QualitatMain, QualitatHelper1, QualitatHelper2 } from "../../images";

function QualityStandards(props) {
  const { currentLanguage } = props;
  return (
    <>
      <div className="w-100 mb-2">
        <img className="img-fluid" alt="quality 1" src={QualitatMain} />
      </div>
      <div className="colorPrimary">
        <div className="row">
          <div className="p-3">
            <p className="main-headings-helper colorPrimary m-0">MEHR ZU</p>
            <p className="main-headings">
              {language[currentLanguage].standardsHeading}
            </p>
          </div>
        </div>
        <div className="row mx-md-0 mx-0">
          <div className="col-md-6 item-detail-box p-1 ml-md-0">
            <div className="p-0 p-md-4 p-5">
              <p className="font-weight-bold">Juwelierqualität</p>
              <p className="mt-5 ">{language[currentLanguage].paragraph1}</p>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center sticky-left mt-3 mt-md-0">
            <img alt="Qualitat 2" src={QualitatHelper1} className="w-100" />
          </div>
        </div>
        <div className="row mx-md-0 mx-0 mt-3">
          <div className="col-md-6 d-flex align-items-center sticky-right mt-3 mt-md-0">
            <img alt="quality 3" src={QualitatHelper2} className="w-100" />
          </div>
          <div className="col-md-6 item-detail-box p-1 ml-md-0">
            <div className="p-0 p-md-4 p-5">
              <p className="font-weight-bold">Juwelierqualität</p>
              <p className="mt-5 ">{language[currentLanguage].paragraph1}</p>
            </div>
          </div>
        </div>

        {/* <div className="row mt-3 mx-md-0 mx-0">
          <div className="d-flex align-items-center sticky-right mt-3 mt-md-0">
            <img src={QualitatHelper2} className="w-50" />
          </div>
          <div className="col-md-6 item-detail-box p-1 ml-0">
            <div className="p-0 p-md-5  p-5">
              <p className="font-weight-bold">
                {language[currentLanguage].paragraph2Title}
              </p>
              <p className="mt-5">{language[currentLanguage].paragraph2}</p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default QualityStandards;
