import { Navigation } from "./routing";
import { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppContext, intitialState } from "./appContext";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const [state, setState] = useState(intitialState);
  return (
    <div>
      <AppContext.Provider value={{ state, setState }}>
        <Router>
          <ToastContainer />
          <Navigation />
        </Router>
      </AppContext.Provider>
    </div>
  );
}

export default App;
