import { Formik } from "formik";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { FloatingInputField } from "../components";
import { forgotPasswordConfirm } from "../services/authService";
import language from "../utils/language.json";

const ForgotPasswordConfirmation = (props) => {
  const { currentLanguage } = props;
  const [loading, setLoading] = useState(false);

  const loginSchema = Yup.object().shape({
    newPassword: Yup.string().required(
      language[currentLanguage].passwordRequired
    ),
    confirmPassword: Yup.string()
      .required("Confirm Password")
      .oneOf(
        [Yup.ref("newPassword"), null],
        language[currentLanguage].passwordNotMatched
      ),
  });

  const handleSubmission = async (values) => {
    setLoading(true);
    const { newPassword } = values;
    try {
      const urltoken = props.match.params.token;
      const data = { newPassword, token: urltoken };
      const result = await forgotPasswordConfirm(data);
      setLoading(false);
      toast.success(currentLanguage === "de" ? result.data.message_de : result.data.message);
      props.history.push("/login");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setLoading(false);
        const { message, message_de } = ex.response.data;
        toast.error(currentLanguage === "de" ? message_de : message);
      }
      if (ex.response && ex.response.status === 404) {
        setLoading(false);
        const { message, message_de } = ex.response.data;
        toast.error(currentLanguage === "de" ? message_de : message);
      }
    }
  };
  return (
    <div className="app-width m-auto p-4 d-flex flex-column justify-content-center ">
      <h5>{language[currentLanguage].changeYourPassword}</h5>
      <br />
      <Formik
        initialValues={{ newPassword: "", confirmPassword: "" }}
        validationSchema={loginSchema}
        onSubmit={handleSubmission}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FloatingInputField
              error={
                errors.newPassword && touched.newPassword && errors.newPassword
              }
              type="password"
              name="newPassword"
              value={values.newPassword}
              currentLanguage={currentLanguage}
              placeholderValue={language[currentLanguage].newPassword}
              onChange={handleChange}
            />
            <FloatingInputField
              error={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
              type="password"
              name="confirmPassword"
              value={values.confirmPassword}
              currentLanguage={currentLanguage}
              placeholderValue={language[currentLanguage].confirmPassword}
              onChange={handleChange}
            />
            <>
              {loading && (
                <div className="spinner-control">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary btn-sm btn-block btn-final"
              >
                {!loading ? (
                  <strong>{language[currentLanguage].confirm}</strong>
                ) : null}
              </button>
            </>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(ForgotPasswordConfirmation);
