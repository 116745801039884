import React, { useEffect, useState } from "react";
import language from "../utils/language.json";

const FloatingInputField = ({
  error = "",
  name,
  value,
  placeholderValue,
  onChange,
  onBlur,
  type,
  handleText,
  currentLanguage,
  ...props
}) => {
  const [currentType, setCurrentType] = useState(type);
  useEffect(() => {
    setCurrentType(type);
  }, []);
  const setInputType = () => {
    setCurrentType(currentType === "text" ? "password" : "text");
  };

  return (
    <div className="pb-2">
      <div className="floating-label-field ">
        <input
          {...props}
          type={currentType}
          className={error[name] ? "fullname field-error" : "fullname"}
          name={name}
          id={name}
          size="40"
          placeholder=" "
          onChange={(e) => onChange(e)}
          onBlur={onBlur}
          value={value}
        />

        <label className="fullname" htmlFor={name}>
          {placeholderValue}
        </label>

        {type === "password" && (
          <div className="show-field cursor" onClick={setInputType}>
            <input
              checked={currentType === "text"}
              type="checkbox"
              className="mr-2 password-checkbox"
              id="button"
              tabIndex={-1}
              size="10"
              readOnly
            />
            <span className="mr-1 mb-1 body-text-size">
              {currentLanguage && language[currentLanguage].show}
            </span>
          </div>
        )}
      </div>
      <p className="error">{error}</p>
    </div>
  );
};
export default FloatingInputField;
