import { useState } from "react";
import { toast } from "react-toastify";
import { ShadowIcon } from "../../images";
import { orderUpdate } from "../../services/dataServices";
import language from "../../utils/language.json";

const RepairOfferServiceBooked = ({
  setDisplayModal,
  currentLanguage,
  loading,
  data,
  history,
  offered,
}) => {
  const [confirmBtn, setConfirmBtn] = useState(false);
  const { ju_service, service_price, ju_order_id, offer_status } = data;
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);

  const handleConfirm = async () => {
    setConfirmBtnLoading(true);
    try {
      const {
        data: { message, message_de },
      } = await orderUpdate({ ju_order_id, status: 2 });
      setConfirmBtnLoading(false);
      toast.success(currentLanguage === "en" ? message : message_de);
      setDisplayModal(2);
    } catch (e) {
      setConfirmBtnLoading(false);
      if (e.response && e.response.status === 403) {
        localStorage.removeItem("authorization");
        history.push("/");
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="reset-page-height">
          <div className="spinner-control">
            <div className="spinner-border full-page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            {ju_service === 2
              ? language[currentLanguage].repairServiceTitle
              : ju_service === 3
              ? language[currentLanguage].cleanupServiceTitle
              : ju_service === 4
              ? language[currentLanguage].adjustmentBtn
              : null}{" "}
            {language[currentLanguage].booked}
          </div>
          <hr />
          <div className="p-0 py-4">
            <div className="d-flex flex-column">
              <div className="pb-4">
                {offer_status === 2 && (
                  <div
                    // onClick={() => handleButton()}
                    className="mb-3 h-100  w-100 d-flex justify-content-between"
                  >
                    <p className="ul-text my-auto">
                      {language[currentLanguage].offerAcceptedBooked}
                    </p>
                    <span className="my-auto">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                  </div>
                )}
                <div
                  // onClick={() => handleButton()}
                  className="mb-3 h-100  w-100 d-flex justify-content-between"
                >
                  <p className="ul-text my-auto">
                    {" "}
                    {ju_service === 2
                      ? language[currentLanguage].repairServiceTitle
                      : ju_service === 3
                      ? language[currentLanguage].cleanupServiceTitle
                      : ju_service === 4
                      ? language[currentLanguage].adjustmentBtn
                      : null}{" "}
                    ,,{language[currentLanguage].booked}" ({service_price}€)
                  </p>
                  <span className="my-auto">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div className="booked-body p-3 mb-3 px-4 md-px-5 ">
                <p className="card-mid-title">
                  {ju_service === 2
                    ? language[
                        currentLanguage
                      ].repairServiceisBooked.toUpperCase()
                    : ju_service === 3
                    ? language[
                        currentLanguage
                      ].cleanupServiceBooked.toUpperCase()
                    : ju_service === 4
                    ? language[
                        currentLanguage
                      ].adjustServiceBooked.toUpperCase()
                    : null}
                </p>
                <p className="mb-6 card-mid-body">
                  {offered === true &&
                    language[currentLanguage].offerAcceptedMessage}
                  {/* {language[currentLanguage].please} */}
                  {/* {ju_service === 2
                    ? language[currentLanguage].repair
                    : ju_service === 3
                    ? language[currentLanguage].cleanup
                    : ju_service === 1
                    ? language[currentLanguage].adjust
                    : null} */}
                  {language[currentLanguage].repairTheGood}
                </p>
                <img
                  src={ShadowIcon}
                  className="check-circle-style"
                  alt="approved"
                />
                <span className="card-end-body">
                  {language[currentLanguage].thankYou}
                </span>
              </div>
              <div>
                <button
                  className="btn btn-outline-secondary mb-3 w-100 selected-btn"
                  onClick={() => setConfirmBtn(true)}
                >
                  {language[currentLanguage].jpickupServiceBodyText}
                </button>
                {confirmBtn && (
                  <button
                    onClick={() => handleConfirm()}
                    className="btn btn-primary w-100 m-auto my-6"
                  >
                    {confirmBtnLoading
                      ? language[currentLanguage].loading
                      : language[currentLanguage].confirm}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RepairOfferServiceBooked;
