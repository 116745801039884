import React from "react";
import { Modal } from "react-bootstrap";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import language from "../utils/language.json";

const MediaShare = (props) => {
  const { modal, closeModal, currentLanguage, urlShare } = props;

  return (
    <div>
      <Modal
        show={modal}
        onHide={closeModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {language[currentLanguage].share}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <EmailShareButton
            className="mx-2"
            subject="Gold Producer"
            body="body"
            url={urlShare}
          >
            <EmailIcon size={"2rem"} round />
          </EmailShareButton>
          <FacebookShareButton
            className="mx-2"
            quote="Gold Producer"
            url={urlShare}
          >
            <FacebookIcon size={"2rem"} round />
          </FacebookShareButton>
          <LinkedinShareButton
            url={urlShare}
            className="mx-2"
            title="Gold Producer"
            windowWidth={750}
            windowHeight={600}
          >
            <LinkedinIcon size={"2rem"} round />
          </LinkedinShareButton>
          <TwitterShareButton
            className="mx-2"
            title="Gold Producer"
            url={urlShare}
          >
            <TwitterIcon size={"2rem"} round />
          </TwitterShareButton>
          <WhatsappShareButton
            className="mx-2"
            title="Gold Producer"
            separator=":: "
            url={urlShare}
          >
            <WhatsappIcon size={"2rem"} round />
          </WhatsappShareButton>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default MediaShare;
